/**
 * shyt ie11 wrap for fixing awesome ie11 bugs
 *
 * usage:
 * @include ie11-only-wrap-shizzle('.foo') {
 *  // your styles here
 * }
 *
 * you can use lists to iterate more than one class
 */
@mixin ie11-only-wrap-shizzle($classNames) {
  @each $class in $classNames {
    _:-ms-fullscreen,
    :root #{$class} {
      /* IE11 fallback mixin */
      @content;
    }
  }
}
