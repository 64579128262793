@use 'sass:math';

.videocontainer {
  position: relative;
  overflow: hidden;
  width: 100%;

  &::after {
    content: '';
    display: block;
    padding-top: 56.25%; //For 16:9 Videos
  }

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

div.main .video {
  & > iframe {
    @extend .aspect-ratio-xs-item;
  }

  .tx-supi__youtube {
    @include css-grid;

    padding: $grid-gutter-width * 0.5;
    border: 1px solid __get($colors, light-grey);

    @include media-breakpoint-up(xl) {
      padding: 0;
      border: none;
    }

    &-text {
      position: relative;
      top: auto;
      margin: 0;
      background: none;
      display: flex;
      align-items: center;

      @include css-grid('layer-1');

      text-align: left;
      padding: $grid-gutter-width * 0.5;

      @include media-breakpoint-up(xl) {
        padding: $grid-gutter-width;
        text-align: center;
      }

      & > div {
        @include media-breakpoint-up(xl) {
          padding: $grid-gutter-width * 0.5;
          background: rgba(#fff, 0.8);
        }

        p {
          font-size: 16px;
          line-height: 1.5;
          margin-bottom: $grid-gutter-width * 0.5;

          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }

    figure {
      @include css-grid('layer-2');
      -ms-grid-row: 2;
      grid-row: 2;

      @include media-breakpoint-up(xl) {
        -ms-grid-row: 1;
        grid-row: 1;
      }
    }
  }
}

@each $bp, $width in (xs: 12, sm: 6, md: 7) {
  _:-ms-fullscreen,
  :root .main .col-#{$bp}-#{$width} .tx-supi__youtube figure,
  :root .main .col-#{$bp}-#{$width} .tx-supi__youtube .tx-supi__youtube-text {
    @include media-breakpoint-up($bp) {
      width: calc(#{(math.div($width, 12) * 100)}vw - #{$grid-gutter-width * 2});
    }
  }
}

.video-embed-item {
  width: 100%;
}
