.file-downloader {
  .doc-downloader-image {
    display: flex;
    align-items: center;
    overflow: hidden;

    img {
      width: 100%;
    }
  }

  .doc-downloader-image-wrap {
    padding: 1px 0;
  }

  .doc-downloader-cover-3 {
    width: calc(100% - 20px);
    text-align: left;
    background-position: center;
    line-height: 150px;
    bottom: 70px;
  }

  .doc-downloader-cover-2 {
    @extend .doc-downloader-cover-3;
  }
}
