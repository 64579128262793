/**************************************/
/* Default styles for news extension */
/**************************************/
.news .clear {
  clear: both;
}

.news .nav ul {
  float: left;
  display: inline;
  margin: 0;
}

.news .nav li {
  float: left;
}

.news .nav a {
  float: left;
  display: block;
}

.news .nav:after {
  content: '.';
  display: block;
  height: 0;
  clear: both;
  visibility: hidden;
}

.news .article {
  padding-bottom: 15px;
  margin-bottom: 15px;
  position: relative;
}

.news .article:last-child {
  padding-bottom: 0;
  margin-bottom: 0;
}

.news .article:after {
  content: '.';
  display: block;
  height: 0;
  clear: both;
  visibility: hidden;
}

.news .article .news-img-wrap {
  float: left;
  margin: 3px 12px 5px 0;
  background: #fff;
}

.news .article .news-img-wrap a {
  display: block;
  float: left;
  padding: 4px;
  border: 1px solid #ddd;
  background: #fff;
}

.news .article .news-img-wrap img {
  float: left;
  height: auto;
  width: auto;
}

.news .article .teaser-text {
  margin: 0 0 10px 0;
}

.news .article .more {
  @include anchor-style;
}

.news .article h3 {
  margin-bottom: 0;
}

.news .footer {
  clear: both;
  padding: 10px 0 0 0;
  border-top: 1px solid #eee;
  margin-top: 15px;
  color: #888;
}

.news .footer p {
  line-height: 1em;
}

.news .footer span {
  display: inline-block;
  //padding-right: 8px;
  //margin-right: 8px;
  //border-right: 1px solid #ddd;
}

.news .footer span:last-child {
  border-right: 0;
}

.news .footer .news-category a {
  text-transform: uppercase;
}

.news .no-news-found {
  color: #db0202;
  font-style: italic;
}

.news .page-navigation p {
  float: left;
}

.news .page-navigation ul {
  float: right;
}

.news .page-navigation ul li {
  float: left;
  display: block;
  padding: 0 1px;
  background: none;
}

.news .page-navigation ul li a {
  display: block;
  padding: 1px 2px;
}

/* category menu view */
.news-category-view ul li {
  padding: 5px 8px;
}

.news-category-view ul li li {
  padding: 2px 8px 2px 12px;
}

.news-category-view > ul > li:nth-child(even) {
  background: #f9f9f9;
  border-top: 1px solid #eee;
  border-bottom: 1px solid #eee;
}

.news-category-view .category-desc {
  padding-left: 10px;
  margin-left: 10px;
  border-left: 1px solid #ddd;
}

/* single view */
.news-single .footer {
  margin-bottom: 20px;
  margin-top: 5px;
  padding: 10px 0 0 0;
  border-bottom: 0;
  border-top: 1px solid #eee;
}

.news-single .article .news-img-wrap {
  float: right;
  width: 282px;
  margin: 30px 0 0 25px;
}

.news-single .article .news-img-wrap img {
  float: none;
}

@include media-breakpoint-down(md) {
  .news-single .article .news-img-wrap {
    float: none;
    width: 100%;
    margin: 0;
  }

  .news-single .article .news-img-wrap img {
    width: 100%;
  }
  .news-single .article .news-img-wrap video {
    width: 100%;
    height: auto;
  }
}

.news-single .teaser-text {
  font-size: 15px;
  color: #888;
}

.news-text-wrap {
  margin-bottom: 10px;

  p {
    margin-bottom: 11px;
  }

  ul {
    padding-left: 40px;
    margin-bottom: 11px;

    li {
      padding-left: 0;
      margin-bottom: 11px;
    }
  }
}

.news-img-caption {
  color: #888;
}

.news-related {
  .news-related__files {
    .news-list {
      row-gap: 24px;

      &__item {
        &-wrapper {
          column-gap: $grid-gutter-width * 0.25;
        }

        &-title {
          hyphens: auto;
        }
      }
    }
  }

  .news-list {
    a {
      @include anchor-style;

      &.download-btn {
        column-gap: $grid-gutter-width * 0.25;
      }
    }
  }

  .news-related__downloads {
    .news-list {
      display: flex;
      flex-direction: column;
      gap: $grid-gutter-width * 1.5;

      &__item {
        justify-content: space-between;
        gap: $grid-gutter-width * 0.5;

        .picturelib-img {
          gap: $grid-gutter-width * 0.25;

          @include media-breakpoint-up(xl) {
            width: 66%;
          }

          img {
            max-width: 110px;
            max-height: 78px;
          }

          .piclib-popup-overlay {
            width: 110px;
            height: 78px;
          }

          .picturelib-name {
            margin-bottom: 2px;
          }
        }

        .picturelib-area {
          row-gap: $grid-gutter-width * 0.25;

          @include media-breakpoint-up(xl) {
            flex: 1;
          }

          .piclib-filesize {
            margin-left: 4px;
          }
        }
      }
    }
  }
}

.news-clear {
  clear: both;
}

.news .select-field {
  label,
  .default,
  .selected {
    font-weight: normal;
  }
}

.news .select-field:has(.open) .select {
  display: block;

  li {
    margin-right: 16px;
  }
}
