html {
  scroll-padding-top: 60px;
  scroll-behavior: smooth;
  @include media-breakpoint-up('lg') {
    scroll-padding-top: 160px;
  }
}

body {
  & > header#header,
  header#header + section.hero,
  & > main#content,
  & > footer,
  .hero-slider {
    max-width: 1900px;
    margin: 0 auto;
  }
}
