.flexslider {
  .slider_image {
    a.fullscreen-image {
      position: relative;

      .magnify {
        background: map-get($colors, 'white');
        border-radius: 100%;
        width: 40px;
        height: 40px;
        position: absolute;
        bottom: $grid-gutter-width * 0.5;
        right: $grid-gutter-width * 0.5;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    figcaption {
      font-size: 15px;
      margin-top: $grid-gutter-width * 0.25;
    }
  }
}

.custom-controls-container {
  margin-top: $grid-gutter-width * 0.75;

  ul {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    justify-content: space-between;

    li a {
      text-decoration: none;

      .ps-icon-angle-right {
        position: relative;

        &:before {
          position: absolute;
          left: -10px;
        }
      }
    }
  }
}
