$supi-colors: (
  primary: #666,
  button-bar: #fff,
  success: map-get($colors, success),
  warning: #fff,
  notice: #000,
  dark-red: #831513,
  button-wrap-bgcolor: #f2f2f2,
);

$supi-grid-gutter-width: 40px;
$supi-font-size-head: 24px;
$supi-box-shadow: false;
$supi-border: false;
$supi-banner-box-padding: 20px;

$supi-button-wrap-padding: 30px;
$supi-banner-box-margin: $supi-grid-gutter-width * 0.5;

@import '../../../../../vendor/supseven/supi/Build/Src/Scss/Supi';

.tx-supi {
  &__block-item__table-overview {
    @extend .tx-supi__block-item__table-cookie;
  }

  &__label {
    margin-top: 0;
  }

  &__block-item__table {
    margin-bottom: $grid-gutter-width * 0.5;
  }

  &__block-item__table > * th {
    flex-basis: 220px;
  }

  &__block-body {
    margin: $grid-gutter-width * 0.25 4 0 $grid-gutter-width 0;

    ul {
      margin: 0;
    }
  }

  .supi-checkbox {
    align-items: flex-start;
    margin: 5px 0;

    input[disabled] + label {
      cursor: not-allowed;
    }
  }

  button#supi__choose,
  button#supi__individualSwitchTo,
  button#supi__dismiss {
    margin: 0;
    line-height: 2;
    padding: 0 $supi-banner-box-padding * 0.5;
    border-radius: 4px;
    background: var(--supi-color-allow-background);
    color: var(--supi-color-allow);
  }

  &__block {
    &-header {
      & > button {
        &:before {
          top: 6px !important;
        }

        &.tx-supi__pane-active {
          &:before {
            top: 4px !important;
          }
        }
      }
    }

    &-item {
      & > div {
        small {
          &.description {
            opacity: 1;
          }
        }
      }
    }
  }

  &__button {
    margin-bottom: 50px;

    &-content {
      margin-bottom: 30px;
    }
  }

  &.theme-default {
    font-size: 14px;
    box-shadow: 0px 0px 8px 4px rgba(34, 34, 34, 0.1);
    overflow: auto;

    @media (min-width: $supi-breakpoint) {
      max-width: 800px;
    }

    h3 {
      @include media-breakpoint-up(md) {
        display: none;
      }
    }

    table tr td a {
      color: __get($colors, 'brand');
      text-decoration: underline;
      text-underline-offset: 4px;
    }

    p {
      margin-bottom: 20px;

      &:last-child {
        margin: 0;
      }
    }

    #supi__elements {
      padding-top: 0 !important;
      padding-bottom: 0 !important;
      margin-bottom: 0;
      list-style: none;

      li {
        display: flex;
        flex-direction: column;

        .supi-element__label,
        .supi-element__names {
          display: none;
        }
      }
    }

    #supi__elements,
    #supi__description {
      a,
      .tx-supi__link-button {
        @include anchor-style;
      }
    }

    .tx-supi__buttongroup {
      ul {
        margin: 0;
        justify-content: flex-start;
        flex-wrap: wrap;

        @media (min-width: $supi-breakpoint) {
          justify-content: space-between;
        }

        li {
          display: flex;
          flex: 0 0 auto;

          & + li {
            margin-top: 0;
          }

          button {
            line-height: 2;
            padding: 0 10px;
            margin-bottom: $supi-banner-box-padding * 0.5;
            border-radius: 4px;

            @media (min-width: $supi-breakpoint) {
              margin: 0;
            }

            @include media-breakpoint-between(xs, md) {
              margin-right: $grid-gutter-width * 0.5;
            }

            &#supi__save,
            &#supi__switchTo {
              font-weight: bold;
              color: #000;
              background: none;
              padding-left: 5px;
            }

            &#supi__allow,
            &#supi__save {
              border-radius: 4px;
            }

            &#supi__save {
              background: map-get($supi-colors, success);
              color: map-get($supi-colors, warning);
              font-weight: 400;
              padding: 0 10px;
              border-radius: 4px;
            }
          }
        }
      }
    }
  }

  &__link {
    &-button {
      &[aria-expanded] {
        &:before {
          top: 11px;
        }
      }

      &[aria-expanded='true'] {
        &:before {
          top: 8px;
        }
      }
    }
  }

  @include media-breakpoint-only(xs) {
    #supi__overview {
      .tx-supi__buttongroup {
        ul {
          justify-content: space-between;

          button {
            margin: $grid-gutter-width * 0.25 0;
          }
        }
      }
    }
  }
}

.supi-service {
  background: __get($colors, very-light-grey);
  padding: $grid-gutter-width;
  text-align: center;
  margin: $grid-gutter-width 0;

  p {
    text-align: center;
  }

  &.chart {
    .infobar & {
      padding: $grid-gutter-width * 0.5;
    }
  }
}

@include ie11-only-wrap-shizzle('.tx-supi.position') {
  position: relative;
}

.vacancies {
  &-text {
    a {
      font-weight: bold;
      text-decoration: underline;
    }
  }
}

.supi-link {
  @include anchor-style;
  background-color: unset;
  border: none;
  padding: 0;
}

.tx-supi a:focus-visible,
.tx-supi__link-button:focus-visible {
  overflow: visible !important;
  color: __get($colors, 'brand-darker') !important;
  text-decoration: underline !important;
  text-underline-offset: 4px;
  text-decoration-thickness: 2px !important;
}

.tx-supi a:hover,
.tx-supi__link-button:hover {
  color: __get($colors, 'brand-darker') !important;
  text-decoration: underline !important;
  text-underline-offset: 4px;
  text-decoration-thickness: 1px !important;
}

.tx-supi button:not(.tx-supi__link-button):focus-visible,
.tx-supi button:not(.tx-supi__link-button):hover {
  background-color: __get($colors, 'brand-darker') !important;
  border-color: __get($colors, 'brand-darker') !important;
}
