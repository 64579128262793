/**
 * AddToCalendar White Blue Menu Style
 * http://addtocalendar.com
 */

/* Base */

.addtocalendar var {
  display: none;
}

.addtocalendar ul li:before {
  padding-right: initial !important;
  margin-left: initial !important;
  font-size: initial !important;
  content: '' !important;
  vertical-align: middle !important;
  padding-bottom: initial !important;
}

.addtocalendar {
  position: relative;
  display: inline-block;
  background: transparent !important;
}

.atcb-link {
  display: block;
  outline: none !important;
  cursor: pointer;
}

.atcb-link:focus ~ ul,
.atcb-link:active ~ ul,
.atcb-list:hover {
  visibility: visible;
}

.atcb-list {
  visibility: hidden;
  position: absolute;
  top: 100%;
  left: 0;
  width: 170px;
  z-index: 900;
}

.atcb-list,
.atcb-item {
  list-style: none;
  margin: 0;
  padding: 0;
  background: #fff;
}

.atcb-item {
  float: none;
  text-align: left;
}

.atcb-item-link {
  text-decoration: none;
  outline: none;
  display: block;
}

.atcb-item.hover,
.atcb-item:hover {
  position: relative;
  z-index: 900;
  cursor: pointer;
  text-decoration: none;
  outline: none;
}

/* Menu WB */

.atc-style-menu-wb .atcb-list {
  width: 170px;
  border: 1px solid rgb(186, 186, 186);
  border-radius: 2px;
  box-shadow: 0 0 5px #aaa;
}

.atc-style-menu-wb .atcb-list,
.atc-style-menu-wb .atcb-item {
  background: #fff;
  color: #000;
}

.atc-style-menu-wb .atcb-item,
.atc-style-menu-wb .atcb-item-link {
  line-height: 1.3em;
  vertical-align: middle;
  zoom: 1;
}

.atc-style-menu-wb .atcb-item-link,
.atc-style-menu-wb .atcb-item-link:hover,
.atc-style-menu-wb .atcb-item-link:active,
.atc-style-menu-wb .atcb-item-link:focus {
  color: #000;
  font-family: 'Verdana';
  font-size: 14px;
  text-decoration: none;
  outline: none;
  padding: 5px 15px;
}

.atc-style-menu-wb .atcb-item-link:hover,
.atc-style-menu-wb .atcb-item-link:active,
.atc-style-menu-wb .atcb-item-link:focus {
  color: #fff;
}

.atc-style-menu-wb .atcb-item.hover,
.atc-style-menu-wb .atcb-item:hover {
  background: rgb(66, 129, 244);
}
