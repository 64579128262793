@mixin make-row() {
  margin-left: -15px;
  margin-right: -15px;

  &:before {
    content: ' ';
    display: table;
  }

  &:after {
    content: ' ';
    display: table;
    clear: both;
  }
}

@mixin arrows() {
  padding-left: $grid-gutter-width;

  &:before {
    content: '';
    border: 1px solid #af1c1a;
    position: absolute;
    left: 0;
    width: 15px;
    top: 10px;
  }

  &:after {
    content: '\e602';
    font-family: ps-icon;
    speak: none;
    font-style: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    position: absolute;
    left: 9px;
    top: 5px;
    font-size: 13px;
    font-weight: bold;
  }
}

@mixin text-shadow() {
  text-shadow: 0 0 50px rgba(#000, 0.8);
}

@mixin custom-select($bg-color, $color) {
  appearance: none;
  background-color: $bg-color !important;
  background-image: svg-icon-angle($color) !important;
  background-repeat: no-repeat, repeat !important;
  background-position:
    right 1.1875em top 50%,
    0 0 !important;
  background-size:
    0.875em auto,
    100% !important;
}

@mixin custom-checkbox($bg-color) {
  background-color: $bg-color !important;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='24px' viewBox='0 0 24 24' width='24px' fill='%23FFFFFF'%3E%3Cpath d='M0 0h24v24H0z' fill='none'/%3E%3Cpath d='M9 16.2L4.8 12l-1.4 1.4L9 19 21 7l-1.4-1.4L9 16.2z'/%3E%3C/svg%3E");
  background-repeat: no-repeat !important;
  background-position:
    left 0.0425em top 0.0625em,
    0 0 !important;
  background-size:
    0.875em auto,
    100% !important;
}
