.ce-textmedia {
  &.ce-intext {
    .ce-gallery {
      margin-bottom: math.div($grid-gutter-width, 2);
      figure {
        text-align: center;
        img {
          max-width: 100%;
          height: auto;
        }
      }
    }

    @include media-breakpoint-up(xl) {
      &.ce-left .ce-gallery {
        float: left;
        margin-right: $grid-gutter-width;
      }

      &.ce-right .ce-gallery {
        float: right;
        margin-left: $grid-gutter-width;
      }
    }
  }
}
