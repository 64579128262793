/** little promo badge font change fixes **/
.promo-badge {
  img {
    transform: rotate(11deg);
  }

  .inside-wrapper {
    display: flex;
    align-items: center;
    padding: 0;

    .badge-text {
      padding: 0 $grid-gutter-width * 0.5;

      .title {
        font-size: 20px;
      }

      .text {
        font-size: 16px;

        &.date {
          font-size: 14px;
        }
      }
    }
  }
}
