.mfp-close span {
  pointer-events: none;
}

.mfp-zoom-out-cur .mfp-image-holder .mfp-close {
  cursor: pointer;
}

body.tmpl-Story {
  header + .hero {
    .slider_info {
      .psslider-title {
        text-shadow: none;
        background: __get($colors, 'white');
        opacity: 0.9;
        font-size: 36px;
        line-height: 36px;
        padding: $grid-gutter-width;
        font-family: $font-family-header-bold;
        display: inline-block;
      }
    }
  }

  .main ul li:before {
    display: none;
  }

  .slider-holder {
    &.position-left {
      float: left;
      margin-right: 55px;
      margin-left: 0;
    }
  }
}
