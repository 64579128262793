@import '../../Scss/00-FontVariables';

/* Browser Resets */
.flex-container a:active,
.flexslider a:active,
.flex-container a:focus,
.flexslider a:focus {
  outline: none;
}

.slides,
.flex-control-nav {
  margin: 0;
  padding: 0;
  list-style: none;
}

/* FlexSlider Necessary Styles
*********************************/
.flexslider {
  margin: 0;
  padding: 0;
}

.flexslider .slides > li {
  display: none;
  -webkit-backface-visibility: hidden;
}

/* Hide the slides before the JS is loaded. Avoids image jumping */
.flexslider .slides img {
  width: 100%;
  display: block;
  height: auto;
}

.flex-pauseplay span {
  text-transform: capitalize;
}

/* Clearfix for the .slides element */
.slides:after {
  content: '.';
  display: block;
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0;
}

html[xmlns] .slides {
  display: block;
}

* html .slides {
  height: 1%;
}

/* No JavaScript Fallback */
/* If you are not using another script, such as Modernizr, make sure you
 * include js that eliminates this class on page load */
.no-js .slides > li:first-child {
  display: block;
}

/* FlexSlider Default Theme
*********************************/
.flexslider {
  margin: 0 0 60px;
  background: #fff;
  border: 4px solid #fff;
  position: relative;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  -o-border-radius: 4px;
  border-radius: 4px;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
  -webkit-box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
  -o-box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
  zoom: 1;
}

.flexslider-noborder {
  margin: 0;
  border: none;
  background: none;
  border-radius: 0;
  -webkit-border-radius: 0px;
  -moz-border-radius: 0px;
  -o-border-radius: 0px;
  box-shadow: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  -o-box-shadow: none;
}

.flex-viewport {
  max-height: 2000px;
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  transition: all 1s ease;
}

.loading .flex-viewport {
  max-height: 300px;
}

.flexslider .slides {
  zoom: 1;
}

.carousel li {
  margin-right: 5px;
}

.flexslider:hover .flex-next {
  opacity: 0.8;
  right: 5px;
}

.flexslider:hover .flex-prev {
  opacity: 0.8;
  left: 18px;
}

.flexslider:hover .flex-next:hover,
.flexslider:hover .flex-prev:hover {
  opacity: 1;
}

/* Control Nav */
.flex-control-nav {
  width: 100%;
  position: absolute;
  bottom: -40px;
  text-align: center;
}

.flex-control-nav li {
  margin: 0 6px;
  display: inline-block;
  zoom: 1;
  *display: inline;
}

.flex-control-paging li a {
  width: 11px;
  height: 11px;
  display: block;
  background: #fff;
  cursor: pointer;
  text-indent: -9999px;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  -o-border-radius: 20px;
  border-radius: 20px;
  box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3);
}

.flex-control-paging li a:hover {
  background: #333;
}

.flex-control-paging li a.flex-active {
  background: #000;
  cursor: default;
}

.flex-control-thumbs {
  margin: 5px 0 0;
  position: static;
  overflow: hidden;
}

.flex-control-thumbs li {
  width: 25%;
  float: left;
  margin: 0;
}

.flex-control-thumbs img {
  width: 100%;
  display: block;
  opacity: 0.7;
  cursor: pointer;
}

.flex-control-thumbs img:hover {
  opacity: 1;
}

.flex-control-thumbs .flex-active {
  opacity: 1;
  cursor: default;
}

@media screen and (max-width: 860px) {
  .flex-direction-nav .flex-prev {
    opacity: 1;
    left: 0;
  }

  .flex-direction-nav .flex-next {
    opacity: 1;
    right: 0;
  }
}

.feature-slider-holder {
  margin-bottom: 50px;
  padding: 10px;
}

.feature-slider-holder .flexslider {
  overflow: hidden;
  height: 500px;
}

.feature-slider-holder .flexslider img {
  max-width: none;
}

.feature-slider-holder .flexslider .slides > li {
  position: relative;
  overflow: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
}

.feature-slider-holder .flexslider .slides > li .slider_info {
  z-index: 99;
}

.feature-slider-holder .flexslider .slider_info h3 {
  color: #222;
  font-size: 42px;
  font-family: $font-family;
  font-weight: 700;
  font-style: normal;
  line-height: 52px;
}

.page-scheme-light .feature-slider-holder .flexslider .slider_info h3 {
  color: #fcfcfc;
}

.feature-slider-holder .flexslider .slider_info p {
  color: #222;
  font-size: 22.5px;
  line-height: 130%;
}

.page-scheme-light .feature-slider-holder .flexslider .slider_info p {
  color: #fcfcfc;
}

.feature-slider-holder .flexslider img {
  position: absolute;
  image-rendering: -webkit-optimize-contrast;
}

.feature-slider-holder .flexslider .shadow-top,
.feature-slider-holder .flexslider .shadow-bottom {
  width: auto;
  left: 50%;
  margin-left: -960px;
}

.feature-slider-holder .flexslider .shadow-top {
  top: 0;
}

.feature-slider-holder .flexslider .shadow-grid {
  display: block;
}

.feature-slider-holder .flexslider .shadow-grid-small {
  display: none;
}

.feature-slider-holder .flexslider .shadow-grid-smaller {
  display: none;
}

.feature-slider-holder .flexslider .shadow-bottom {
  bottom: -100px;
}

.feature-slider-holder .flexslider a {
  text-decoration: none;
}

.feature-slider-holder .flexslider a:hover {
  text-decoration: none;
}

.feature-slider-holder .flexslider .slider-separator {
  z-index: 999;
  margin-left: -100px;
  position: absolute;
  width: 199px;
  height: 6px;
  bottom: 83px;
  left: 50%;
}

.feature-slider-holder .flex-viewport,
.feature-slider-holder .flexslider .slides,
.feature-slider-holder .flexslider .slides > li {
  height: 100%;
}

.feature-slider-holder .container {
  position: relative;
  height: 100%;
}

.feature-slider-placeholder {
  height: 210px;
  margin: 0 0 60px;
}

ol.flex-control-nav {
  list-style: none;
  margin: 0;
  bottom: 5px !important;
}

ol.flex-control-nav.flex-control-paging li {
  padding: 0;
}

ol.flex-control-nav.flex-control-paging li a {
  background: #fff !important;
}

ol.flex-control-nav.flex-control-paging li a:hover {
  background: #fff !important;
}

ol.flex-control-nav.flex-control-paging li a.flex-active {
  background: rgb(175, 28, 26) !important;
  cursor: default;
}

.feature-slider-holder .flexslider .slider_image .dark-overlay {
  width: 100%;
  height: 100%;
  position: absolute;
}

.feature-slider-holder .flexslider .slider_image .dark-overlay.darken-full {
  background-color: #000000;
  filter: alpha(opacity=50);
  opacity: 0.5;
}

.feature-slider-holder .flexslider .slider_image .dark-overlay.darken-gradient-bottom {
  background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIwJSIgeTI9IjEwMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzAwMDAwMCIgc3RvcC1vcGFjaXR5PSIwIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiMwMDAwMDAiIHN0b3Atb3BhY2l0eT0iMSIvPgogIDwvbGluZWFyR3JhZGllbnQ+CiAgPHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEiIGhlaWdodD0iMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+);
  filter: alpha(opacity=50);
  background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 0%, #000000 100%);
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, #000000 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#00000000', endColorstr='#ff000000', GradientType=0);
  opacity: 0.5;
}

.feature-slider-holder .flexslider .slider_image .dark-overlay.darken-gradient-top {
  background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIwJSIgeTI9IjEwMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzAwMDAwMCIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiMwMDAwMDAiIHN0b3Atb3BhY2l0eT0iMCIvPgogIDwvbGluZWFyR3JhZGllbnQ+CiAgPHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEiIGhlaWdodD0iMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+);
  filter: alpha(opacity=50);
  background-image: -webkit-linear-gradient(top, #000000 0%, rgba(0, 0, 0, 0) 100%);
  background-image: linear-gradient(to bottom, #000000 0%, rgba(0, 0, 0, 0) 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff000000', endColorstr='#00000000', GradientType=0);
  opacity: 0.5;
}

.show-more {
  text-decoration: none;
  padding: 0 30px;
}
