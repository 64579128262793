.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  content: '';
}

.text-color {
  @each $key, $value in $colors {
    &-#{$key} {
      color: $value !important;
    }
  }
}

.text-link {
  text-decoration: underline;
  text-underline-offset: 4px;
}

.z-index-1 {
  z-index: 1 !important;
}

.position-relative {
  position: relative !important;
}

.float {
  &-left {
    float: left;
  }

  &-right {
    float: right;
  }
}
