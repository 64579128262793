.teaser-gutter-wrapper {
  margin-top: -$grid-gutter-width * 0.5;
  margin-bottom: -$grid-gutter-width * 0.5;

  & > [class*='col-'] {
    padding-top: $grid-gutter-width * 0.5;
    padding-bottom: $grid-gutter-width * 0.5;
  }
}

.teaser-tile {
  .front .title,
  .front .content {
    color: __get($colors, 'dark-grey');
  }

  strong {
    text-decoration: underline;
    text-underline-offset: 4px;
  }
}
