.ce {
  &-headline {
    &-center {
      text-align: center;
    }
  }

  &-div {
    margin: __get($spacers, 'small') 0;
  }

  &-textpic {
    @include make-row;
    @extend .clearfix;

    .csc-header {
      @extend .col-xs-12;

      h2 {
        font-size: 24px;
      }
    }

    .ce-bodytext {
      @extend .col-xs-12;
    }

    .ce-bodytext .csc-header {
      padding: 0;
      float: none;
    }

    .ce-gallery {
      @extend .col-xs-12;

      img {
        @extend .img-responsive;
      }

      figcaption {
        @extend .simplyimage-subtitle;
      }
    }

    &.ce-left,
    &.ce-right {
      > .ce-gallery {
        @extend .col-lg-5, .col-md-5, .col-sm-6, .col-xs-12;
      }
    }

    &.ce-right {
      > .ce-gallery {
        float: right;
        margin-bottom: 11px;
      }

      > .ce-bodytext {
        float: unset;
      }
    }

    &.ce-left {
      > .ce-gallery {
        float: left;
        margin-bottom: 11px;
      }

      > .ce-bodytext {
        float: unset;
      }
    }

    &.ce-center {
      .ce-column {
        display: flex;
        justify-content: center;
      }
    }
  }
}
