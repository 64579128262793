.nav-menu_section {
  list-style: none;
  margin: 0 0 $grid-gutter-width !important;
  padding: 0;

  .nav-item {
    &:before {
      display: none;
    }

    .nav-link {
      font-weight: bold;
      padding: 4px 0;

      &:focus,
      &:hover {
        background: transparent;
        color: __get($colors, 'red');
      }

      &:after {
        padding-left: 6px;
        content: '\203A';
        font-size: 16px;
        margin: -1px;
      }
    }
  }
}
