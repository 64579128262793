.expnum {
  &-smalltitle {
    color: map-get($colors, dark-grey-hover);
  }
}

// exposed number used in hero column needs special stylings
.hero {
  .numbersBgImage {
    aspect-ratio: 3/1;
    display: flex;

    .pos-verticalposition-middle {
      transform: translateY(0);
      align-self: center;
    }
  }
}

.expnum-link a {
  @include anchor-style;
}
