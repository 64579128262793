// new infobar behaviour, if there are 3 CE beside 1 new element
$infobar-margin-bottom-for-title: 22px;

.infobar {
  &-text {
    h2 {
      @extend .infobar__title;
      margin: 0;
      padding-bottom: 10px;
      @include media-breakpoint-up(lg) {
        margin-bottom: $infobar-margin-bottom-for-title;
      }
    }

    &-more {
      margin-top: 15px;
    }

    p {
      font-size: 17px;
      line-height: 25px;
    }

    a {
      color: __get($colors, 'red');

      &:hover,
      &:focus {
        opacity: 0.7;
      }
    }
  }

  &__title {
    color: __get($colors, dark-grey-hover) !important;
    font-family: $font-family !important;
    font-size: 16px !important;
    margin: 0 !important;
    padding: 0 !important;

    & + * {
      padding-top: 10px;

      @include media-breakpoint-up(lg) {
        padding-top: 30px;
      }
    }
  }

  &__quicklinks {
    @include media-breakpoint-only(xs) {
      margin-top: 0 !important;
      margin-bottom: 35px;
      padding: 0 !important;
    }
  }

  & &__quicklinks {
    text-align: left !important;
  }

  &__graph {
    @include media-breakpoint-only(xs) {
      margin-top: 0 !important;
      padding: 0 !important;
    }
  }

  &__chartcontainer {
    margin-top: -5px;
  }

  &-text {
    flex-direction: column;
    margin-bottom: 35px;

    & > * {
      &:last-child {
        margin-top: auto !important;
        padding-top: 15px;
      }
    }
  }

  &-news {
    align-items: stretch;
    flex-direction: column;

    @include media-breakpoint-up(lg) {
      flex-direction: row;
    }

    &__inner {
      h2 {
        font-family: $font-family;
        font-size: 16px;
        margin: 0 0 $grid-gutter-width;
        color: __get($colors, 'dark-grey-hover');
      }

      align-items: stretch;

      &--top {
        flex-direction: column;

        @include media-breakpoint-up(md) {
          flex-direction: row;
        }
      }

      &--bottom {
        @include media-breakpoint-only(xs) {
          flex-direction: column;
        }
        @include media-breakpoint-up(md) {
          align-items: stretch;
          margin: 0 -15px;
          height: 100%;
        }
      }
    }

    &__three {
      flex-direction: column;

      @include media-breakpoint-only(xs) {
        padding-left: 15px;
        padding-right: 15px;
      }

      @include media-breakpoint-up(md) {
        flex-direction: row;
        flex-wrap: wrap;
      }

      @include media-breakpoint-up(lg) {
        flex-wrap: nowrap;
      }

      & > * {
        @include media-breakpoint-down(md) {
          width: 100% !important;
          padding: 0 !important;
        }

        @include media-breakpoint-up(lg) {
          width: 33.33% !important;
        }

        &:last-child,
        &:nth-last-child(2) {
          @include media-breakpoint-only(md) {
            width: 50% !important;
          }
        }
      }
    }

    &-single {
      flex-direction: column;

      & > * {
        &:last-child {
          margin-top: auto !important;
          padding-top: 15px;
        }
      }
    }

    &-category,
    &-date,
    &-title {
      font-size: 16px;
      margin: 0;
    }

    &-category,
    &-date {
      color: map-get($colors, dark-grey-hover);
    }

    &-more-a {
      @include anchor-style;
    }
  }

  &-quicklinks {
    a {
      @include anchor-style;
    }
  }

  .columns-2 {
    .infobar-news__inner > div,
    .infobar-graph {
      @extend .col-md-6, .col-sm-12;
    }
  }

  .columns-3 {
    .infobar-graph,
    .infobar-text,
    .infobar-quicklinks {
      @extend .col-md-4, .col-sm-12;
    }
  }
}
