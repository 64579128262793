@use 'sass:math';

table {
  &.customTable {
    th {
      padding: 10px 40px;
      border-bottom: 2px solid #e2e2e2;
      //font-size: 16px;
      //line-height: 23px;
    }
    @media (max-width: 1200px) {
      .customTable th {
        padding: 10px 24px;
      }
    }
    @media (max-width: 542px) {
      .customTable th {
        padding: 10px 14px;
      }
    }
    @media (max-width: 480px) {
      .customTable th {
        padding: 10px 5px;
      }
    }
    tr {
      &:first-child {
        td {
        }
      }
    }
  }

  &.table-responsive {
    width: calc(100dvw - 20px);
  }

  &.table-special {
    --col-width: 110px;

    th,
    td {
      border: 1px solid map-get($colors, 'very-light-grey') !important;
    }

    th {
      background: map-get($colors, 'very-light-grey');

      &:not(:first-child) {
        text-align: center;
      }
    }

    td {
      &:first-child {
        width: var(--col-width);
        vertical-align: middle;

        a {
          display: grid;
          background: yellow;
          width: var(--col-width);
          aspect-ratio: 3/1;
          position: relative;

          i {
            display: none;
          }

          &:before {
            content: '';
            position: absolute;
            inset: 0;
            text-align: center;
            background: #fff var(--logo-bg) no-repeat center;
            background-size: contain;
          }

          @each $name in (sustainalytics, msci, issesg, epra) {
            &[title*='#{$name}'] {
              --logo-bg: url('../Images/ESG_Nachhaltigkeit/#{$name}.svg');
            }
          }
        }
      }
    }
    td:not(:first-child) {
      text-align: center;
      vertical-align: middle;
    }
  }
}
