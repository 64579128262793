.pre-footer-background {
  &-1 {
    background: __get($colors, very-light-grey);
    margin: 0 0;
    width: 100%;
  }
}

.footer {
  padding-bottom: $grid-gutter-width;
  background: __get($colors, very-light-grey);

  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  &__list {
    flex-wrap: wrap;
    justify-content: center;
    text-align: center;
    align-items: center;
  }

  &__listitem {
    position: relative;
    margin: 5px 0 !important;
    align-items: center;
    padding: 0 $grid-gutter-width * 0.5;

    .dots:before,
    .dots:after {
      content: '•';
      position: absolute;
      left: -3px;
      top: 3px;
      color: __get($colors, success);
    }

    .dots:after {
      right: -3px;
      left: auto;
    }

    @include media-breakpoint-up(xl) {
      &:first-child .dots:before,
      &:last-child .dots:after {
        display: none;
      }
    }
  }

  &__links {
    color: __get($colors, success) !important;
    font-family: $font-family-header !important;
    font-size: 20px !important;
    text-transform: uppercase;
    letter-spacing: 2px;

    &:hover,
    &:focus {
      color: __get($colors, 'brand-darker') !important;
      text-decoration: underline;
      text-underline-offset: 5px;
    }

    &:hover {
      text-decoration-thickness: 1px;
    }

    &:focus {
      text-decoration-thickness: 2px;
    }
  }

  &__logos {
    display: none;

    @include media-breakpoint-up(md) {
      display: flex;
    }

    flex-direction: column;
    justify-content: center;
    align-items: flex-start;

    @include media-breakpoint-up(lg) {
      align-items: center;
      justify-content: space-between;

      & > div {
        flex: 1 0 auto;
        width: auto;
        float: none;
        margin: $grid-gutter-width * 0.5 0;
      }
    }

    @include media-breakpoint-up(xl) {
      flex-direction: row;

      & > div {
        margin: 0;
      }
    }
  }

  &__copyright {
    text-align: center;

    @include media-breakpoint-up(xl) {
      text-align: left;
      margin: 0;
    }
  }

  &__imprint {
    text-align: center;
    display: flex;
    flex-direction: column;
    gap: $grid-gutter-width * 0.5;

    @include media-breakpoint-up(xl) {
      flex-direction: row;
      gap: $grid-gutter-width;
      margin: 0 !important;

      li {
        padding: 0;

        a {
          @include anchor-style;
          color: #333;
          text-decoration: none;

          &:focus,
          &:hover {
            text-decoration: underline;
          }

          &:focus {
            text-decoration-thickness: 2px;
          }
        }
      }
    }
  }

  &__bottom {
    &-wrap {
      display: flex;
      align-items: center;
      flex-direction: column;
      gap: $grid-gutter-width;
    }

    &-microsite {
      display: flex;
      justify-content: space-between;
      flex-direction: column;
      align-items: center;
      gap: $grid-gutter-width;

      .copyright {
        color: map-get($colors, 'dark-grey');
      }

      @include media-breakpoint-up(xl) {
        flex-direction: row;
      }

      p {
        margin: 0;
        order: 1;

        @include media-breakpoint-up(xl) {
          order: 0;
        }
      }

      .microsite-footer-link {
        display: flex;
        margin: 0;
        gap: $grid-gutter-width * 0.5;
      }
    }
  }
}

.partner {
  & + & {
    margin: $grid-gutter-width 0 $grid-gutter-width * 2 0;
  }

  @include media-breakpoint-up(lg) {
    flex-wrap: wrap;
  }

  &__list {
    &-oneliner {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;

      .partner__item {
        padding: 0 $grid-gutter-width * 0.5 $grid-gutter-width * 0.5;
      }
    }
  }

  &__item {
    @include media-breakpoint-up(lg) {
      padding-bottom: 0 !important;
    }
  }

  &__link {
    &:focus {
      outline: 1px solid __get($colors, primary) !important;
      outline-offset: 3px;
    }
  }

  &__intro {
    text-align: center;

    @include media-breakpoint-up(lg) {
      margin: 0 $grid-gutter-width * 0.5 0 0;
      flex-basis: 100%;
      text-align: left;
      margin-bottom: 5px;
    }
  }

  &__list {
    text-align: center;

    @include media-breakpoint-up(lg) {
      margin-bottom: 0;
    }
  }

  &__oneliner {
    display: flex;
    justify-content: center;
    margin-bottom: $grid-gutter-width;

    .partner {
      padding: 0 $grid-gutter-width * 0.5;

      & + .partner {
        margin: 0;
      }
    }
  }

  &__column {
    @include media-breakpoint-up(lg) {
      margin-bottom: $grid-gutter-width;
      display: flex;
      justify-content: space-between;

      .partner {
        & + .partner {
          margin: 0;
        }
      }
    }
  }

  &__wrap {
    @include media-breakpoint-up(lg) {
      display: flex;

      .partner {
        & + .partner {
          margin: 0 0 0 $grid-gutter-width;

          .partner__intro {
            padding-left: $grid-gutter-width * 0.5;
            margin-right: 0;
          }
        }
      }
    }
  }
}

.social {
  justify-content: center;
  align-items: center;

  @include media-breakpoint-up(lg) {
    justify-content: flex-end;
  }

  &__list {
    justify-content: flex-end;
  }

  &__item {
    margin-top: 0 !important;

    & + & {
      margin-left: 10px;
    }
  }

  &__link {
    height: 40px;
    min-width: 40px;

    &:hover {
      opacity: 0.6;
    }

    &:focus {
      outline: 1px solid __get($colors, success) !important;
      outline-offset: 3px;
    }
  }
}
