@font-face {
    font-family: 'ps-icon';
    src: url('../Fonts/ps-icon/ps-icon.eot?4kget4');
    src:
        url('../Fonts/ps-icon/ps-icon.eot?#iefix4kget4') format('embedded-opentype'),
        url('../Fonts/ps-icon/ps-icon.ttf?4kget4') format('truetype'),
        url('../Fonts/ps-icon/ps-icon.woff?4kget4') format('woff'),
        url('../Fonts/ps-icon/ps-icon.svg?4kget4#ps-icon') format('svg');
    font-weight: normal;
    font-style: normal;
}

.ps-icon {
    font-family: 'ps-icon';
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

.ps-icon-breadcrumb-arrow:before {
    content: '\e606';
}
.ps-icon-hotspot_plus:before {
    content: '\e605';
}
.ps-icon-kran:before {
    content: '\e600';
}
.ps-icon-angle-left:before {
    content: '\e601';
}
.ps-icon-angle-right:before {
    content: '\e602';
}
.ps-icon-camera:before {
    content: '\e603';
}
.ps-icon-expand:before {
    content: '\e604';
}
