/**
    overrides and modifies the existing parkside project list legacy scss
 */
@use 'sass:math';

.project {
  &-list {
    &.bild-archiv-list {
      .projects {
        display: block;
      }
    }

    .projects {
      display: flex;
      flex-wrap: wrap;
    }

    &-item {
      .project-status-7 {
        span {
          &:before {
            background-image: url('../Images/developed-by-ca-immo.svg');
            background-size: 40px 20px;
            display: inline-block;
            width: 40px;
            height: 20px;
            content: '';
            position: absolute;
            top: 50px;
            left: -48px;
          }
        }
      }
      .project-status-2 {
        span {
          font-size: 28px;
          line-height: 110px;
          color: #fff;
          margin-left: -42px;

          &:before {
            content: '\e600';
          }
        }
      }
    }
  }

  &-list &-list-item &-name {
    margin: ($grid-gutter-width * 0.25) 0 ($grid-gutter-width * 0.5) 0;

    h2 {
      font-size: 30px;
      line-height: 1.2;
      color: __get($colors, 'dark-grey');
      margin: 0;
    }
  }

  &-list &-list-item &-usage {
    display: flex;
    flex-direction: column;
    margin: 0;
    font-size: 15px;
    line-height: 1.2;

    & > span {
      margin-bottom: ($grid-gutter-width * 0.5);
    }
  }

  &-separator {
    display: flex;

    & > span {
      display: flex;
      align-items: center;

      &:after {
        content: '';
        width: 4px;
        height: 4px;
        background: __get($colors, 'black');
        border-radius: 100%;
        margin: 0 ($grid-gutter-width * 0.25);
      }

      &:last-child:after {
        display: none;
      }
    }
  }

  &-location {
    svg {
      margin-right: $grid-gutter-width * 0.25;
    }
  }

  &-list &-list-item &-icons {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin: ($grid-gutter-width * 0.5) math.div($grid-gutter-width, -8) 0 math.div($grid-gutter-width, -8);

    img {
      width: auto;
      flex: 0 1 auto;
      padding: 0 ($grid-gutter-width * 0.125);
      margin-bottom: $grid-gutter-width * 0.125;
      max-height: 40px;
      max-width: 70px;
    }
  }

  &-filter {
    .select {
      font-size: 1.6rem;

      ul {
        margin-left: 0;
      }

      li:not(.optgroup),
      .optgroup > span {
        display: flex;
        justify-content: space-between;
        padding: 12px 24px;
        align-items: center;
        user-select: none;
        cursor: pointer;
        list-style: none;

        &:hover {
          background: __get($colors, 'very-light-grey');
        }
      }

      .optgroup li {
        padding-left: 40px;
        color: __get($colors, dark-grey);
      }

      &.has-scroll-indicator {
        padding-bottom: 40px;
      }

      .scroll-indicator {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: __get($colors, 'grey');
        opacity: 0;
        cursor: pointer;
        transition: opacity 0.2s ease;
        box-shadow: -5px 0 10px -5px fade-out(#000, 0.7);
      }
    }
  }

  &-detail {
    .border {
      border-bottom: 1px solid __get($colors, 'border-color');
    }

    h1.headline {
      border: none;
    }
  }

  &__certificates {
    padding: 0 0 $grid-gutter-width;
    border-bottom: 1px solid __get($colors, 'border-color');
  }

  main &__icons {
    list-style: none;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;

    li {
      padding: $grid-gutter-width * 0.25;
      position: relative;
      display: flex;
      align-items: center;
      transition: all 0.2s ease-in-out;

      &:before {
        display: none;
      }

      &:hover {
        transform: scale(1.2);
      }
    }
  }

  &__logo {
    display: flex;
    justify-content: flex-start;
    margin-right: $grid-gutter-width * 0.5;
    padding-right: $grid-gutter-width * 0.5;
    padding-top: $grid-gutter-width * 0.25;
    padding-bottom: $grid-gutter-width * 0.25;

    @include media-breakpoint-up(lg) {
      padding-bottom: $grid-gutter-width * 0.5;
      margin-bottom: $grid-gutter-width * 0.5;
      margin-right: 0;
      padding-right: 0;
      border-right: none;
      justify-content: flex-end;
    }
  }

  &__icon-bar {
    display: flex;

    @include media-breakpoint-up(lg) {
      margin-top: $grid-gutter-width * 0.5;
      display: block;
    }
  }
}

.project-detail a {
  @include anchor-style;
}
