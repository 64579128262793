.accordion {
  &__title {
    flex: 1 0 calc(100% - 45px);

    &--second,
    &--third {
      .accordion__button[aria-expanded='false'],
      .accordion__button[aria-expanded='true'] {
        color: __get($colors, 'dark-grey');
        text-transform: inherit;

        &:hover,
        &:focus {
          color: $brand-color;
        }
      }
    }

    &--second {
      @at-root h2#{&} {
        margin: 24px 0;
      }

      .accordion__arrow--large {
        height: 26px;
        width: 26px;
        flex: 0 0 26px;
        margin-top: 0;
      }
    }

    &--third {
      @at-root h3#{&} {
        margin: 27px 0;
      }

      .accordion__arrow--small {
        height: 20px;
        width: 20px;
        flex: 0 0 26px;
        margin-top: 0;
      }
    }
  }

  &__item {
    border-top: 1px solid __get($colors, not-so-dark-grey);
  }

  &__content {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;

    @include media-breakpoint-up(lg) {
      flex-direction: row;
      justify-content: space-between;
    }
  }

  &__arrow {
    display: block;
    height: 17px;
    width: 30px;
    transform: rotate(0);
    transition: transform 0.3s;
    flex: 0 0 30px;
    margin-top: 10px;
  }

  &__button {
    background: transparent;
    border: none;
    padding: 0;
    text-align: left;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    &[aria-expanded='false'] {
      color: __get($colors, 'dark-grey');
    }

    &:hover,
    &:focus,
    &[aria-expanded='true'] {
      color: $brand-color;
    }

    &[aria-expanded='true'] {
      .accordion__arrow {
        transform: rotate(180deg);
        transition: transform 0.3s;
      }
    }
  }

  &__panel {
    overflow: hidden;
  }

  &__panel-content {
    margin-top: 8px;
    margin-bottom: $grid-gutter-width * 1.86666667;
  }

  .main &__list {
    list-style: none;
    margin-top: 8px;
    margin-bottom: $grid-gutter-width * 1.86666667;
    margin-left: 0;
    padding-left: 0;
  }

  .main &__list-item {
    &::before {
      content: '';
      margin: 0;
      padding: 0;
    }
  }

  &__list-item {
    & + & {
      margin-top: $grid-gutter-width * 0.86666667;
    }
  }

  &__files {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 8px;

    @include media-breakpoint-up(lg) {
      justify-content: flex-start;
      align-items: flex-start;
      margin-top: 0;

      & > * + * {
        margin-left: $grid-gutter-width * 0.86666667;
      }
    }
  }

  &__link {
    color: $brand-color;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;

    &:hover,
    &:focus {
      color: $brand-color;
      text-decoration: underline;
      text-underline-offset: 0.15em;
    }
  }

  &__download {
    color: $brand-color;
    display: flex;
    justify-content: space-between;
    align-items: center;
    min-height: $grid-gutter-width * 0.86666667;

    &-icon {
      display: block;
      margin-right: $grid-gutter-width * 0.53333333;
    }

    &-description {
      padding-right: $grid-gutter-width * 0.5;
      padding-top: 3px;
    }
  }

  &__flip {
    align-items: center;
    background-color: transparent;
    border: none;
    color: $brand-color;
    display: none;
    justify-content: space-between;
    padding: 0;

    @include media-breakpoint-up(lg) {
      display: flex;
    }

    &:hover,
    &:focus {
      text-decoration: underline;
      text-underline-offset: 0.15em;
    }

    &-icon {
      display: block;
      margin-right: $grid-gutter-width * 0.53333333;
    }
  }

  &__placeholder {
    width: 96.2px;
  }

  &__figure {
    @include media-breakpoint-up(xl) {
      float: right;
      margin-left: $grid-gutter-width * 1.5;
    }
  }
}
