header#header {
  position: sticky;
  top: 0;

  @include media-breakpoint-up(lg) {
    top: -30px;
  }

  z-index: 1041;
  background: #fff;

  .navigation-wrap {
    height: 55px;
    align-items: center;
    position: relative;
    z-index: 1;
    justify-content: space-between;

    &:before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: __get($colors, background);
      z-index: 1;
    }

    @include media-breakpoint-up(lg) {
      height: 110px;

      &:before {
        display: none;
      }
    }
  }

  .toggle-mobile-navigation {
    border: none;
    background: none;
    width: 35px;
    height: 18px;
    position: relative;
    padding: 0;

    span {
      position: absolute;
      top: 0;
      left: 0;
      height: 2px;
      width: 100%;
      background: __get($colors, 'black');
      border-radius: 2px;
      transition: all 0.2s ease-in-out;

      &:after,
      &:before {
        transition: all 0.2s ease-in-out;
        content: '';
        width: 100%;
        height: 2px;
        position: absolute;
        left: 0;
        top: 8px;
        background: __get($colors, 'black');
        border-radius: 2px;
      }

      &:after {
        top: 16px;
      }

      body.show-mobile & {
        background: none;

        &:before {
          transform: rotate(-45deg);
        }

        &:after {
          top: 8px;
          transform: rotate(45deg);
        }
      }
    }
  }
}
