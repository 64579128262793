.iframe-wrapper {
  iframe {
    width: 100%;
    height: auto;
    border: none;
    overflow: hidden;
    opacity: 0;
    transition: opacity 0.2s ease-in-out;

    &.show-me {
      opacity: 1;
    }
  }
}
