$font-family: 'Open Sans', sans-serif;
$font-family-bold: 'Open Sans', sans-serif;
$font-family-header: 'Jomolhari-Regular', serif;
$font-family-header-bold: 'Jomolhari-Regular', serif;

$h1-font-size: var(--h1-font-size);
$h2-font-size: var(--h2-font-size);
$h3-font-size: var(--h3-font-size);
$h4-font-size: var(--h4-font-size);

$headings-font-settings: (
  1: (
    xs: 32px,
    xl: 40px,
    line-height: 1.1,
    color: #af1c1a,
  ),
  2: (
    xs: 26px,
    xl: 28px,
    line-height: 1.4,
    color: #333,
  ),
  3: (
    xs: 20px,
    xl: 24px,
    line-height: 1.4,
    color: #333,
  ),
  4: (
    xs: 20px,
    xl: 22px,
    line-height: 1.4,
    color: #333,
  ),
  5: (
    xs: 20px,
    xl: 22px,
    line-height: 1.4,
    color: #333,
  ),
  6: (
    xs: 20px,
    xl: 22px,
    line-height: 1.4,
    color: #333,
  ),
);
