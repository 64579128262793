.iframeInfobarEmbed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 5;
}

#chart {
  overflow: hidden;
  width: 100%;
  position: relative;
}

.supi-service.chart a {
  @include anchor-style;
}
