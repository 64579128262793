.pagination-wrapper {
  padding-top: $grid-gutter-width;
  padding-bottom: $grid-gutter-width;

  .pagination {
    list-style: none;
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-left: 0;

    .page-item {
      padding: 0;

      &:hover {
        background: none;
      }

      .page-link {
        border: 0;
        font-family: $font-family-header;
        font-size: 2.2rem;
        color: __get($colors, 'dark-grey');
        padding: 6px;

        &:hover {
          background: transparent;
          color: __get($colors, 'red');
        }
      }

      &:before {
        display: none;
      }

      &.more .page-link {
        color: __get($colors, 'black');
        font-weight: normal;
      }

      &.active .page-link {
        color: __get($colors, 'red');
        background: transparent;
      }
    }
  }
}
