/** new hero slider element 2021 */

@use 'sass:math';

:root {
  --badge-background: #{__get($colors, success)};
  --badge-text: #{__get($colors, 'white')};
  --badge-opacity: 0.8;
}

.hero-slider {
  position: relative;
  display: flex;
  flex-direction: column;

  & + #content {
    margin-top: $grid-gutter-width * 2;
  }

  &__badge {
    z-index: 2;
    background: var(--badge-background);
    color: var(--badge-text);
    font-size: 14px;
    text-align: center;
    order: 1;

    @include media-breakpoint-up(md) {
      &-image {
        background: transparent;
      }
    }

    @include media-breakpoint-up(md) {
      position: absolute;
      width: 260px;
      height: 260px;
      font-size: 26px;
      padding: 0;
      text-align: left;
      opacity: var(--badge-opacity);
      transform: scale(0.7) rotate(15deg);
      right: 0;
      top: 0;
      border-radius: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }

    @include media-breakpoint-up(xl) {
      transform: scale(1) rotate(15deg);
      right: $grid-gutter-width * 2;
      top: $grid-gutter-width * 2;
    }

    img {
      width: 100%;
      height: 100%;
      border-radius: 100%;
      display: none;

      @include media-breakpoint-up(md) {
        display: block;

        & + .hero-slider__badge-wrap {
          display: none;
        }
      }
    }

    time,
    span {
      text-align: center;
      line-height: 1.3;
      display: block;
    }

    time,
    &-text {
      font-size: 1em;
      padding: 8px 0;

      @include media-breakpoint-up(md) {
        font-family: $font-family-header;
        padding: 0 35px;
      }
    }

    time {
      font-weight: bold;

      @include media-breakpoint-up(md) {
        font-weight: normal;
      }
    }

    &-title {
      font-size: 1em;
      position: relative;

      @include media-breakpoint-up(md) {
        font-size: 0.6em;
        margin-bottom: 32px;

        &:after {
          content: '';
          width: 150px;
          height: 1px;
          border-bottom: 2px dotted var(--badge-text);
          position: absolute;
          bottom: -20px;
          left: 50%;
          margin-left: -75px;
        }
      }
    }

    &:hover {
      color: var(--badge-text);
    }
  }

  &__item {
    @include css-grid();

    &-content {
      @include css-grid('layer-1');
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;

      &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: var(--hero-overlayColor);
        opacity: var(--hero-overlayOpacity);
        z-index: 0;
      }

      & > .container {
        @include media-breakpoint-only(xs) {
          width: 100%;
        }
      }

      @include media-breakpoint-up(ultrawide) {
        width: 1160px;
        margin: 0 auto;
      }

      &--cta {
        transform: translateY(-16px);

        @include media-breakpoint-up(md) {
          width: percentage(math.div(8, 12));
          transform: translateY(-32px);
        }

        @include media-breakpoint-up(lg) {
          width: percentage(math.div(6, 12));
        }

        @include media-breakpoint-up(ultrawide) {
          transform: translateY(-48px);
          width: percentage(math.div(8, 12));
        }

        h1,
        h2 {
          font-size: 26px;

          @include media-breakpoint-up(md) {
            font-size: 30px;
          }

          @include media-breakpoint-up(xl) {
            font-size: 44px;
          }

          @include media-breakpoint-up(ultrawide) {
            font-size: 46px;
          }
        }

        a {
          @include anchor-style;
        }
      }
    }

    figure {
      @include css-grid('layer-2');

      picture {
        display: block;
      }

      img {
        width: 100%;
        max-width: 100%;
        height: auto;
      }
    }
  }

  .flex-direction-nav {
    display: none;
    list-style: none;
    margin: 0;

    @include media-breakpoint-up(md) {
      display: block;
    }

    .flex {
      &-prev,
      &-next {
        position: absolute;
        top: 50%;
        height: 100px;
        margin: -50px 0 0 0;
        width: 50px;
        opacity: 0;
        transition: all 0.2s ease-in-out;

        &:before,
        &:after {
          content: '';
          position: absolute;
          height: 50px;
          width: 2px;
          background: __get($colors, background);
          border-radius: 3px;
        }
      }

      &-prev {
        left: $grid-gutter-width;
        transform: translateX(calc((100% + #{$grid-gutter-width}) * -1));

        &:before {
          top: 3px;
          left: 12px;
          transform: rotate(25deg);
        }

        &:after {
          bottom: 3px;
          left: 12px;
          transform: rotate(-25deg);
        }
      }

      &-next {
        right: $grid-gutter-width;
        transform: translateX(calc(100% + #{$grid-gutter-width}));

        &:before {
          top: 3px;
          right: 12px;
          transform: rotate(-25deg);
        }

        &:after {
          bottom: 3px;
          right: 12px;
          transform: rotate(25deg);
        }
      }
    }
  }

  &:hover {
    .flex-direction-nav .flex-prev {
      transform: translateX(0);
      opacity: 1;
    }

    .flex-direction-nav .flex-next {
      transform: translateX(0);
      opacity: 1;
    }
  }
}

@include ie11-only-wrap-shizzle(('.hero-slider__item figure', '.hero-slider__item-content')) {
  width: calc(100vw);
  max-width: 1900px;
}
