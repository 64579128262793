.tmpl-Event,
.tmpl-EventList {
  .infobar-quicklinks {
    float: none;
    width: auto;
    padding: 0;

    & > div {
      font-family: $font-family-bold;
      padding: ($grid-gutter-width * 0.25) 0;

      &:first-of-type {
        margin-left: 0;
      }

      a {
        color: __get($colors, 'red');
        font-family: inherit;
        position: relative;

        @include arrows;
      }
    }
  }
}
[class^='tmpl-Event'] aside:not(.col-sidebar) .infobar-quicklinks > div {
  @media (min-width: 992px) {
    display: inline-block;
    margin-left: $grid-gutter-width;
    padding: 0;
  }
}
.tmpl-Event .col-sidebar .infobar-quicklinks-title {
  font-weight: bold;
}

.tmpl-EventList {
  .main .frame-type-text {
    @include make-row;
    margin-bottom: $grid-gutter-width;

    header,
    p {
      @extend .col-xs-12, .col-md-10, .col-lg-8;
    }

    header {
      h2 {
        margin-bottom: $grid-gutter-width;
      }
    }

    p:last-of-type {
      margin-bottom: 0;
    }
  }

  .frame-type-events_list,
  .frame-type-events_past {
    @include make-row;
    position: relative;
    margin-bottom: $grid-gutter-width;
    display: flex;
    flex-wrap: wrap;

    .csc-header {
      @extend .col-xs-12;
    }

    .item {
      padding-top: $grid-gutter-width * 0.5;
      padding-bottom: $grid-gutter-width * 0.5 + 19;
      @media (min-width: 992px) {
        padding-bottom: $grid-gutter-width * 3 - 21;
      }
      display: flex;
      flex-direction: column;
      height: 100%;

      figure {
        position: relative;

        picture {
          img {
            width: 100%;
            height: auto;
          }
        }

        figcaption {
          position: absolute;
          bottom: 0;
          left: 0;
          width: auto;
          height: 82px;
          background: __get($colors, 'white');
          padding-top: 18px;
          padding-right: 18px;
          img {
            height: 100%;
            width: auto;
            object-fit: contain;
            object-position: left bottom;
          }
          @media screen and (min-width: 769px) {
            width: 60%;
            img {
              width: 100%;
            }
          }
        }
      }

      & > div {
        flex-grow: 1;
        display: flex;
        padding-top: $grid-gutter-width;

        & > time {
          flex: 0 0 74px;
          display: flex;
          flex-direction: column;
          align-items: center;
          font-family: $font-family-header-bold;
          font-weight: normal;
          font-size: 22px;
          line-height: 1.3;
          padding-top: 5px;
          @media (min-width: 992px) {
            flex-basis: 80px;
          }

          span {
            &:first-child {
              font-family: $font-family-header;
              font-size: 14px;
            }
          }
        }

        & > div {
          h2 {
            margin: 0 0 $grid-gutter-width * 0.5 0;
            color: __get($colors, 'black');
            font-family: $font-family-header;
            font-weight: normal;
          }

          time {
            &:after {
              content: '|';
              padding: 0 $grid-gutter-width * 0.25;
            }
          }

          address {
            display: inline-block;
            margin: 0;
          }
        }
      }
    }
  }

  .frame-type-events_past .item > div {
    background: __get($colors, 'white');
    padding-bottom: $grid-gutter-width;
  }

  .tx-parkside-contact {
    margin-bottom: $grid-gutter-width;

    @media (min-width: 992px) {
      display: flex;
      justify-content: space-between;

      .single-contact {
        flex-basis: calc(50% - #{$grid-gutter-width * 0.5});
      }
    }

    .position {
      word-break: break-word;
      hyphens: auto;
    }
  }

  .main {
    .bs-content {
      background: rgba (__get($colors, very-light-grey), 0.5);
      width: 100%;
      margin: $grid-gutter-width 0 0 0;
      padding: $grid-gutter-width 0 !important;

      @media (min-width: 992px) {
        margin: 100px 0 0 0;
        padding: 100px 0 !important;
      }

      .content-container {
        @extend .container;

        .frame-type-events_list,
        .frame-type-events_past {
          .item {
            // align event logo for when list plugin is placed in grey background content area
            figure figcaption {
              padding-right: 10px;
              padding-left: 8px;
            }
            // add space for title/date/location when list plugin is placed in grey background content area
            time + div {
              padding-right: $grid-gutter-width * 0.5;
            }
          }
        }
      }
    }

    // Disable horizontal line under page's H1.
    h1 + hr {
      opacity: 0;
    }
  }

  .recent-events {
    @include anchor-style;
    margin: $grid-gutter-width auto;
    position: relative;

    @media (min-width: 992px) {
      margin: 0;
      position: absolute;
      right: $grid-gutter-width * 0.5;
      top: $grid-gutter-width * 0.5;
    }

    font-family: $font-family-bold;

    @include arrows;
  }
}

// Event detail pages
.tmpl-Event {
  .header {
    h1 {
      margin: 0 0 32px;
      @media (min-width: 769px) {
        margin: 0 0 25px;
      }
    }

    p {
      color: __get($colors, 'red');
      font-size: 18px;
      margin: 0 0 30px;
    }

    // hide h1 subheader on small devices
    h1 + p {
      display: none;
      @media (min-width: 769px) {
        display: block;
      }
    }

    .header-image {
      margin: 0 -30px;
      position: relative;

      @media (max-width: 768px) {
        margin: 0;
      }

      img.sujet {
        width: 100%;
        height: auto;
      }

      .logo {
        position: absolute;
        left: 0;
        bottom: 0;
        max-width: 50%;
        max-height: 50%;
        height: auto;
        width: auto;
        display: none;

        @media (max-width: 768px) {
          display: block !important;
          background-color: white;
          padding-top: 15px;
          padding-right: 15px;
        }
      }
    }
  }

  .col-main.empty {
    padding-top: 0;
    padding-bottom: 0;
    margin-bottom: 0;
    margin-top: 0;
  }

  .col-sidebar {
    .event-addressblock + .event-contactblock {
      padding-top: 21px;
    }
    .event-contactblock + * {
      padding-top: 14px;
    }
    > :last-child {
      margin-bottom: $grid-gutter-width * 2;
    }
  }

  .metadata-container {
    padding: 44px;
    @media (min-width: 1200px) {
      padding: 56px 44px 44px;
    }
    background: #f0f0f0;
    &.is-past {
      padding-top: 41px;
      time.date,
      .event-name-place {
        opacity: 0.5;
      }
    }

    .event-logo {
      img {
        width: 100%;
        height: auto;
        background: white;
        padding: 15px;
      }
    }

    &.aside {
      margin: -170px 0 50px;
      &.no-event-detailimage {
        margin-top: 0;
      }

      @media (max-width: 768px) {
        display: none !important;
      }
    }

    &.top {
      display: none;
      margin-bottom: 15px;
      @media (max-width: 768px) {
        padding-top: 34px;
      }
      padding-top: 8px;

      time,
      .event-name-place {
        text-align: center;
      }

      @media (max-width: 768px) {
        display: block !important;
      }

      .btn-download.btn-block {
        // overwrite btn-block on small devices
        width: auto !important;
      }
      @media (max-width: 768px) {
        .logo {
          background: inherit;
          padding: 18px 18px 0 0;
        }
      }
      .event-is-past-message {
        text-align: center;
      }
    }

    .btn-group-center {
      text-align: center;
    }

    time {
      display: block;
      padding: 20px 0 24px;
      font-family: $font-family-header-bold;
      font-size: 32px;
      &:first-child {
        padding-top: 0;
      }
    }

    .event-is-past-message {
      color: __get($colors, 'red');
      font-size: 17px;
      padding-bottom: 15px;
      & + .logo {
        // raise space if past event has an event logo
        padding-top: 23px;
      }
    }

    .event-name-place {
      font-size: 18px;
      color: #333;
      margin-bottom: 27px;
      // remove mb if no iCal dl-link is available
      &:last-child {
        margin-bottom: 0;
      }

      .event-name {
        font-family: $font-family-bold;
        font-weight: bold;
      }
      .event-address-booth-pipe {
        display: none;
      }
      .event-address-booth {
        display: block;
        padding-top: 6px;
      }
    }
  }

  .event-addressblock {
    &:before {
      content: attr(data-label);
      font-size: 18px;
      padding-bottom: 10px;
      display: block;
      font-weight: bold;
    }
  }

  .event-contactblock {
    &:before {
      content: attr(data-label);
      font-size: 18px;
      padding-bottom: 10px;
      display: block;
      font-weight: bold;
    }

    margin-bottom: 22px;
  }

  .btn.btn-primary.btn-download {
    display: inline-block;
    background: transparent;
    border: 2px solid $brand-color;
    padding: 10px 15px;
    text-align: center;
    color: $brand-color;
    border-radius: 0;
  }

  .presentation-element {
    .btn-group-right {
      float: right;
    }

    .data-extra {
      display: none;
    }

    h2,
    h3,
    h4,
    h5 {
      color: #333;
      font-size: 27px;
      font-family: $font-family-header;
      margin: 0 160px 15px 0;
      font-weight: 400;
      line-height: 1.3;
      @media (min-width: 1200px) {
        margin-bottom: 29px;
      }
    }

    time {
      font-family: $font-family-bold;
    }

    .presentation-location {
      margin: 0 0 15px;
    }

    .presentation-people {
      @media (min-width: 768px) {
        float: right;
        width: 45%;
      }

      img {
        width: 100%;
        height: auto;
      }

      p {
        color: #a6a6a6;
        margin: 5px 0 15px 0;
      }
    }

    .presentation-text {
      @media (max-width: 768px) {
        margin-bottom: 15px;
      }

      @media (min-width: 769px) {
        margin-right: 50%;
      }
    }

    .presentation-details {
      &:before,
      &:after {
        content: ' ';
        display: table;
      }

      &:after {
        clear: both;
      }
    }

    .presentation-expander {
      @include arrows();
      color: $brand-color;
      padding-left: 0;

      &:before {
        display: none;
      }

      &:after {
        top: auto;
        left: auto;
        transform: scale(0.7) rotate(90deg);
        margin-top: 9px;
        margin-left: 4px;
      }

      &.reduce:after {
        margin-top: 2px;
        transform: scale(0.7) rotate(-90deg);
      }
    }
    .ce-div:last-child {
      display: none;
    }
    &:last-of-type {
      .ce-div:last-child {
        display: block;
      }
    }

    // reduce space between CE header and CE tx_events_contribution
    @at-root .frame-layout-4201 + .presentation-element hr.ce-div {
      margin-top: 38px;
    }
  }
}
