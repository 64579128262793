body {
  // Regular headers are introduced step by step
  // due to the massive conceptional problems (which was present when we took over this website)
  &.tmpl-Event .col-main {
    h3 {
      margin: 0 0 11px;
      font-weight: 400 !important;
      line-height: 1.3;
      font-family: $font-family-header;
      font-size: 24px;
      color: #333;
    }

    h4 {
      font-size: 20px;
      line-height: 1.42857143;
      font-family: $font-family-header;
      color: #333;
    }

    h5 {
      font-size: inherit;
      line-height: 1.42857143;
      font-family: $font-family-header;
      color: #333;
    }
  }
}

// Custom tt_content.layout for small headers before a group of CE tx_events_contribution (value 4201)
.frame-layout-4201 {
  h1,
  h2,
  h3,
  h4,
  h5 {
    font-family: inherit !important;
    font-size: 17px !important;
    line-height: 1.42857143 !important;
    font-weight: bold !important;
    color: inherit !important;
    padding-top: 44px;
  }
}

.color-primary {
  color: __get($colors, 'red');
}
