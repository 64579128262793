@use 'sass:math';

body.show-mobile {
  overflow: hidden;

  @include media-breakpoint-up(xl) {
    overflow: auto;
  }
}

#outside-burger {
  z-index: 1;
  display: flex;
  align-items: center;

  ul {
    margin: 0 15px 0 0;
    padding: 0;
    list-style: none;
    display: flex;

    li {
      &.contactpage {
        display: none;
        margin-right: 15px;

        @include media-breakpoint-up(lg) {
          display: flex;
        }

        a {
          flex: 1 0 auto;
          display: inline-block;
          width: 30px;
          background: url(../Images/mail.svg) no-repeat center;
        }
      }
    }
  }

  .search {
    margin-right: 15px;
  }

  @include media-breakpoint-up(xl) {
    display: none;
  }
}

#mainnavigation {
  font-size: 20px;
  position: fixed;
  top: 55px;
  background: __get($colors, 'background');
  left: 0;
  right: 0;
  overflow-y: scroll;
  max-height: 100%;
  transform: translateY(calc(-100% - 55px));
  transition: all 0.2s ease-in-out;
  box-shadow: 0 2px 4px rgba(34, 34, 34, 0.1);
  height: calc(100% - 55px);

  body.small-logo & {
    padding-bottom: 0;
  }

  @include media-breakpoint-up(xl) {
    font-size: 16px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: sticky;
    top: 15px;
    left: auto;
    right: auto;
    padding-bottom: 30px;
    flex: 1 0 auto;
    overflow: unset;
    transform: unset;
    box-shadow: none;
    height: auto;
  }

  @include media-breakpoint-up(xl) {
    font-size: 18px;
  }

  // first level
  & > ul {
    position: relative;
    display: block;
    top: 0;
    list-style: none;
    padding: 0;
    @extend .container;
    margin: 15px auto;

    @include media-breakpoint-up(lg) {
      top: 55px;
    }

    @include media-breakpoint-up(xl) {
      position: sticky;
      top: 15px;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      margin: 0 (-$grid-gutter-width * 0.5);
      width: auto;
      min-height: 55px; // Harmonize height with microsites fancySelect height.
    }

    li li.active > a {
      color: __get($colors, 'brand');
    }

    // all anchors
    a {
      color: __get($colors, 'dark-grey');
      line-height: 1.3;

      &:hover {
        color: __get($colors, 'brand');
      }
    }

    // dropdown holder for sub nav
    li > div:not(.branches__mobile):not(.branches__select) {
      display: none;
      flex: 1 0 100%;

      @include media-breakpoint-up(xl) {
        position: absolute;
        z-index: 1;
      }
    }

    // first level list elements

    & > li.search {
      display: none;

      @include media-breakpoint-up(xl) {
        display: list-item;
        z-index: 16;
        position: relative;

        &:before {
          content: '';
          position: absolute;
          bottom: 4px;
          left: 5px;
          right: 5px;
          height: 0;
          background: __get($colors, 'brand');
        }

        &:focus-within {
          &:before {
            height: 2px;
          }
        }

        &:hover {
          &:before {
            height: 1px;
          }
        }
      }
    }

    & > li:not(.search) {
      &:before {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        height: 1px;
        background: rgba(__get($colors, 'grey'), 0.3);

        @include media-breakpoint-up(xl) {
          display: none;
        }
      }

      &:not(.branches) {
        font-size: 1em;
        padding: $grid-gutter-width * 0.5;
        position: relative;
        display: flex;
        flex-wrap: wrap;
      }

      &:nth-last-child(2):before {
        display: none;
      }

      a,
      & > span {
        flex: 1 0 auto;
      }

      // subnavigation toggle button
      button {
        flex: 0 auto;
        width: 44px;
        height: 44px;
        position: relative;
        border: none;
        background: none;
        margin: -10px 0;
        transform-origin: center;

        em {
          position: absolute;
          top: 50%;
          left: 50%;
          width: 15px;
          height: 15px;
          background: red;
          transform: translate(-50%, -50%) rotate(-45deg);
          border: 4px solid __get($colors, 'black');
          border-top: none;
          border-left: none;
          background: none;
          transition: all 0.2s ease-in-out;
        }
      }

      &.language {
        display: none;
      }

      @include media-breakpoint-down(lg) {
        &.toggle {
          div:not(.branches__mobile):not(.branches__select) {
            display: block;
          }

          button em {
            transform: translate(-50%, -50%) rotate(45deg);
          }

          &:after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: rgba(__get($colors, 'grey'), 0.05);
            z-index: -1;
          }
        }
      }

      @include media-breakpoint-up(xl) {
        &:not(.branches) {
          padding: 0 math.div($grid-gutter-width, 3);
          display: block;
        }

        > a {
          position: relative;

          &:before {
            content: '';
            position: absolute;
            bottom: -5px;
            left: -5px;
            right: -5px;
            height: 0;
            background: __get($colors, 'brand');
          }
        }

        &.active {
          > a {
            color: __get($colors, 'brand');

            &:before {
              height: 1px;
            }
          }
        }

        > a:focus:not(.search) {
          color: __get($colors, 'brand');
          text-decoration: none;
          &:before {
            height: 2px;
          }
        }

        > a:hover:not(.search) {
          text-decoration: none;
          &:before {
            height: 1px;
            z-index: 2;
          }
        }

        &.contactpage {
          margin-left: $grid-gutter-width;
          display: flex;

          a {
            display: inline-block;
            width: 30px;
            height: 26px;
            background: url(../Images/mail.svg) no-repeat center;
          }
        }

        &:hover:not(.active):not(.branches) {
          div {
            display: block;
            margin: 0 math.div($grid-gutter-width, -2);
            background: __get($colors, background);
          }
        }

        &.language {
          display: flex;
        }
      }
    }

    // second level
    ul {
      list-style: none;
      padding: 15px;
      min-width: 250px;
      width: auto;
      transition: all 0.2s ease-in-out;

      @include media-breakpoint-up(xl) {
        padding: 0;
        box-shadow: 0 2px 4px rgba(34, 34, 34, 0.1);
        transform: translateY(67px);
        background: #fff;

        // sticky nav behaviour
        body.sticky & {
          transform: translateY(24px);
        }
      }

      // second level list elements
      li {
        font-size: 0.9em;
        padding: 5px 0;

        @include media-breakpoint-up(xl) {
          padding: $grid-gutter-width * 0.25 $grid-gutter-width * 0.5;
        }

        a {
          line-height: 1.5;

          &:hover,
          &:focus {
            text-decoration: none;
            position: relative;
            color: __get($colors, 'brand');

            &:before {
              content: '';
              position: absolute;
              bottom: -5px;
              left: -5px;
              right: -5px;
              background: __get($colors, 'brand');
            }
          }

          &:hover:before {
            height: 1px;
          }

          &:focus:before {
            height: 2px;
          }
        }
      }
    }
  }

  @include media-breakpoint-down(lg) {
    body.show-mobile & {
      transform: translateY(0);
    }
  }
}

#subnav {
  a {
    &.active {
      color: map-get($colors, 'brand');
    }
    &:hover,
    &:focus {
      color: map-get($colors, 'brand-darker');
      text-decoration: underline;
      text-underline-offset: 4px;
    }
    &:hover {
      text-decoration-thickness: 1px;
    }
    &:focus {
      text-decoration-thickness: 2px;
    }
  }

  @include media-breakpoint-up(xl) {
    display: block;
  }
}

@include ie11-only-wrap-shizzle('header#header') {
  position: relative;
}

@include ie11-only-wrap-shizzle('header#header .logo') {
  position: absolute;
}

@include ie11-only-wrap-shizzle('#mainnavigation') {
  position: relative;
  overflow: visible;
  padding-bottom: 0;
}

@include ie11-only-wrap-shizzle('#mainnavigation > ul') {
  top: 0;
}
