.project-list {
  .load-more-container {
    button {
      &.loadmore {
        color: #af1c1a;
        font-family: $font-family-bold;
        border: none;
        background: none;
        padding: 0;
      }
    }
  }
}

.picture-list-item {
  .piclib-popup-overlay:focus {
    @include outline-style;
  }

  .picturelib-area {
    .download {
      @include anchor-style;
    }
  }
}
