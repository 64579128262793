main .breadcrumbs {
  list-style: none;
  background: none;
  margin: 20px 0 8px 0 !important;
  padding: 0;

  &__item {
    display: inline-block;
    position: relative;
    padding-left: 12px;
    margin-left: 3px;

    &:before {
      display: none;
    }

    &:after {
      font-family: 'ps-icon';
      content: '\e606\00a0';
      color: __get($colors, 'black');
      font-size: 7px;
      position: absolute;
      left: 0;
      top: 6.5px;
    }

    &:first-child {
      margin-left: 0;
      padding-left: 0;

      &:after {
        display: none;
      }
    }

    &-link {
      @include anchor-style;
      font-size: 15px;
      color: #000;
      text-decoration: none;

      &.active {
        color: __get($colors, 'red');
      }

      &:hover,
      &:focus {
        text-decoration: underline;
        color: __get($colors, 'brand-darker');
      }

      &:focus {
        text-decoration-thickness: 2px;
      }
    }
  }
}
