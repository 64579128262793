.frame-type-form_formframework {
  header {
    @extend .mail-order-title;
  }

  input[type='checkbox'] {
    width: auto;
    height: auto;
    margin: 5px 0 5px -20px;
    position: absolute;
  }

  p {
    font-size: 13px;
  }

  input,
  select,
  textara {
    &:focus:invalid {
      border: 1px solid __get($colors, 'red');
    }
  }

  [type='submit'] {
    background-color: __get($colors, 'red');
    color: __get($colors, 'white');
    text-transform: uppercase;
    font-family: $font-family;
    letter-spacing: 1px;
    width: 100%;
    height: 45px;
    border: 0;
    margin: 10px 0;
    border-radius: 5px;
    padding-left: 15px;
    padding-top: 3px;
  }
}

.form-horizontal.form-label-left .control-label {
  text-align: left;
}

.mfp-content {
  @include media-breakpoint-up(md) {
    max-width: 560px;
  }

  @include media-breakpoint-up(lg) {
    max-width: 732px;
  }

  @include media-breakpoint-up(xl) {
    max-width: 888px;
  }

  form {
    background: __get($colors, 'very-light-grey');

    padding: $grid-gutter-width;
  }

  .mwork form {
    background: #eee;
  }

  label {
    font-size: 14px;
    line-height: 1.5;
    font-weight: normal;
  }

  .form {
    &-control {
      color: __get($colors, 'form-text');
      height: auto;
      padding: 12px $grid-gutter-width * 0.5;
      border: 1px solid #ccc;
      box-shadow: none;

      &::placeholder {
        color: __get($colors, 'form-text');
        overflow: visible;
        opacity: 1;
      }
    }
  }

  .select-wrapper {
    position: relative;

    &::after {
      content: '';
      position: absolute;
      top: 50%;
      right: 15px;
      transform: translateY(-50%) rotate(45deg);
      pointer-events: none;
      border: __get($colors, 'red') solid;
      border-width: 0 3px 3px 0;
      display: inline-block;
      padding: 3px;
    }
  }

  select {
    appearance: none;
  }

  .checkbox {
    position: relative;
    input[type='checkbox'] {
      margin-left: -26px;
      width: 18px;
      height: 18px;
      opacity: 0;
      z-index: -1;

      & + span {
        &::after {
          content: '';
          position: absolute;
          left: 0;
          top: 2px;
          border: 1px solid __get($colors, 'dark-grey');
          border-radius: 2px;
          height: 18px;
          width: 18px;
        }
      }

      &:checked {
        color: red;

        & + span {
          &::before {
            content: '';
            position: absolute;
            height: 6px;
            width: 14px;
            border-bottom: 2px solid __get($colors, 'very-light-grey');
            border-left: 2px solid __get($colors, 'very-light-grey');
            top: 6px;
            left: 2px;
            z-index: 2;
            transform: rotate(-45deg);
          }
          &::after {
            background-color: __get($colors, 'dark-grey');
          }
        }
      }
    }

    label {
      padding-left: 26px;
    }
  }

  .actions {
    margin-top: $grid-gutter-width * 1.5;
  }
}
