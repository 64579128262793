.expnum-link {
  margin-bottom: 0;
}

.hero-shader-custom {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.pos-verticalposition-middle {
  transform: translateY(50%);
}

.pos-verticalposition-bottom {
  transform: translateY(100%);
}

.main > .microsite-bottom {
  width: 100% !important;
  max-width: 100% !important;
  margin: 0 !important;
  padding: 0 !important;
}

.frc-default .col-sidebar .csc-header {
  h4,
  a {
    color: #333333;
  }
}
