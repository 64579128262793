.scrollToTopBtn {
  background: __get($colors, 'red');
  border: none;
  box-shadow: 0 0 0.25rem rgba(255, 255, 255, 1);
  border-radius: 50%;
  color: white;
  cursor: pointer;
  width: 40px;
  height: 40px;
  position: fixed;
  bottom: 100px;
  right: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.75s ease-in-out;
  opacity: 0;
  visibility: hidden;
  z-index: 5000;
  svg {
    transform: translateY(-2px);
  }
  &.show {
    visibility: visible;
    opacity: 1;
  }

  @include media-breakpoint-up(lg) {
    width: 54px;
    height: 54px;

    svg {
      width: 20px;
      height: auto;
      transform: translateY(0);
    }
  }
}
