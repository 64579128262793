.select {
  ul {
    margin-left: 0 !important;
    padding-left: 24px;

    li {
      padding: 8px 0;
      cursor: pointer;

      &:hover {
        background: map-get($colors, 'very-light-grey');
      }

      &:before {
        content: unset !important;
      }
    }
  }
}
