.frame-type-parksideportfolio_projektdetail {
  width: auto;
  padding: 0;
  margin: 0;

  #content .main > & {
    @include media-breakpoint-down('md') {
      padding-right: 0 !important;
    }
  }

  .project-detail {
    padding-top: 0;
  }

  .project-back-button {
    a {
      color: __get($colors, 'dark-grey');
    }
  }

  //.flex-viewport {
  //  img {
  //    height: auto;
  //    position: absolute;
  //  }
  //
  //  li:before {
  //    display: none;
  //  }
  //}

  .col-sidebar {
    ul {
      margin: 0;

      li {
        &:before {
          display: none;
        }
      }
    }
  }

  .project-image {
    position: relative;

    .project-status-7,
    .project-status-2 {
      width: 120px;
      height: 120px;
      text-align: center;
      position: absolute;
      right: 0;
      bottom: 0;
      border-style: solid;
      border-width: 0 0 120px 120px;
      border-color: transparent transparent #af1c1a transparent;
    }

    .project-status-7 {
      span {
        &:before {
          background-image: url('../Images/developed-by-ca-immo.svg');
          background-size: 60px 30px;
          display: inline-block;
          width: 60px;
          height: 30px;
          content: '';
          position: absolute;
          top: 78px;
          left: -70px;
        }
      }
    }
    .project-status-2 {
      span {
        font-size: 40px;
        line-height: 167px;
        color: #fff;
        margin-left: -64px;

        &:before {
          content: '\e600';
        }
      }
    }

    @include media-breakpoint-only(xs) {
      .project-status-7,
      .project-status-2 {
        width: 80px;
        height: 80px;
        border-width: 0 0 80px 80px;
      }
      .project-status-7 {
        span {
          &:before {
            background-size: 40px 20px;
            width: 40px;
            height: 20px;
            top: 50px;
            left: -48px;
          }
        }
      }
      .project-status-2 {
        span {
          font-size: 28px;
          line-height: 110px;
          margin-left: -42px;
        }
      }
    }
  }
}

.project-footer {
  width: 100% !important;
  margin-bottom: 0 !important;
}

.tmpl-Default .frc-tiles .project-list.row .projects {
  margin-left: -10px;
}

.tx_supi__map {
  &-toggle-wrapper {
    background: __get($colors, 'very-light-grey');
    padding: $grid-gutter-width * 2 0;
    text-align: center;

    .container {
      padding-top: 0;
    }
  }

  &-trademark {
    margin-bottom: $grid-gutter-width;
  }
}
