$supi-position: () !default;
$supi-position: map-merge(
  (
    bottom: 15px,
    left: 15px,
  ),
  $supi-position
);

$supi-colors: () !default;
$supi-colors: map-merge(
  (
    background: #fff,
    primary: #000,
    secondary: #ccc,
    button-bar: #efefef,
    success: #198754,
    warning: red,
    notice: #000,
    underline: #6c757d,

    button-wrap-bgcolor: #efefef,
    cookie-table-bgcolor: #f8f9fa,
  ),
  $supi-colors
);

$supi-grid-gutter-width: 30px !default;
$supi-breakpoint: 800px !default;
$supi-border: true !default;
$supi-box-shadow: true !default;
$supi-font-base-size: 16px !default;
$supi-font-size: 1em !default;
$supi-font-size-head: 1.5em !default;

$supi-banner-box-padding: $supi-grid-gutter-width * 0.5 !default;
$supi-banner-box-margin: $supi-grid-gutter-width * 0.5 !default;
$supi-banner-box-border: 1px solid map-get($supi-colors, primary) !default;
$supi-banner-box-shadow: 0 0 10px rgba(map-get($supi-colors, primary), 0.8) !default;

$supi-button-wrap-padding: $supi-grid-gutter-width * 0.5 !default;

$supi-breakpoints: (
  xs: 0,
  md: $supi-breakpoint,
) !default;

$supi-spacers: () !default;
$supi-spacers: map-merge(
  (
    hg: $supi-grid-gutter-width * 0.5,
    fg: $supi-grid-gutter-width,
  ),
  $supi-spacers
);
