.logo {
  background: url(../Images/logo_CA_IMMO.svg) no-repeat center;
  background-size: contain;
  position: relative;
  width: 147px;
  height: 55px;
  z-index: 1;
  overflow: hidden;
  transform: scale(1.001);

  &:focus {
    @include outline-style;
  }

  @include media-breakpoint-up(lg) {
    background-image: url(../Images/logo_CA_IMMO_Urban_Benchmarks.svg);
    width: 214px;
    height: 80px;
    transition: transform 0.2s ease-in-out;

    body.small-logo & {
      transform: translateY(35px) scale(1.001);
      height: 40px;
      background-size: 100% auto;
      background-position: top center;
    }
  }

  body.small-logo & {
    background-image: url(../Images/logo_CA_IMMO.svg);
    height: 84px;
  }
}

@include ie11-only-wrap-shizzle('.logo') {
  width: 200px;
  height: 60px;
}

@include ie11-only-wrap-shizzle('.logo span[aria-hidden="true"]') {
  background-size: 150px 50px;
}
