@import '00-FontVariables';

$aspect-ratios: (
  16by9: 16 9,
  6by4: 6 4,
  4by3: 4 3,
  4by1: 4 1,
  3by4: 3 4,
  3by1: 3 1,
  2by1: 2 1,
  1by1: 1 1,
);

$grid-breakpoints: (
  xs: 0,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  ultrawide: 1920px,
);

$brand-color: #af1c1a;

$colors: (
  background: #fff,
  primary: #a0a0a0,
  secondary: #ccc,
  button-bar: #efefef,
  notice: #000,
  'white': #ffffff,
  'grey': #808080,
  dark-grey: #333333,
  not-so-dark-grey: #c9c9c9,
  light-grey: #a0a0a0,
  very-light-grey: #f2f2f2,
  'black': #000000,
  success: $brand-color,
  warning: #a0a0a0,
  'red': $brand-color,
  'brand': $brand-color,
  'brand-darker': #64100f,
  dark-grey-hover: #707070,
  border-color: #eee,
  form-text: #757575,
);

$position: (
  bottom: 15px,
  left: 15px,
);

$border: true;
$box-shadow: true;
$font-base-size: 16px;
$font-size: 1em;
$font-size-head: 1.5em;

$banner-box-padding: 15px;
$banner-box-border: 1px solid __get($colors, primary);
$banner-box-shadow: 0 0 10px rgba(__get($colors, primary), 0.8);
$suggest-shadow: 0 5px 5px rgba(__get($colors, primary), 0.5);

$grid-gutter-width: 30px;

$spacers: (
  0: 0,
  qg: $grid-gutter-width * 0.25,
  hg: $grid-gutter-width * 0.5,
  qp: $grid-gutter-width * 0.75,
  fg: $grid-gutter-width,
  1h: $grid-gutter-width * 1.5,
  dg: $grid-gutter-width * 2,
  nav-spacer: 10px,
  extra-small: 20px,
  double-extra-small: 40px,
  small: 50px,
  medium: 100px,
  story: 300px,
);
