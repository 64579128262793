.d-flex {
  display: flex !important;
}

@each $direction in (row, column) {
  .flex-#{$direction} {
    flex-direction: $direction;
  }

  @each $bp, $width in $grid-breakpoints {
    @include media-breakpoint-up($bp) {
      .flex-#{$bp}-#{$direction} {
        flex-direction: $direction !important;
      }
    }
  }
}

@each $justify in (center, end, start) {
  .justify-content-#{$justify} {
    justify-content: $justify;
  }

  @each $bp, $width in $grid-breakpoints {
    @include media-breakpoint-up($bp) {
      .justify-content-#{$bp}-#{$justify} {
        justify-content: $justify !important;
      }
    }
  }
}

@each $align in (center, end, start) {
  .align-items-#{$align} {
    align-items: $align;
  }

  @each $bp, $width in $grid-breakpoints {
    @include media-breakpoint-up($bp) {
      .align-items-#{$bp}-#{$align} {
        align-items: $align !important;
      }
    }
  }
}

@each $wrap in (wrap, nowrap, wrap-reverse) {
  .flex-#{$wrap} {
    flex-wrap: $wrap;
  }
}

@for $i from 0 through 4 {
  .order-#{$i} {
    order: $i;
  }

  @each $bp, $width in $grid-breakpoints {
    @include media-breakpoint-up($bp) {
      .order-#{$bp}-#{$i} {
        order: $i !important;
      }
    }
  }
}
