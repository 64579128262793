.icon {
  width: 40px;
  height: 40px;
  display: inline-flex;
  justify-content: center;

  &-project-status {
    font-family: ps-icon;
    font-size: 28px;
    color: #000;

    &-1 {
    }

    &-2 {
      &:before {
        content: '\e600';
      }
    }
  }
}
