.teasertext {
  margin: 100px 0;
  text-align: center;

  &__inner {
    flex-direction: column;
    align-items: center;
  }

  &__heading {
    margin: 0;

    & + .teasertext__body {
      font-size: 16px !important;
      margin-top: 20px;

      & > * {
        max-width: 80ch;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}
.ce-teasertext-newsletter {
  margin: 0 0;
  padding-top: 100px;
  padding-bottom: 100px;
}
