#mainnavigation {
  .branches {
    position: relative;

    &__mobile {
      display: flex;
      flex-direction: column;

      div {
        display: none;
        flex: 1 0 100%;
        padding: 15px;
        margin-top: -15px;
      }

      .branches__button {
        width: 100%;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        font-size: 1em;
        padding: 15px;
        position: relative;
        margin: 0;
        height: auto;
      }

      .branches__arrow-container {
        flex: 0 auto;
        width: 44px;
        height: 44px;
        position: relative;
        border: none;
        background: none;
        margin: -8px 0;
        transform-origin: center;
      }

      @include media-breakpoint-up(lg) {
        display: none;
      }
    }

    &__select {
      display: none;
      width: 100%;
      font-size: 16px;

      @include media-breakpoint-up(lg) {
        display: block;
      }
    }

    &__label {
      cursor: pointer;
      font-weight: 400 !important;
      display: block;
      height: 50px;
      position: relative;
      font-size: 16px;
      line-height: 50px;
      padding: 0 0 0 24px;
      min-width: 260px;
    }

    .select,
    &__label {
      background: __get($colors, 'very-light-grey');
      border-radius: 5px;
    }

    .select {
      border-radius: 0 0 5px 5px;
    }

    .default,
    .selected,
    i {
      position: relative;
      z-index: 15;
    }

    select {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: none;
    }

    div.select {
      z-index: 1000;
      background-color: __get($colors, 'white');
      display: none;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      margin-top: 47px;
      padding: 0 0 19px;
      font-weight: 400;
      font-size: 16px;
      line-height: 1.2;
      font-family:
        Open Sans,
        sans-serif;
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
      overflow-x: hidden;

      & > ul {
        overflow: auto;
        max-height: 400px;
        transform: unset;
        box-shadow: none;
      }

      .optgroup > span,
      li:not(.optgroup) {
        display: flex;
        justify-content: space-between;
        padding: 12px 24px;
        align-items: center;
        user-select: none;
        cursor: pointer;
        list-style: none;

        &:hover {
          background: fade-out(__get($colors, 'dark-grey'), 0.8);
        }
      }
    }

    .open div.select {
      display: block;
    }

    i {
      float: right;
      vertical-align: top;
      line-height: 50px;
      font-size: 14px;
      font-weight: bolder;
      margin: 0 20px 0 0;
    }
  }
}
