@use 'sass:math';

.content-teaser {
  width: auto;
  margin: 0;
  padding: 0;
  display: flex;
  flex-wrap: wrap;

  row-gap: $grid-gutter-width * 0.5;

  @include media-breakpoint-up(xl) {
    row-gap: 0;
  }

  &__item {
    position: relative;
    color: map-get($colors, 'dark-grey');
    font-size: 16px;
    display: grid;
    grid-template-rows: 1fr;
    grid-template-columns: 1fr;

    h2 {
      font-family: $font-family-header;
      font-weight: normal;
      font-size: 1.75em;
      color: inherit;
      line-height: 1.3;
    }

    span {
      font-family: $font-family;
      color: __get($colors, 'brand');
      font-weight: normal;
      font-size: 1em;
    }

    &:not(.content-teaser__item-text) {
      a {
        @include anchor-style;
      }
    }

    &.content-teaser__item-text {
      a {
        &:hover,
        &:focus {
          text-decoration: none;
          color: inherit;
          span {
            text-decoration-thickness: 2px;
          }
        }

        span {
          color: inherit;
        }
      }
    }

    &-image {
      & > div {
        z-index: 1;
        display: flex;
        align-items: flex-end;

        & > div {
          flex: 1 0 100%;
        }
      }
    }

    &-text {
      aspect-ratio: 3/4;
      align-items: center;
      background: map-get($colors, 'brand');
      color: map-get($colors, 'white');

      a {
        &.stretched-link {
          display: flex;
          justify-content: center;
          align-items: center;
          flex-direction: column;

          &:hover {
            color: inherit;
          }
        }
      }

      .bodytext-only a {
        text-decoration: underline;
        text-underline-offset: 4px;

        &:hover,
        &:focus {
          color: inherit;
          text-decoration-thickness: 2px;
        }
      }

      & * {
        color: inherit;
      }
    }

    & > figure,
    & > div,
    & > a {
      grid-row: 1;
      grid-column: 1;
    }

    &-column {
      &-25 {
        flex: 0 0 percentage(math.div(12, 12));
        max-width: percentage(math.div(12, 12));

        @include media-breakpoint-up(md) {
          flex: 0 0 percentage(math.div(6, 12));
          max-width: percentage(math.div(6, 12));
        }

        @include media-breakpoint-up(xl) {
          flex: 0 0 percentage(math.div(3, 12));
          max-width: percentage(math.div(3, 12));
        }
      }

      &-50 {
        flex: 0 0 percentage(math.div(12, 12));
        max-width: percentage(math.div(12, 12));

        @include media-breakpoint-up(lg) {
          .content-teaser__item-overlay {
            flex: 0 0 calc(percentage(math.div(6, 12)) - 8px);
          }
        }

        @include media-breakpoint-up(xl) {
          flex: 0 0 percentage(math.div(6, 12));
          max-width: percentage(math.div(6, 12));
        }
      }

      &-100 {
        flex: 0 0 percentage(math.div(12, 12));
        max-width: percentage(math.div(12, 12));

        @include media-breakpoint-up(lg) {
          .content-teaser__item-overlay {
            flex: 0 0 calc(percentage(math.div(6, 12)) - 12px);
          }
        }
        @include media-breakpoint-up(xl) {
          .content-teaser__item-overlay {
            flex: 0 0 calc(percentage(math.div(3, 12)) - 12px);
          }
        }
      }
    }
  }
}
