/* open-sans-regular - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url('../Fonts/Open_Sans/open-sans-v17-latin-regular.eot'); /* IE9 Compat Modes */
  src:
    local('Open Sans Regular'),
    local('OpenSans-Regular'),
    url('../Fonts/Open_Sans/open-sans-v17-latin-regular.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('../Fonts/Open_Sans/open-sans-v17-latin-regular.woff2') format('woff2'),
    /* Super Modern Browsers */ url('../Fonts/Open_Sans/open-sans-v17-latin-regular.woff') format('woff'),
    /* Modern Browsers */ url('../Fonts/Open_Sans/open-sans-v17-latin-regular.ttf') format('truetype'),
    /* Safari, Android, iOS */ url('../Fonts/Open_Sans/open-sans-v17-latin-regular.svg#OpenSans') format('svg'); /* Legacy iOS */
}

/* open-sans-italic - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: italic;
  font-weight: 400;
  font-display: swap;
  src: url('../Fonts/Open_Sans/open-sans-v17-latin-italic.eot'); /* IE9 Compat Modes */
  src:
    local('Open Sans Italic'),
    local('OpenSans-Italic'),
    url('../Fonts/Open_Sans/open-sans-v17-latin-italic.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('../Fonts/Open_Sans/open-sans-v17-latin-italic.woff2') format('woff2'),
    /* Super Modern Browsers */ url('../Fonts/Open_Sans/open-sans-v17-latin-italic.woff') format('woff'),
    /* Modern Browsers */ url('../Fonts/Open_Sans/open-sans-v17-latin-italic.ttf') format('truetype'),
    /* Safari, Android, iOS */ url('../Fonts/Open_Sans/open-sans-v17-latin-italic.svg#OpenSans') format('svg'); /* Legacy iOS */
}

/* open-sans-700 - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url('../Fonts/Open_Sans/open-sans-v17-latin-700.eot'); /* IE9 Compat Modes */
  src:
    local('Open Sans Bold'),
    local('OpenSans-Bold'),
    url('../Fonts/Open_Sans/open-sans-v17-latin-700.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('../Fonts/Open_Sans/open-sans-v17-latin-700.woff2') format('woff2'),
    /* Super Modern Browsers */ url('../Fonts/Open_Sans/open-sans-v17-latin-700.woff') format('woff'),
    /* Modern Browsers */ url('../Fonts/Open_Sans/open-sans-v17-latin-700.ttf') format('truetype'),
    /* Safari, Android, iOS */ url('../Fonts/Open_Sans/open-sans-v17-latin-700.svg#OpenSans') format('svg'); /* Legacy iOS */
}

/* jomolhari-regular - latin */
@font-face {
  font-family: 'Jomolhari';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url('../Fonts/Jomolhari/jomolhari-v2-latin-regular.eot'); /* IE9 Compat Modes */
  src:
    local('Jomolhari Regular'),
    local('Jomolhari-Regular'),
    url('../Fonts/Jomolhari/jomolhari-v2-latin-regular.eot?#iefix') format('embedded-opentype'),
    /* IE6-IE8 */ url('../Fonts/Jomolhari/jomolhari-v2-latin-regular.woff2') format('woff2'),
    /* Super Modern Browsers */ url('../Fonts/Jomolhari/jomolhari-v2-latin-regular.woff') format('woff'),
    /* Modern Browsers */ url('../Fonts/Jomolhari/jomolhari-v2-latin-regular.ttf') format('truetype'),
    /* Safari, Android, iOS */ url('../Fonts/Jomolhari/jomolhari-v2-latin-regular.svg#Jomolhari') format('svg'); /* Legacy iOS */
}
