@use 'sass:math';
@use 'sass:map';

.audio {
  display: block;

  &__wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: math.div($grid-gutter-width, 2);
    padding: math.div($grid-gutter-width, 2) math.div($grid-gutter-width, 3);
    background: map.get($colors, very-light-grey);
    margin-bottom: $grid-gutter-width;
    border-radius: math.div($grid-gutter-width, 6);
  }

  &__time {
    white-space: nowrap;
  }

  &__button {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0;
    padding: 0;
    border: none;
    background: none;
    width: $grid-gutter-width * 0.8;
    height: $grid-gutter-width * 0.8;
  }

  &__progress {
    flex-grow: 1;
    appearance: none;
    height: 4px;
    background: map.get($colors, not-so-dark-grey);
    color: map.get($colors, not-so-dark-grey);
    border: none;

    &::-webkit-progress-bar {
      background: map.get($colors, not-so-dark-grey);
      color: map.get($colors, not-so-dark-grey);
    }

    &::-webkit-progress-value {
      background: $brand-color;
      position: relative;
    }

    &::-moz-progress-bar {
      background: $brand-color;
    }
  }

  &__icon {
    &::before {
      display: block;
      content: '';
      width: $grid-gutter-width * 0.8;
      height: $grid-gutter-width * 0.8;
      background-position: center center;
      background-repeat: no-repeat;
      background-size: contain;
    }

    &--play::before {
      background-image: url("data:image/svg+xml,%3Csvg width='16' height='18' viewBox='0 0 16 18' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M14.7913 7.35394C15.3659 7.70757 15.7156 8.33044 15.7156 9.00153C15.7156 9.67263 15.3659 10.2955 14.7913 10.613L3.21915 17.6856C2.62361 18.0834 1.87817 18.0995 1.26977 17.7579C0.661241 17.4163 0.284424 16.7734 0.284424 16.0741V1.92893C0.284424 1.23132 0.661241 0.58795 1.26977 0.246375C1.87817 -0.0947976 2.62361 -0.0807328 3.21915 0.282944L14.7913 7.35394Z' fill='%23333333'/%3E%3C/svg%3E%0A");
    }

    &--pause::before {
      background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath fill='%23333333' d='M15,16H13V8H15M11,16H9V8H11M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2Z' /%3E%3C/svg%3E");
    }

    &--volume-loud::before {
      background-image: url("data:image/svg+xml,%3Csvg width='18' height='16' viewBox='0 0 18 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M13.6522 5.54791C13.3121 5.27182 12.8114 5.32043 12.5355 5.66061C12.2573 5.99976 12.307 6.50006 12.6472 6.77833C13.0202 7.08651 13.2353 7.5299 13.2353 7.99975C13.2353 8.46927 13.0202 8.91365 12.646 9.21971C12.3058 9.49785 12.2562 9.99828 12.5343 10.3374C12.6905 10.5297 12.9188 10.629 13.1484 10.629C13.3253 10.629 13.5041 10.5701 13.651 10.4501C14.3967 9.83946 14.8235 8.94938 14.8235 7.99975C14.8235 7.05012 14.3967 6.16004 13.6522 5.54791ZM15.654 3.10269C15.3159 2.8266 14.8156 2.87418 14.5363 3.21334C14.2582 3.55249 14.3078 4.05279 14.647 4.33106C15.7699 5.25012 16.4118 6.58688 16.4118 7.99975C16.4118 9.41262 15.7685 10.7491 14.6478 11.6692C14.3087 11.9474 14.259 12.4478 14.5372 12.787C14.6944 12.9782 14.9217 13.0775 15.1513 13.0775C15.3292 13.0775 15.507 13.0186 15.6549 12.8976C17.1463 11.6759 18 9.88909 18 7.99975C18 6.11041 17.1463 4.32365 15.654 3.10269ZM9.96618 0.681956C9.58566 0.510327 9.13864 0.578853 8.82695 0.855967L4.36103 4.82328H1.58824C0.711066 4.82328 0 5.53435 0 6.38174V9.52843C0 10.4056 0.711066 11.0869 1.58824 11.0869H4.36235L8.82596 15.0542C9.02316 15.3189 9.27463 15.4115 9.52941 15.4115C9.67626 15.4115 9.82585 15.3807 9.96485 15.3176C10.3467 15.1468 10.5882 14.7696 10.5882 14.3527V1.64681C10.5882 1.23023 10.3467 0.822912 9.96618 0.681956Z' fill='%23333333'/%3E%3C/svg%3E%0A");
    }

    &--volume-silent::before {
      background-image: url("data:image/svg+xml,%3Csvg width='18' height='16' viewBox='0 0 18 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M9.96618 0.681956C9.58566 0.510327 9.13864 0.578853 8.82695 0.855967L4.36103 4.82328H1.58824C0.711066 4.82328 0 5.53435 0 6.38174V9.52843C0 10.4056 0.711066 11.0869 1.58824 11.0869H4.36235L8.82596 15.0542C9.02316 15.3189 9.27463 15.4115 9.52941 15.4115C9.67626 15.4115 9.82585 15.3807 9.96485 15.3176C10.3467 15.1468 10.5882 14.7696 10.5882 14.3527V1.64681C10.5882 1.23023 10.3467 0.822912 9.96618 0.681956Z' fill='%23333333'/%3E%3C/svg%3E%0A");
    }
  }
}
