.hidden-small {
  @media (max-width: 768px) {
    display: none !important;
  }
}

.visible-md {
  display: none;

  @media (min-width: 992px) {
    display: block !important;
  }
}

.hidden-md {
  @media (min-width: 992px) {
    display: none !important;
  }
}

.d-block {
  display: block;
}

// need this to replace .frc-tiles .row and .col-*
// and to keep "normal" bootstrap row and column behaviour
.keep-row {
  @extend .clearfix;
  margin-left: -$grid-gutter-width * 0.5;
  margin-right: -$grid-gutter-width * 0.5;

  > [class*='col-'] {
    padding-left: $grid-gutter-width * 0.5;
    padding-right: $grid-gutter-width * 0.5;
  }
}

.btn-lg-smalltext {
  font-size: 16px;
  padding: 18px 27px;
}

// Spacing Utilities
.mb-0 {
  margin-bottom: 0 !important;
}
.pt-0 {
  padding-top: 0 !important;
}

.box-shadow {
  position: relative;

  &:after {
    content: '';
    position: absolute;
    bottom: -5px;
    left: 0;
    right: 0;
    height: 5px;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.1) 0%, rgba(0, 0, 0, 0) 100%);
    z-index: -1;
  }

  &-top {
    &:after {
      top: 0;
      bottom: auto;
      z-index: 0;
    }
  }
}

.w-100 {
  width: 100% !important;
}

@each $breakpoint, $value in $grid-breakpoints {
  .sr-#{$breakpoint}-only {
    @include media-breakpoint-up($breakpoint) {
      position: absolute !important;
      width: 1px !important;
      height: 1px !important;
      margin: -1px !important;
      padding: 0 !important;
      overflow: hidden !important;
      clip: rect(0, 0, 0, 0) !important;
      border: 0 !important;
    }
  }
}

.divider {
  position: relative;
  padding-bottom: $grid-gutter-width * 0.5;
  margin-bottom: $grid-gutter-width * 0.5;

  &:after {
    content: '';
    width: 150px;
    height: 1px;
    position: absolute;
    bottom: 0;
    left: 0;
    border-bottom: 1px dashed var(--content-teaser-color);

    .layout-0 & {
      left: 50%;
      margin-left: -75px;
    }
  }
}
