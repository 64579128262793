body.tmpl-Story {
  .mobile-main-heading {
    margin-bottom: $grid-gutter-width * 2.5;

    @media (min-width: 769px) {
      display: none;
    }

    h1 {
      line-height: 1.2;
      font-size: 28px;
      margin-bottom: 0;
    }
  }

  .story-img {
    &.position-right {
      @media (min-width: 768px) {
        float: right;
        margin-left: 55px;
        margin-right: 0;
      }
    }
  }

  .story-quote-big .story-quote-wrapper {
    .story-quote-imagewrap {
      float: none;

      @media (min-width: 768px) {
        margin-right: 20px;
        float: left;
      }

      @media (min-width: 992px) {
        margin-right: 0;
      }

      .story-quote-img {
        float: none;
        width: 100%;

        img {
          width: 100%;
        }

        @media (min-width: 768px) {
          float: left;
          width: 100%;
          max-width: 400px;

          img {
            width: 100%;
            max-width: 400px;
          }
        }
      }
    }

    .story-img-desc-wrap {
      float: none;
    }
  }

  .bs-content {
    margin-bottom: $grid-gutter-width * 2;

    p:last-of-type {
      margin-bottom: 0;
    }

    h2 {
      padding: 0;
    }

    h3 {
      font-family: $font-family-bold;
      font-size: 21px;
      color: __get($colors, 'black');
    }

    .csc-header {
      @media (min-width: 1200px) {
        padding-left: 200px;
      }
    }

    .story-text-slider {
      .flex-direction-nav {
        list-style: none;
        margin: 0;

        .flex {
          &-prev {
            left: 0 !important;
          }

          &-next {
            right: 0 !important;
          }

          &-prev,
          &-next {
            position: absolute;
            top: 50%;
            height: 100px;
            margin: -50px 0 0 0;
            width: 50px;
            transition: all 0.2s ease-in-out;
            display: flex;
            align-items: center;
            justify-content: center;

            span {
              position: relative;
              display: flex;
              align-items: center;
              justify-content: center;

              &:before,
              &:after {
                width: 20px;
              }
            }

            &:hover,
            &:focus,
            &:focus-visible {
              text-decoration: none;
            }

            &:focus,
            &:focus-visible {
              outline: 1px dotted $brand-color !important;
            }
          }
        }
      }

      &:hover {
        .flex-direction-nav .flex-prev {
          transform: translateX(0);
        }

        .flex-direction-nav .flex-next {
          transform: translateX(0);
        }
      }
    }

    .story-text-slider .csc-header {
      @media (min-width: 1200px) {
        padding-left: 100px;
      }
    }
  }

  .frame-type-tx_theme_audio .bs-content {
    .csc-header {
      @media (min-width: 1200px) {
        padding-left: 0px;
      }
    }
  }

  .story-imagetext,
  .firstPart,
  .secondPart {
    margin-top: 0;
    margin-bottom: 0;
  }

  .story-author-box,
  .story-share-buttons {
    @media (min-width: 1200px) {
      bottom: $grid-gutter-width * 2;
    }
  }

  .story-author-box {
    margin-bottom: $grid-gutter-width;

    @media (min-width: 1200px) {
      left: calc(8.33333333% + (#{$grid-gutter-width * 0.5}));
      margin-bottom: 0;
    }
  }

  .hero {
    .flex-direction-nav {
      display: none;
    }
  }

  .special-row {
    @media (min-width: 992px) {
      margin-left: -$grid-gutter-width;
      margin-right: -$grid-gutter-width;
    }

    & > [class*='col-'] {
      margin-bottom: $grid-gutter-width;

      @media (min-width: 992px) {
        padding-left: $grid-gutter-width;
        padding-right: $grid-gutter-width;
        margin-bottom: 0;
      }

      img.img-responsive {
        width: 100%;
      }
    }
  }

  .frame-type-parksideportfolio_projektliste {
    padding-bottom: $grid-gutter-width;

    .csc-header {
      padding-bottom: $grid-gutter-width;
    }

    .story-teaser-name {
      color: #333333;
      font-family: $font-family-header-bold;
      font-size: 20px;
      margin: 7px 2px 0;
    }

    .story-teaser-usage,
    .story-teaser-usage {
      font-size: 16px;
      margin: 3px 2px 0;
    }
  }

  .psslider-title h1 {
    color: inherit;
    text-shadow: none;
    background: __get($colors, 'white');
    font-size: 36px;
    line-height: 36px;
    font-family: $font-family-header-bold;
    margin: 0;
    padding: 0;
  }
}

.stories {
  @include make-row;

  display: flex;
  flex-wrap: wrap;

  .stories-item {
    @extend .col-xs-12;
    margin-bottom: $grid-gutter-width * 2;
    padding-bottom: $grid-gutter-width * 2;

    &:after {
      content: '';
      position: absolute;
      left: $grid-gutter-width * 0.5;
      bottom: 0;
      width: calc(100% - #{$grid-gutter-width});
      height: 1px;
      background: __get($colors, not-so-dark-grey);
    }

    @include after-first(1) {
      @extend .col-xs-12, .col-md-6;

      &:after {
        @media (min-width: 992px) {
          display: none;
        }
      }

      .stories-item__wrap {
        h2 {
          margin-top: 0;

          @media (min-width: 992px) {
            margin-top: 22px;
          }
        }

        picture {
          @media (min-width: 992px) {
            margin-top: 25px;
          }
        }
      }
    }
  }

  .stories-item__wrap {
    @include make-row;

    figure {
      margin-bottom: $grid-gutter-width;

      @media (min-width: 992px) {
        margin-bottom: 0;
      }

      figcaption {
        font-family: $font-family-header;
        font-size: 14px;
        line-height: 22px;
        color: __get($colors, light-grey);
        margin-top: $grid-gutter-width * 0.5;
      }

      picture {
        display: block;
        background: __get($colors, very-light-grey);
      }
    }

    h2 {
      a {
        color: __get($colors, 'red');

        &:hover {
          text-decoration: none;
        }
      }
    }
  }

  .stories-item__link {
    @include anchor-style;
  }
}
