body {
  font-family: $font-family;
  font-size: 15px;
}

:root {
  // headings
  @each $heading, $settings in $headings-font-settings {
    --h#{$heading}-size: #{__get($settings, 'xs')};
    @include media-breakpoint-up(xl) {
      --h#{$heading}-size: #{__get($settings, 'xl')};
    }
  }
}

// headings
@each $heading, $settings in $headings-font-settings {
  h#{$heading},
  .h#{$heading} {
    font-family: $font-family-header;
    font-size: var(--h#{$heading}-size);
    color: #{__get($settings, 'color')};
    line-height: #{__get($settings, 'line-height')};
    font-weight: 400;
  }
}

h1.page-h1 {
  margin-top: __get($spacers, 'small');
}

.col-sidebar {
  h2,
  .h2 {
    font-size: var(--h3-size);
  }

  h3,
  .h3 {
    --h3-size: 18px;

    @include media-breakpoint-up(xl) {
      --h3-size: 20px;
    }
  }

  h4,
  .h4 {
    --h4-size: 18px;
  }
}

.hero-slider h1 {
  color: __get($colors, 'dark-grey');
}

.header-nav-title {
  @media (min-width: 992px) {
    font-size: 14.5px;
  }
}

#subnav li {
  @media (min-width: 992px) {
    font-size: 14px;
    font-family: $font-family;
  }
}

.usernavbar {
  .list-inline {
    li {
      a {
        font-family: $font-family;
        font-size: 14px;
        line-height: 25px;
      }
    }
  }
}

.footer-section {
  .nav-stacked {
    a {
      font-family: $font-family;
      font-size: 13px;
    }
  }

  ul {
    a {
      font-family: $font-family;
      font-size: 13px;
    }
  }

  .copyright {
    font-family: $font-family;
    font-size: 12px;

    @media (min-width: 992px) {
      font-size: 14px;
    }
  }
}

.expnum-number-wrapper .expnum-number-big {
  font-size: 110px;
  line-height: 100px;
}

.expnum-unit-wrapper {
  font-family: $font-family-header;
}

.expnum-number-wrapper .expnum-unit-big {
  font-size: 30px;
  line-height: 30px;
}

.project-filter label,
.project-filter input[type='text'] {
  font-size: 16px;
}

.project-data dl dd {
  font-size: 16px;
}

.project-data dl {
  margin-top: revert;
}

.expnum-smalltitle {
  margin-bottom: 7px;
}

.infobar .infobar-news .infobar-news-single .infobar-news-title {
  font-size: 18px;
}

.ce-textpic .csc-header h2 {
  font-size: 23px;
}

.file-downloader .doc-downloader-link,
.fido-link {
  font-weight: 700;
}

.teaser-tile .content {
  font-size: 15px;

  @media (min-width: 992px) {
    font-size: 18px;
  }
}

.feature-slider-holder .slider_info .psslider-title {
  font-size: 20px;

  @media (min-width: 768px) {
    font-size: 44px;
  }
}

.expnum-text {
  font-size: 15px;
}

.tx-parkside-contact {
  .single-contact {
    .col-data {
      a {
        @include anchor-style;
      }

      .name {
        font-size: 16px;
      }

      .position {
        font-size: 15px;
      }
    }
  }
}

body.tmpl-Story {
  .psslider-title h1 {
    font-size: 33px;
  }

  .mobile-main-heading h1 {
    font-size: 26px;
  }

  .frame-type-parksideportfolio_projektliste {
    .story-teaser-name {
      font-size: 18px;
    }

    .story-teaser-usage {
      font-size: 14px;
    }

    .story-teaser-area {
      font-size: 14px;
    }
  }
}

.story-teaser .story-teaser-text {
  font-size: 19px;

  @media (min-width: 768px) {
    font-size: 25px;
  }
}

.story-text-slider {
  font-size: 19px;
}

.news {
  .header h3 {
    font-size: 21px;
  }

  .footer {
    font-size: 15px;
  }
}

.picturelib-description {
  font-size: 14px;
}

.picturelib-area {
  font-size: 15px;
}

.news .page-navigation {
  font-size: 22px;
}

.latest-news .latest-news-title {
  font-size: 23px !important;
}

.latest-news .infobar-news-category {
  font-size: 15px;
}

.tmpl-Event .header p {
  font-size: 16px;
}

.tmpl-EventList .frame-type-events_list .item > div > time,
.tmpl-EventList .frame-type-events_past .item > div > time {
  font-size: 18px;
}

.file-downloader {
  .doc-downloader-text {
    font-size: 15px;
  }

  .doc-downloader-link {
    font-size: 15px;
  }
}

.customTable td {
  font-size: 15px;
}

p.rtetitle3 {
  font-size: 17px;
}

.fido-text {
  font-size: 16px;
}

.expnum-link {
  font-size: 15.5px;
}

.dropdown-menu {
  a {
    @media (min-width: 992px) {
      font-size: 15px;
    }
  }

  .quicklinks-title {
    font-size: 19px;
  }

  .img-link span {
    font-weight: 400;
    font-size: 14px;
  }
}
