.frame-type-textpic {
  margin-bottom: $grid-gutter-width;

  @include media-breakpoint-up(lg) {
    margin-bottom: $grid-gutter-width * 2;
  }
}

.textpic-wrapper {
  figure {
    svg {
      position: absolute;
      right: $grid-gutter-width * 0.5;
      bottom: $grid-gutter-width * 0.5;
    }

    img {
      width: 100%;
    }
  }

  figcaption {
    text-align: center;
    font-size: 80%;
    padding: $grid-gutter-width * 0.25;
  }

  .imageorient {
    &-17,
    &-18 {
      @include media-breakpoint-up(lg) {
        margin-bottom: $grid-gutter-width * 0.5;
      }
    }

    &-17,
    &-25 {
      @include media-breakpoint-up(lg) {
        margin-left: $grid-gutter-width;
      }
    }

    &-18,
    &-26 {
      @include media-breakpoint-up(lg) {
        margin-right: $grid-gutter-width;
      }
    }

    &-17 {
      @include media-breakpoint-up(lg) {
        float: right;
      }
    }

    &-18 {
      @include media-breakpoint-up(lg) {
        float: left;
      }
    }

    &-25,
    &-26 {
      flex: 1 0 340px;

      @include media-breakpoint-up(lg) {
        flex: 1 0 216px;
      }

      @include media-breakpoint-up(xl) {
        flex: 1 0 270px;
      }
    }
  }
}
