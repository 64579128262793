// @import "../../../public/typo3conf/ext/solr/Resources/Public/StyleSheets/Frontend/suggest.css";

@use 'sass:math';

$search__select--native-breakpoint: md;
$search__expandable--breakpoint: xs;
$search__form-input--max-width: 210px;
$search__form-input--max-width-large: 548px;
$search__form-input--height: 50px;
$search__form-submit-width: 42px;
$search__form-submit-height: 42px;

.search {
  &__container {
    background-color: __get($colors, very-light-grey);
    margin-bottom: $grid-gutter-width;
    padding: $grid-gutter-width * 0.5 $grid-gutter-width;
  }

  &__form {
    padding: 0;
    position: relative;

    &-input {
      border: 1px solid transparent;
      border-radius: 5px 0 0 5px;
      background: transparent;
      box-shadow: none;
      display: block;
      height: 100%;
      flex-grow: 1;
      flex-shrink: 1;
      color: inherit;
      overflow: hidden;
      width: auto;
      padding: 0.25rem 0.5rem;

      &:focus {
        // border: 1px solid __get($colors, 'dark-grey');
        border: 0;

        box-shadow: none;
        outline: 0;
      }
    }

    &-submit {
      border: 0;
      background: transparent;
      display: block;
      flex-grow: 0;
      flex-shrink: 0;
      color: __get($colors, dark-grey);
      font-size: 1rem;
      padding: 12px;
      width: $search__form-submit-width;
      height: $search__form-submit-height;

      svg path {
        fill: __get($colors, dark-grey);
      }
    }

    &--default {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      margin-bottom: $grid-gutter-width;
      padding: 0;
    }

    &--default &-input {
      background-color: __get($colors, 'white');
      height: $search__form-input--height;
      border-radius: 5px 0 0 5px;
      padding: 0 $grid-gutter-width * 0.5;

      &:focus {
        border: 1px solid __get($colors, 'grey');
        box-shadow: none;
        outline: none;
      }
    }

    &--default &-submit {
      border-radius: 0 5px 5px 0;
      background-color: __get($colors, 'red');
      height: $search__form-input--height;
      width: $search__form-input--height;
      svg path {
        fill: __get($colors, 'white');
      }
    }

    &--expandable {
      position: relative;
      background: transparent;
      color: currentColor;

      .search__suggestions {
        left: -$search__form-input--max-width;

        @include media-breakpoint-up(lg) {
          left: -$search__form-input--max-width-large;
        }
      }

      &:not(.search__form--expanded) .search__suggestions {
        display: none !important;
      }
    }

    &--expandable &-input {
      position: absolute !important;
      right: $search__form-submit-width;
      background: inherit;
      top: 0;
      transition:
        width 0.2s ease,
        padding 0.2s ease;
      width: 0;
      padding: 0;
    }

    &--expandable#{&}--expanded {
      background: __get($colors, 'very-light-grey');
      color: __get($colors, 'dark-grey');
      padding: 0;

      &:focus-within {
        .search__form-input {
          border-style: solid;
          border-color: __get($colors, 'grey');
          border-width: 1px 0 1px 1px;
          border-radius: 5px 0 0 5px;
          box-shadow: none;

          & + .search__form-submit {
            border-style: solid;
            border-color: __get($colors, 'grey');
            border-width: 1px 1px 1px 0;
            border-radius: 0 5px 5px 0;
            padding: calc(1rem - 1px) 1.25rem calc(1rem - 1px) 0;
          }
        }
      }
    }

    &--expandable#{&}--expanded &-input {
      width: calc(100% - #{$search__form-submit-width});
      padding: 0 $grid-gutter-width * 0.5;

      @include media-breakpoint-up($search__expandable--breakpoint) {
        width: $search__form-input--max-width;
      }

      @include media-breakpoint-up(lg) {
        width: $search__form-input--max-width-large;
      }
    }

    &__suggestions {
      position: absolute;
      display: block;
      top: 100%;
      right: 0;
      left: 0;

      .autocomplete-suggestions {
        width: 100% !important;
        background: __get($colors, 'white');
        overflow: auto;
      }

      .autocomplete-suggestion {
        padding: $grid-gutter-width * 0.25 $grid-gutter-width * 0.5;
        cursor: pointer;
        user-select: none;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        strong {
          color: __get($colors, 'dark-grey');
          font-weight: bold;
        }

        &:hover {
          background: fade-out(__get($colors, 'dark-grey'), 0.8);
        }
      }
    }

    &.autocomplete-active & {
      &-submit,
      &-input {
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
      }
    }
  }

  &__select {
    @include custom-select(__get($colors, 'white'), __get($colors, 'dark-grey'));

    position: relative;
    overflow: unset;
    height: 50px;
    border-radius: 5px;

    &--active {
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;
      box-shadow: 0 4px 4px fade-out(__get($colors, 'dark-grey'), 0.75);
    }

    &--disabled {
      @include custom-select(fade-out(__get($colors, 'white'), 0.5), fade-out(__get($colors, 'dark-grey'), 0.5));
      background-color: fade-out(__get($colors, 'white'), 0.5) !important;
      color: fade-out(__get($colors, 'dark-grey'), 0.5);
    }

    ul {
      margin: 0;
      li {
        list-style: none;
        margin: 0;
        &:before {
          display: none;
        }
      }
    }

    &-label {
      position: absolute;
      display: flex;
      align-items: center;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      user-select: none;
      cursor: pointer;
      padding: math.div($grid-gutter-width, 3) $grid-gutter-width * 0.5;
      margin: 0;
      border: none;
      background: transparent;
      width: 100%;

      &--native {
        @include media-breakpoint-up($search__select--native-breakpoint) {
          display: none;
        }
      }

      &--custom {
        display: none;

        @include media-breakpoint-up($search__select--native-breakpoint) {
          display: flex;
        }
      }
    }

    &-input {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      display: block;
      width: 100%;
      background: transparent;
      font-size: 0;
      appearance: none;
      border: none;

      option {
        font-size: 1rem;
      }

      @include media-breakpoint-up($search__select--native-breakpoint) {
        display: none;
      }
    }

    &-options {
      position: absolute;
      list-style: none;
      margin: 0;
      padding: 0;
      top: 100%;
      left: -1px;
      right: -1px;
      background: __get($colors, 'white');
      z-index: 9999;
      display: none;
      border-radius: 0 0 5px 5px;
      border-top-width: 0;
      box-shadow: 0 4px 4px fade-out(__get($colors, 'dark-grey'), 0.75);

      &--active {
        display: block;
      }
    }

    &-option {
      display: flex;
      justify-content: space-between;
      padding: 12px 24px;
      align-items: center;
      user-select: none;
      cursor: pointer;
      list-style: none;

      &:hover {
        background: fade-out(__get($colors, 'dark-grey'), 0.8);
      }
    }

    &-checkbox {
      border-radius: 2px;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 18px;
      height: 18px;
      border: 2px solid __get($colors, 'dark-grey');
      margin-right: $grid-gutter-width * 0.5;
      flex-grow: 0;
      flex-shrink: 0;

      &--active {
        @include custom-checkbox(__get($colors, 'dark-grey'));
        color: __get($colors, 'very-light-grey');
      }
    }

    &-optionLabel {
      flex-grow: 1;
      font-size: 16px;
      color: __get($colors, 'dark-grey');
    }

    &-count {
      border-radius: 2px;
      background: __get($colors, 'red');
      color: __get($colors, 'white');
      flex-grow: 0;
      flex-shrink: 0;
      padding: 4px 8px;
      font-size: 1.2rem;
    }
  }

  &__result {
    border-top: 1px solid __get($colors, not-so-dark-grey);
    position: relative;

    &:last-of-type {
      border-bottom: 1px solid __get($colors, not-so-dark-grey);
    }

    &-title a {
      color: __get($colors, 'red');
      font-family: $font-family-header;
      font-size: 2.1rem;

      &:focus,
      &:hover {
        text-decoration: underline;
      }

      &::after {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 1;
        content: '';
      }
    }

    &-image {
      margin-right: 1rem;
      margin-bottom: 0.5rem;
    }

    &-category {
      color: __get($colors, dark-grey);
    }

    &-content .results-highlight {
      color: __get($colors, 'red');
    }
  }

  &__suggestions {
    position: absolute;
    display: block;
    top: 100%;
    right: 0;
    left: 0;

    .autocomplete {
      &-suggestions {
        position: relative !important;
        width: 100% !important;
        background: __get($colors, background);
        overflow: auto;
        box-shadow: $suggest-shadow;
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
        z-index: 1040 !important;
      }

      &-suggestion {
        min-height: $grid-gutter-width * 1.5;
        padding: math.div($grid-gutter-width, 3) $grid-gutter-width * 0.5;

        strong {
          font-weight: 400;
          color: __get($colors, 'red');
        }
      }

      &-selected {
        background: __get($colors, 'very-light-grey');
        cursor: pointer;
      }
    }
  }

  &__facets {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 20px;

    &-label {
      padding: 0.5rem 0.75rem 0.5rem 0;
      margin-right: 0.5rem;
      margin-bottom: math.div($grid-gutter-width, 3);
    }

    &-facet {
      border: 1px solid __get($colors, 'red');
      background: __get($colors, 'white');
      color: __get($colors, 'red');
      padding: 0.5rem 0.75rem;
      margin-right: 1rem;
      margin-bottom: 1rem;
      display: flex;
      align-items: center;

      .search__form-icon {
        display: block;
        margin-left: 0.75rem;
      }

      &:focus,
      &:hover {
        background-color: rgba(__get($colors, 'red'), 0.2);
        color: __get($colors, 'red');
      }
    }
  }

  &__icons {
    display: none;
  }
}
