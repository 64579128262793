@import '../../Scss/00-FontVariables';

div.csc-textpic .csc-textpic-imagewrap .csc-textpic-image {
  margin-bottom: 0px;
}

DIV.csc-textpic-above DIV.csc-textpic-imagewrap,
DIV.csc-textpic-below DIV.csc-textpic-imagewrap,
DIV.csc-textpic DIV.csc-textpic-imagewrap UL LI,
DIV.csc-textpic DIV.csc-textpic-imagewrap DL.csc-textpic-image {
  float: none;
}

.csc-header-alignment-center {
  text-align: center;
}

.csc-header-alignment-right {
  text-align: right;
}

.csc-header-alignment-left {
  text-align: left;
}

/* Clear floats after csc-textpic and after csc-textpic-imagerow */
DIV.csc-textpic,
DIV.csc-textpic DIV.csc-textpic-imagerow,
UL.csc-uploads LI {
  overflow: hidden;
}

.csc-textpic-intext-left-wrap .csc-textpic-imagewrap {
  margin-right: 20px;
}

.csc-textpic-intext-left-wrap .csc-textpic-text {
  padding-left: 15px;
}

.csc-textpic-intext-right-wrap .csc-textpic-imagewrap {
  margin-left: 20px !important;
}

.csc-textpic-intext-right-wrap .csc-textpic-imagewrap {
  float: right !important;
  /* um spanX CSS Einstellungen zu überlagern */
}

DIV.csc-textpic-border DIV.csc-textpic-imagewrap FIGURE {
  border: 1px solid #cccccc;
  padding: 5px 5px;
}

/* Settings for figure and figcaption (HTML5) */
DIV.csc-textpic .csc-textpic-imagewrap FIGURE,
DIV.csc-textpic FIGURE.csc-textpic-imagewrap {
  margin: 0;
  display: block;
}

/* Captions */
FIGCAPTION.csc-textpic-caption {
  display: block;
}

.csc-textpic-caption {
  text-align: left;
  caption-side: bottom;
}

DIV.csc-textpic-caption-c .csc-textpic-caption,
.csc-textpic-imagewrap .csc-textpic-caption-c {
  text-align: center;
}

DIV.csc-textpic-caption-r .csc-textpic-caption,
.csc-textpic-imagewrap .csc-textpic-caption-r {
  text-align: right;
}

DIV.csc-textpic-caption-l .csc-textpic-caption,
.csc-textpic-imagewrap .csc-textpic-caption-l {
  text-align: left;
}

/* For Firefox. Hat sonst Probleme in Verbindung mit dem display: table */
/*
DIV.csc-textpic .csc-textpic-imagewrap FIGURE img, DIV.csc-textpic FIGURE.csc-textpic-imagewrap img { width: 100%; }
*/
DIV.csc-textpic-imagecolumn {
  margin-right: 0;
  /* Abstand wird von bootstrap realisiert */
}

.csc-textpic-center {
  text-align: center;
}

.csc-textpic-center img {
  margin: 0 auto;
}

.csc-margin-after {
  margin-bottom: 30px;
}

.csc-textpic-center img {
  margin: 0 auto;
}

/* Fix for buttons groups */
.btn-group > div {
  display: inline;
}

/* Abstand unter den Bildern in der Smartphone-Ansicht */
@media (max-width: 992px) {
  .csc-textpic .csc-textpic-imagewrap {
    margin-bottom: 20px;
  }
}

.news .footer {
  background: transparent;
}

.news .article .facebook div {
  float: left;
  margin-right: 10px;
}

.news .social {
  margin-top: 20px;
  overflow: hidden;
}

.news .social .like,
.news .social .share,
.news .social .twitter {
  float: left;
  margin-right: 20px;
}

.formError {
  z-index: 990;
}

.formError .formErrorContent {
  z-index: 991;
}

.formError .formErrorArrow {
  z-index: 996;
}

.formErrorInsideDialog.formError {
  z-index: 5000;
}

.formErrorInsideDialog.formError .formErrorContent {
  z-index: 5001;
}

.formErrorInsideDialog.formError .formErrorArrow {
  z-index: 5006;
}

.inputContainer {
  position: relative;
  float: left;
}

.formError {
  font-family: Calibri, Arial, sans-serif;
  position: absolute;
  top: 300px;
  left: 300px;
  display: block;
  cursor: pointer;
}

.ajaxSubmit {
  padding: 20px;
  background: #55ea55;
  border: 1px solid #999;
  display: none;
}

.formError .formErrorContent {
  width: 100%;
  position: relative;
  color: #fff;
  width: 150px;
  font-size: 11px;
  border: 2px solid #ddd;
  padding: 4px 10px 4px 10px;
  background: #1e5799;
  /* Old browsers */
}

.greenPopup .formErrorContent {
  background: #33be40;
}

.blackPopup .formErrorContent {
  background: #393939;
  color: #fff;
}

.formError .formErrorArrow {
  width: 15px;
  margin: -2px 0 0 13px;
  position: relative;
}

body[dir='rtl'] .formError .formErrorArrow,
body.rtl .formError .formErrorArrow {
  margin: -2px 13px 0 0;
}

.formError .formErrorArrowBottom {
  box-shadow: none;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
  margin: 0px 0 0 12px;
  top: 2px;
}

.formError .formErrorArrow div {
  border-left: 2px solid #ddd;
  border-right: 2px solid #ddd;
  font-size: 0px;
  height: 1px;
  margin: 0 auto;
  line-height: 0;
  font-size: 0;
  display: block;
  background: #1e5799;
  /* Old browsers */
}

.formError .formErrorArrowBottom div {
  box-shadow: none;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
}

.greenPopup .formErrorArrow div {
  background: #33be40;
}

.blackPopup .formErrorArrow div {
  background: #393939;
  color: #fff;
}

.formError .formErrorArrow .line10 {
  width: 15px;
  border: none;
}

.formError .formErrorArrow .line9 {
  width: 13px;
  border: none;
}

.formError .formErrorArrow .line8 {
  width: 11px;
}

.formError .formErrorArrow .line7 {
  width: 9px;
}

.formError .formErrorArrow .line6 {
  width: 7px;
}

.formError .formErrorArrow .line5 {
  width: 5px;
}

.formError .formErrorArrow .line4 {
  width: 3px;
}

.formError .formErrorArrow .line3 {
  width: 1px;
  border-left: 2px solid #ddd;
  border-right: 2px solid #ddd;
  border-bottom: 0 solid #ddd;
}

.formError .formErrorArrow .line2 {
  width: 3px;
  border: none;
  background: #ddd;
}

.formError .formErrorArrow .line1 {
  width: 1px;
  border: none;
  background: #ddd;
}

.btn-upload {
  margin-top: 5px;
}

.tx-events2 .list .item {
  margin-bottom: 40px;
}

.tx-events2 .list .item h3 {
  margin-top: 0px;
}

.tx-events2 .list .footer {
  clear: both;
  padding: 10px 0 0 0;
  border-top: 1px solid #eee;
  margin-top: 15px;
  color: #888;
}

.tx-events2 .list-date {
  background: #337ab7;
  margin: 0 23px 21px 0;
  padding: 8px 16px;
  color: #fff;
  text-align: center;
}

.tx-events2 .list-date .dayname {
  font-weight: bold;
  font-size: 23px;
  display: block;
}

/**************************************/
/* Default styles for news extension */
/**************************************/
.news .clear {
  clear: both;
}

.news .nav ul {
  float: left;
  display: inline;
  margin: 0;
}

.news .nav li {
  float: left;
}

.news .nav a {
  float: left;
  display: block;
}

.news .nav:after {
  content: '.';
  display: block;
  height: 0;
  clear: both;
  visibility: hidden;
}

.news .article {
  padding-bottom: 15px;
  margin-bottom: 15px;
}

.news .article:last-child {
  padding-bottom: 0;
  margin-bottom: 0;
}

.news .article:after {
  content: '.';
  display: block;
  height: 0;
  clear: both;
  visibility: hidden;
}

.news .article .news-img-wrap {
  float: right;
  margin: 3px 12px 5px 0;
  background: #fff;
}

.news .article .news-img-wrap a {
  display: block;
  float: left;
  padding: 4px;
  border: 1px solid #ddd;
  background: #fff;
}

.news .article .news-img-wrap img {
  float: left;
  height: auto;
  width: auto;
}

.news .article .teaser-text {
  margin: 0 0 10px 0;
}

.news .article .news-backlink-wrap {
  margin-top: 20px;
}

.news .article .news-backlink-wrap a {
  color: #af1c1a;
  font-family: $font-family-bold;
  font-weight: 700;
}

.news .footer {
  clear: both;
  padding: 10px 0 0 0;
  border-top: 1px solid #eee;
  margin-top: 15px;
  color: #888;
}

.news .footer p {
  line-height: 1em;
}

.news .footer span {
  display: inline-block;
}

.news .footer span:last-child {
  border-right: 0;
}

.news .footer .news-category a {
  text-transform: uppercase;
}

.news .no-news-found {
  color: #db0202;
  font-style: italic;
}

/* category menu view */
.news-category-view ul li {
  padding: 5px 8px;
}

.news-category-view ul li li {
  padding: 2px 8px 2px 12px;
}

.news-category-view > ul > li:nth-child(even) {
  background: #f9f9f9;
  border-top: 1px solid #eee;
  border-bottom: 1px solid #eee;
}

.news-category-view .category-desc {
  padding-left: 10px;
  margin-left: 10px;
  border-left: 1px solid #ddd;
}

/* single view */
.news-single .footer {
  margin-bottom: 20px;
  margin-top: 5px;
  padding: 10px 0 0 0;
  border-bottom: 0;
  border-top: 1px solid #eee;
}

.news-single .article .news-img-wrap {
  float: right;
  width: 282px;
  margin: 0 0 0 25px;
}

.news-single .article .news-img-wrap img {
  float: none;
}

.news-single .teaser-text {
  font-size: 15px;
  color: #888;
}

.news-text-wrap {
  margin-bottom: 10px;
}

.news-img-caption {
  color: #888;
}

.news-clear {
  clear: both;
}

/*!
Video.js Default Styles (http://videojs.com)
Version GENERATED_AT_BUILD
Create your own skin at http://designer.videojs.com
*/
/* SKIN
================================================================================
The main class name for all skin-specific styles. To make your own skin,
replace all occurances of 'vjs-default-skin' with a new name. Then add your new
skin name to your video tag instead of the default skin.
e.g. <video class="video-js my-skin-name">
*/
.vjs-default-skin {
  color: #cccccc;
}

/* Custom Icon Font
--------------------------------------------------------------------------------
The control icons are from a custom font. Each icon corresponds to a character
(e.g. "\e001"). Font icons allow for easy scaling and coloring of icons.
*/
@font-face {
  font-family: 'VideoJS';
  src: url('../Fonts/video-js/vjs.eot');
  src:
    url('../Fonts/video-js/vjs.eot?#iefix') format('embedded-opentype'),
    url('../Fonts/video-js/vjs.woff') format('woff'),
    url('../Fonts/video-js/vjs.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

/* Base UI Component Classes
--------------------------------------------------------------------------------
*/
/* Slider - used for Volume bar and Seek bar */
.vjs-default-skin .vjs-slider {
  /* Replace browser focus hightlight with handle highlight */
  outline: 0;
  position: relative;
  cursor: pointer;
  padding: 0;
  /* background-color-with-alpha */
  background-color: #333333;
  background-color: rgba(51, 51, 51, 0.9);
}

.vjs-default-skin .vjs-slider:focus {
  /* box-shadow */
  -webkit-box-shadow: 0 0 2em #ffffff;
  -moz-box-shadow: 0 0 2em #ffffff;
  box-shadow: 0 0 2em #ffffff;
}

.vjs-default-skin .vjs-slider-handle {
  position: absolute;
  /* Needed for IE6 */
  left: 0;
  top: 0;
}

.vjs-default-skin .vjs-slider-handle:before {
  content: '\e009';
  font-family: VideoJS;
  font-size: 1em;
  line-height: 1;
  text-align: center;
  text-shadow: 0em 0em 1em #fff;
  position: absolute;
  top: 0;
  left: 0;
  /* Rotate the square icon to make a diamond */
  /* transform */
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

/* Control Bar
--------------------------------------------------------------------------------
The default control bar that is a container for most of the controls.
*/
.vjs-default-skin .vjs-control-bar {
  /* Start hidden */
  display: none;
  position: absolute;
  /* Place control bar at the bottom of the player box/video.
     If you want more margin below the control bar, add more height. */
  bottom: 0;
  /* Use left/right to stretch to 100% width of player div */
  left: 0;
  right: 0;
  /* Height includes any margin you want above or below control items */
  height: 3em;
  /* background-color-with-alpha */
  background-color: #07141e;
  background-color: rgba(7, 20, 30, 0.7);
}

/* Show the control bar only once the video has started playing */
.vjs-default-skin.vjs-has-started .vjs-control-bar {
  display: block;
  /* Visibility needed to make sure things hide in older browsers too. */
  visibility: visible;
  opacity: 1;
  /* transition */
  -webkit-transition:
    visibility 0.1s,
    opacity 0.1s;
  -moz-transition:
    visibility 0.1s,
    opacity 0.1s;
  -o-transition:
    visibility 0.1s,
    opacity 0.1s;
  transition:
    visibility 0.1s,
    opacity 0.1s;
}

/* Hide the control bar when the video is playing and the user is inactive  */
.vjs-default-skin.vjs-has-started.vjs-user-inactive.vjs-playing .vjs-control-bar {
  display: block;
  visibility: hidden;
  opacity: 0;
  /* transition */
  -webkit-transition:
    visibility 1s,
    opacity 1s;
  -moz-transition:
    visibility 1s,
    opacity 1s;
  -o-transition:
    visibility 1s,
    opacity 1s;
  transition:
    visibility 1s,
    opacity 1s;
}

.vjs-default-skin.vjs-controls-disabled .vjs-control-bar {
  display: none;
}

.vjs-default-skin.vjs-using-native-controls .vjs-control-bar {
  display: none;
}

/* The control bar shouldn't show after an error */
.vjs-default-skin.vjs-error .vjs-control-bar {
  display: none;
}

/* IE8 is flakey with fonts, and you have to change the actual content to force
fonts to show/hide properly.
  - "\9" IE8 hack didn't work for this
  - Found in XP IE8 from http://modern.ie. Does not show up in "IE8 mode" in IE9
*/
@media \0screen {
  .vjs-default-skin.vjs-user-inactive.vjs-playing .vjs-control-bar :before {
    content: '';
  }
}

/* General styles for individual controls. */
.vjs-default-skin .vjs-control {
  outline: none;
  position: relative;
  float: left;
  text-align: center;
  margin: 0;
  padding: 0;
  height: 3em;
  width: 4em;
}

/* FontAwsome button icons */
.vjs-default-skin .vjs-control:before {
  font-family: VideoJS;
  font-size: 1.5em;
  line-height: 2;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  text-align: center;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.5);
}

/* Replacement for focus outline */
.vjs-default-skin .vjs-control:focus:before,
.vjs-default-skin .vjs-control:hover:before {
  text-shadow: 0em 0em 1em #ffffff;
}

.vjs-default-skin .vjs-control:focus {
  /*  outline: 0; */
  /* keyboard-only users cannot see the focus on several of the UI elements when
  this is set to 0 */
}

/* Hide control text visually, but have it available for screenreaders */
.vjs-default-skin .vjs-control-text {
  /* hide-visually */
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

/* Play/Pause
--------------------------------------------------------------------------------
*/
.vjs-default-skin .vjs-play-control {
  width: 5em;
  cursor: pointer;
}

.vjs-default-skin .vjs-play-control:before {
  content: '\e001';
}

.vjs-default-skin.vjs-playing .vjs-play-control:before {
  content: '\e002';
}

/* Playback toggle
--------------------------------------------------------------------------------
*/
.vjs-default-skin .vjs-playback-rate .vjs-playback-rate-value {
  font-size: 1.5em;
  line-height: 2;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  text-align: center;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.5);
}

.vjs-default-skin .vjs-playback-rate.vjs-menu-button .vjs-menu .vjs-menu-content {
  width: 4em;
  left: -2em;
  list-style: none;
}

/* Volume/Mute
-------------------------------------------------------------------------------- */
.vjs-default-skin .vjs-mute-control,
.vjs-default-skin .vjs-volume-menu-button {
  cursor: pointer;
  float: right;
}

.vjs-default-skin .vjs-mute-control:before,
.vjs-default-skin .vjs-volume-menu-button:before {
  content: '\e006';
}

.vjs-default-skin .vjs-mute-control.vjs-vol-0:before,
.vjs-default-skin .vjs-volume-menu-button.vjs-vol-0:before {
  content: '\e003';
}

.vjs-default-skin .vjs-mute-control.vjs-vol-1:before,
.vjs-default-skin .vjs-volume-menu-button.vjs-vol-1:before {
  content: '\e004';
}

.vjs-default-skin .vjs-mute-control.vjs-vol-2:before,
.vjs-default-skin .vjs-volume-menu-button.vjs-vol-2:before {
  content: '\e005';
}

.vjs-default-skin .vjs-volume-control {
  width: 5em;
  float: right;
}

.vjs-default-skin .vjs-volume-bar {
  width: 5em;
  height: 0.6em;
  margin: 1.1em auto 0;
}

.vjs-default-skin .vjs-volume-menu-button .vjs-menu-content {
  height: 2.9em;
}

.vjs-default-skin .vjs-volume-level {
  position: absolute;
  top: 0;
  left: 0;
  height: 0.5em;
  /* assuming volume starts at 1.0 */
  width: 100%;
  background: #66a8cc
    url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAYAAAAGCAYAAADgzO9IAAAAP0lEQVQIHWWMAQoAIAgDR/QJ/Ub//04+w7ZICBwcOg5FZi5iBB82AGzixEglJrd4TVK5XUJpskSTEvpdFzX9AB2pGziSQcvAAAAAAElFTkSuQmCC) -50%
    0 repeat;
}

.vjs-default-skin .vjs-volume-bar .vjs-volume-handle {
  width: 0.5em;
  height: 0.5em;
  /* Assumes volume starts at 1.0. If you change the size of the
     handle relative to the volume bar, you'll need to update this value
     too. */
  left: 4.5em;
}

.vjs-default-skin .vjs-volume-handle:before {
  font-size: 0.9em;
  top: -0.2em;
  left: -0.2em;
  width: 1em;
  height: 1em;
}

.vjs-default-skin .vjs-volume-menu-button .vjs-menu .vjs-menu-content {
  width: 6em;
  left: -4em;
}

/* Progress
--------------------------------------------------------------------------------
*/
.vjs-default-skin .vjs-progress-control {
  position: absolute;
  left: 0;
  right: 0;
  width: auto;
  font-size: 0.3em;
  height: 1em;
  /* Set above the rest of the controls. */
  top: -1em;
  /* Shrink the bar slower than it grows. */
  /* transition */
  -webkit-transition: all 0.4s;
  -moz-transition: all 0.4s;
  -o-transition: all 0.4s;
  transition: all 0.4s;
}

/* On hover, make the progress bar grow to something that's more clickable.
    This simply changes the overall font for the progress bar, and this
    updates both the em-based widths and heights, as wells as the icon font */
.vjs-default-skin:hover .vjs-progress-control {
  font-size: 0.9em;
  /* Even though we're not changing the top/height, we need to include them in
      the transition so they're handled correctly. */
  /* transition */
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
}

/* Box containing play and load progresses. Also acts as seek scrubber. */
.vjs-default-skin .vjs-progress-holder {
  height: 100%;
}

/* Progress Bars */
.vjs-default-skin .vjs-progress-holder .vjs-play-progress,
.vjs-default-skin .vjs-progress-holder .vjs-load-progress {
  position: absolute;
  display: block;
  height: 100%;
  margin: 0;
  padding: 0;
  /* updated by javascript during playback */
  width: 0;
  /* Needed for IE6 */
  left: 0;
  top: 0;
}

.vjs-default-skin .vjs-play-progress {
  /*
    Using a data URI to create the white diagonal lines with a transparent
      background. Surprisingly works in IE8.
      Created using http://www.patternify.com
    Changing the first color value will change the bar color.
    Also using a paralax effect to make the lines move backwards.
      The -50% left position makes that happen.
  */
  background: #66a8cc
    url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAYAAAAGCAYAAADgzO9IAAAAP0lEQVQIHWWMAQoAIAgDR/QJ/Ub//04+w7ZICBwcOg5FZi5iBB82AGzixEglJrd4TVK5XUJpskSTEvpdFzX9AB2pGziSQcvAAAAAAElFTkSuQmCC) -50%
    0 repeat;
}

.vjs-default-skin .vjs-load-progress {
  background: #646464 /* IE8- Fallback */;
  background: rgba(255, 255, 255, 0.4);
}

.vjs-default-skin .vjs-seek-handle {
  width: 1.5em;
  height: 100%;
}

.vjs-default-skin .vjs-seek-handle:before {
  padding-top: 0.1em /* Minor adjustment */;
}

/* Live Mode
--------------------------------------------------------------------------------
*/
.vjs-default-skin.vjs-live .vjs-time-controls,
.vjs-default-skin.vjs-live .vjs-time-divider,
.vjs-default-skin.vjs-live .vjs-progress-control {
  display: none;
}

.vjs-default-skin.vjs-live .vjs-live-display {
  display: block;
}

/* Live Display
--------------------------------------------------------------------------------
*/
.vjs-default-skin .vjs-live-display {
  display: none;
  font-size: 1em;
  line-height: 3em;
}

/* Time Display
--------------------------------------------------------------------------------
*/
.vjs-default-skin .vjs-time-controls {
  font-size: 1em;
  /* Align vertically by making the line height the same as the control bar */
  line-height: 3em;
}

.vjs-default-skin .vjs-current-time {
  float: left;
}

.vjs-default-skin .vjs-duration {
  float: left;
}

/* Remaining time is in the HTML, but not included in default design */
.vjs-default-skin .vjs-remaining-time {
  display: none;
  float: left;
}

.vjs-time-divider {
  float: left;
  line-height: 3em;
}

/* Fullscreen
--------------------------------------------------------------------------------
*/
.vjs-default-skin .vjs-fullscreen-control {
  width: 3.8em;
  cursor: pointer;
  float: right;
}

.vjs-default-skin .vjs-fullscreen-control:before {
  content: '\e000';
}

/* Switch to the exit icon when the player is in fullscreen */
.vjs-default-skin.vjs-fullscreen .vjs-fullscreen-control:before {
  content: '\e00b';
}

/* Big Play Button (play button at start)
--------------------------------------------------------------------------------
Positioning of the play button in the center or other corners can be done more
easily in the skin designer. http://designer.videojs.com/
*/
.vjs-default-skin .vjs-big-play-button {
  left: 0.5em;
  top: 0.5em;
  font-size: 3em;
  display: block;
  z-index: 2;
  position: absolute;
  width: 4em;
  height: 2.6em;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  opacity: 1;
  /* Need a slightly gray bg so it can be seen on black backgrounds */
  /* background-color-with-alpha */
  background-color: #07141e;
  background-color: rgba(7, 20, 30, 0.7);
  border: 0.1em solid #3b4249;
  /* border-radius */
  -webkit-border-radius: 0.8em;
  -moz-border-radius: 0.8em;
  border-radius: 0.8em;
  /* box-shadow */
  -webkit-box-shadow: 0px 0px 1em rgba(255, 255, 255, 0.25);
  -moz-box-shadow: 0px 0px 1em rgba(255, 255, 255, 0.25);
  box-shadow: 0px 0px 1em rgba(255, 255, 255, 0.25);
  /* transition */
  -webkit-transition: all 0.4s;
  -moz-transition: all 0.4s;
  -o-transition: all 0.4s;
  transition: all 0.4s;
}

/* Optionally center */
.vjs-default-skin.vjs-big-play-centered .vjs-big-play-button {
  /* Center it horizontally */
  left: 50%;
  margin-left: -2.1em;
  /* Center it vertically */
  top: 50%;
  margin-top: -1.4em;
}

/* Hide if controls are disabled */
.vjs-default-skin.vjs-controls-disabled .vjs-big-play-button {
  display: none;
}

/* Hide when video starts playing */
.vjs-default-skin.vjs-has-started .vjs-big-play-button {
  display: none;
}

/* Hide on mobile devices. Remove when we stop using native controls
    by default on mobile  */
.vjs-default-skin.vjs-using-native-controls .vjs-big-play-button {
  display: none;
}

.vjs-default-skin:hover .vjs-big-play-button,
.vjs-default-skin .vjs-big-play-button:focus {
  outline: 0;
  border-color: #fff;
  /* IE8 needs a non-glow hover state */
  background-color: #505050;
  background-color: rgba(50, 50, 50, 0.75);
  /* box-shadow */
  -webkit-box-shadow: 0 0 3em #ffffff;
  -moz-box-shadow: 0 0 3em #ffffff;
  box-shadow: 0 0 3em #ffffff;
  /* transition */
  -webkit-transition: all 0s;
  -moz-transition: all 0s;
  -o-transition: all 0s;
  transition: all 0s;
}

.vjs-default-skin .vjs-big-play-button:before {
  content: '\e001';
  font-family: VideoJS;
  /* In order to center the play icon vertically we need to set the line height
     to the same as the button height */
  line-height: 2.6em;
  text-shadow: 0.05em 0.05em 0.1em #000;
  text-align: center /* Needed for IE8 */;
  position: absolute;
  left: 0;
  width: 100%;
  height: 100%;
}

.vjs-error .vjs-big-play-button {
  display: none;
}

/* Error Display
--------------------------------------------------------------------------------
*/
.vjs-error-display {
  display: none;
}

.vjs-error .vjs-error-display {
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
}

.vjs-error .vjs-error-display:before {
  content: 'X';
  font-family: Arial;
  font-size: 4em;
  color: #666666;
  /* In order to center the play icon vertically we need to set the line height
     to the same as the button height */
  line-height: 1;
  text-shadow: 0.05em 0.05em 0.1em #000;
  text-align: center /* Needed for IE8 */;
  vertical-align: middle;
  position: absolute;
  top: 50%;
  margin-top: -0.5em;
  width: 100%;
}

.vjs-error-display div {
  position: absolute;
  font-size: 1.4em;
  text-align: center;
  bottom: 1em;
  right: 1em;
  left: 1em;
}

.vjs-error-display a,
.vjs-error-display a:visited {
  color: #f4a460;
}

/* Loading Spinner
--------------------------------------------------------------------------------
*/
.vjs-loading-spinner {
  display: none;
  position: absolute;
  top: 50%;
  left: 50%;
  font-size: 4em;
  line-height: 1;
  width: 1em;
  height: 1em;
  margin-left: -0.5em;
  margin-top: -0.5em;
  opacity: 0.75;
  /* animation */
  -webkit-animation: spin 1.5s infinite linear;
  -moz-animation: spin 1.5s infinite linear;
  -o-animation: spin 1.5s infinite linear;
  animation: spin 1.5s infinite linear;
}

/* Errors are unrecoverable without user interaction,
   so hide the spinner in the case of an error */
.video-js.vjs-error .vjs-loading-spinner {
  /* using !important flag because currently the loading spinner
     uses hide()/show() instead of classes. The !important can be
     removed when that's updated */
  display: none !important;
  /* ensure animation doesn't continue while hidden */
  /* animation */
  -webkit-animation: none;
  -moz-animation: none;
  -o-animation: none;
  animation: none;
}

.vjs-default-skin .vjs-loading-spinner:before {
  content: '\e01e';
  font-family: VideoJS;
  position: absolute;
  top: 0;
  left: 0;
  width: 1em;
  height: 1em;
  text-align: center;
  text-shadow: 0em 0em 0.1em #000;
}

@-moz-keyframes spin {
  0% {
    -moz-transform: rotate(0deg);
  }
  100% {
    -moz-transform: rotate(359deg);
  }
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
  }
}

@-o-keyframes spin {
  0% {
    -o-transform: rotate(0deg);
  }
  100% {
    -o-transform: rotate(359deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(359deg);
  }
}

/* Menu Buttons (Captions/Subtitles/etc.)
--------------------------------------------------------------------------------
*/
.vjs-default-skin .vjs-menu-button {
  float: right;
  cursor: pointer;
}

.vjs-default-skin .vjs-menu {
  display: none;
  position: absolute;
  bottom: 0;
  left: 0em;
  /* (Width of vjs-menu - width of button) / 2 */
  width: 0em;
  height: 0em;
  margin-bottom: 3em;
  border-left: 2em solid transparent;
  border-right: 2em solid transparent;
  border-top: 1.55em solid #000000;
  /* Same width top as ul bottom */
  border-top-color: rgba(7, 40, 50, 0.5);
  /* Same as ul background */
}

/* Button Pop-up Menu */
.vjs-default-skin .vjs-menu-button .vjs-menu .vjs-menu-content {
  display: block;
  padding: 0;
  margin: 0;
  position: absolute;
  width: 10em;
  bottom: 1.5em;
  /* Same bottom as vjs-menu border-top */
  max-height: 15em;
  overflow: auto;
  left: -5em;
  /* Width of menu - width of button / 2 */
  /* background-color-with-alpha */
  background-color: #07141e;
  background-color: rgba(7, 20, 30, 0.7);
  /* box-shadow */
  -webkit-box-shadow: -0.2em -0.2em 0.3em rgba(255, 255, 255, 0.2);
  -moz-box-shadow: -0.2em -0.2em 0.3em rgba(255, 255, 255, 0.2);
  box-shadow: -0.2em -0.2em 0.3em rgba(255, 255, 255, 0.2);
}

.vjs-default-skin .vjs-menu-button:hover .vjs-menu {
  display: block;
}

.vjs-default-skin .vjs-menu-button ul li {
  list-style: none;
  margin: 0;
  padding: 0.3em 0 0.3em 0;
  line-height: 1.4em;
  font-size: 1.2em;
  text-align: center;
  text-transform: lowercase;
}

.vjs-default-skin .vjs-menu-button ul li.vjs-selected {
  background-color: #000;
}

.vjs-default-skin .vjs-menu-button ul li:focus,
.vjs-default-skin .vjs-menu-button ul li:hover,
.vjs-default-skin .vjs-menu-button ul li.vjs-selected:focus,
.vjs-default-skin .vjs-menu-button ul li.vjs-selected:hover {
  outline: 0;
  color: #111;
  /* background-color-with-alpha */
  background-color: #ffffff;
  background-color: rgba(255, 255, 255, 0.75);
  /* box-shadow */
  -webkit-box-shadow: 0 0 1em #ffffff;
  -moz-box-shadow: 0 0 1em #ffffff;
  box-shadow: 0 0 1em #ffffff;
}

.vjs-default-skin .vjs-menu-button ul li.vjs-menu-title {
  text-align: center;
  text-transform: uppercase;
  font-size: 1em;
  line-height: 2em;
  padding: 0;
  margin: 0 0 0.3em 0;
  font-weight: bold;
  cursor: default;
}

/* Subtitles Button */
.vjs-default-skin .vjs-subtitles-button:before {
  content: '\e00c';
}

/* Captions Button */
.vjs-default-skin .vjs-captions-button:before {
  content: '\e008';
}

/* Replacement for focus outline */
.vjs-default-skin .vjs-captions-button:focus .vjs-control-content:before,
.vjs-default-skin .vjs-captions-button:hover .vjs-control-content:before {
  /* box-shadow */
  -webkit-box-shadow: 0 0 1em #ffffff;
  -moz-box-shadow: 0 0 1em #ffffff;
  box-shadow: 0 0 1em #ffffff;
}

/*
REQUIRED STYLES (be careful overriding)
================================================================================
When loading the player, the video tag is replaced with a DIV,
that will hold the video tag or object tag for other playback methods.
The div contains the video playback element (Flash or HTML5) and controls,
and sets the width and height of the video.

** If you want to add some kind of border/padding (e.g. a frame), or special
positioning, use another containing element. Otherwise you risk messing up
control positioning and full window mode. **
*/
.video-js {
  background-color: #000;
  position: relative;
  padding: 0;
  /* Start with 10px for base font size so other dimensions can be em based and
     easily calculable. */
  font-size: 10px;
  /* Allow poster to be vertially aligned. */
  vertical-align: middle;
  /*  display: table-cell; */
  /*This works in Safari but not Firefox.*/
  /* Provide some basic defaults for fonts */
  font-weight: normal;
  font-style: normal;
  /* Avoiding helvetica: issue #376 */
  font-family: Arial, sans-serif;
  /* Turn off user selection (text highlighting) by default.
     The majority of player components will not be text blocks.
     Text areas will need to turn user selection back on. */
  /* user-select */
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Playback technology elements expand to the width/height of the containing div
    <video> or <object> */
.video-js .vjs-tech {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

/* Fix for Firefox 9 fullscreen (only if it is enabled). Not needed when
   checking fullScreenEnabled. */
.video-js:-moz-full-screen {
  position: absolute;
}

/* Fullscreen Styles */
body.vjs-full-window {
  padding: 0;
  margin: 0;
  height: 100%;
  /* Fix for IE6 full-window. http://www.cssplay.co.uk/layouts/fixed.html */
  overflow-y: auto;
}

.video-js.vjs-fullscreen {
  position: fixed;
  overflow: hidden;
  z-index: 1000;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  width: 100% !important;
  height: 100% !important;
  /* IE6 full-window (underscore hack) */
  _position: absolute;
}

.video-js:-webkit-full-screen {
  width: 100% !important;
  height: 100% !important;
}

.video-js.vjs-fullscreen.vjs-user-inactive {
  cursor: none;
}

/* Poster Styles */
.vjs-poster {
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: contain;
  cursor: pointer;
  height: 100%;
  margin: 0;
  padding: 0;
  position: relative;
  width: 100%;
}

.vjs-poster img {
  display: block;
  margin: 0 auto;
  max-height: 100%;
  padding: 0;
  width: 100%;
}

/* Hide the poster when native controls are used otherwise it covers them */
.video-js.vjs-using-native-controls .vjs-poster {
  display: none;
}

/* Text Track Styles */
/* Overall track holder for both captions and subtitles */
.video-js .vjs-text-track-display {
  text-align: center;
  position: absolute;
  bottom: 4em;
  /* Leave padding on left and right */
  left: 1em;
  right: 1em;
}

/* Move captions down when controls aren't being shown */
.video-js.vjs-user-inactive.vjs-playing .vjs-text-track-display {
  bottom: 1em;
}

/* Individual tracks */
.video-js .vjs-text-track {
  display: none;
  font-size: 1.4em;
  text-align: center;
  margin-bottom: 0.1em;
  /* Transparent black background, or fallback to all black (oldIE) */
  /* background-color-with-alpha */
  background-color: #000000;
  background-color: rgba(0, 0, 0, 0.5);
}

.video-js .vjs-subtitles {
  color: #ffffff /* Subtitles are white */;
}

.video-js .vjs-captions {
  color: #ffcc66 /* Captions are yellow */;
}

.vjs-tt-cue {
  display: block;
}

/* Hide disabled or unsupported controls */
.vjs-default-skin .vjs-hidden {
  display: none;
}

.vjs-lock-showing {
  display: block !important;
  opacity: 1;
  visibility: visible;
}

/*  In IE8 w/ no JavaScript (no HTML5 shim), the video tag doesn't register.
    The .video-js classname on the video tag also isn't considered.
    This optional paragraph inside the video tag can provide a message to users
    about what's required to play video. */
.vjs-no-js {
  padding: 20px;
  color: #ccc;
  background-color: #333;
  font-size: 18px;
  font-family: Arial, sans-serif;
  text-align: center;
  width: 300px;
  height: 150px;
  margin: 0px auto;
}

.vjs-no-js a,
.vjs-no-js a:visited {
  color: #f4a460;
}

/* -----------------------------------------------------------------------------
The original source of this file lives at
https://github.com/videojs/video.js/blob/master/src/css/video-js.less */
body .feature-slider-holder {
  margin: 0;
  padding: 0;
  border: 0;
  overflow: hidden;
}

body .feature-slider-holder .ps-icon-angle-left {
  left: 18px !important;
}

body .flexslider {
  margin: 0;
  padding: 0;
  border: 0;
}

body .flexslider ul.slides {
  margin: 0;
  padding: 0;
}

body .flexslider .flex-direction-nav {
  text-shadow: 0 3px 7px #000;
}

body .flexslider .slider_info a {
  color: #ffffff;
}

body .flexslider .slider_info .show-more {
  margin-top: 20px;
  display: none;
}

@media (min-width: 768px) {
  body .flexslider .slider_info .show-more {
    display: block;
  }
}

.verticalposition-middle {
  position: absolute;
  top: 50% !important;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  -o-transform: translate(0, -50%);
  transform: translate(0, -50%);
}

.verticalposition-top {
  position: absolute;
  top: 0;
}

.verticalposition-bottom {
  position: absolute;
  bottom: 0;
}

.horizontalposition-center {
  position: absolute;
  left: 50%;
  -webkit-transform: translate(-50%, 0);
  -ms-transform: translate(-50%, 0);
  -o-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
}

.horizontalposition-left {
  position: absolute;
  left: 0;
}

.horizontalposition-right {
  position: absolute;
  right: 0;
}

.horizontalposition-center.verticalposition-middle {
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

/*
	Den Abstand zwischen Google Maps und Footer unterdrücken
*/
.footer-section {
  margin-top: 0;
}

/***********************
	Projektliste
************************/
.project-filter {
  margin: 0;
  font-family: $font-family;
  background: #f2f2f2;
  padding: 34px;
  padding-bottom: 30px;
}

.project-filter h2 {
  font-weight: 400;
  font-size: 30px;
  line-height: 1.2;
  font-family: $font-family-header-bold;
  margin: -6px 0 25px;
  padding: 0;
}

.project-filter label,
.project-filter input[type='text'] {
  display: block;
  height: 50px;
  position: relative;
  font-size: 18px;
  line-height: 50px;
  padding: 0 0 0 24px;
}

.project-filter label {
  cursor: pointer;
  font-weight: normal !important;
}

.project-filter label i {
  float: right;
  vertical-align: top;
  line-height: 50px;
  font-size: 14px;
  font-weight: bolder;
  margin: 0 20px 0 0;
}

.project-filter select {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: none;
}

.project-filter label,
.project-filter .select,
.project-filter .input {
  background: #fff;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
}

.project-filter ul,
.project-filter li {
  list-style: none;
  margin: 0;
  padding: 0;
}

.project-filter .select-field,
.project-filter .input-field {
  padding-left: 7px;
}

.project-filter .select {
  display: none;
  position: absolute;
  top: 0;
  left: 7px;
  right: 15px;
  z-index: 10;
  padding: 47px 0 19px;
  font-weight: 400;
  font-size: 18px;
  line-height: 1.2;
  font-family: $font-family;
  /* box-shadow */
  -webkit-box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
  overflow-x: hidden;
}

.project-filter .select > ul {
  overflow: auto;
  max-height: 400px;
}

.project-filter li {
  padding: 3px 24px 3px;
}

.project-filter li.optgroup {
  background: none !important;
  font-weight: 400 !important;
  padding: 0;
}

.project-filter li.optgroup > span {
  display: block;
  padding: 11px 24px 3px;
  font-family: $font-family-bold;
  cursor: default;
}

.project-filter li:before {
  display: none;
}

.project-filter li:hover {
  background: #f2f2f2;
}

.project-filter .default,
.project-filter .selected,
.project-filter i {
  position: relative;
  z-index: 15;
}

.project-filter .select-field:has(.open) .select {
  display: block;
}

.project-filter .open i:before {
  content: '\f106';
}

.project-filter input[type='text'] {
  width: 100%;
  border: 0;
}

.project-filter .input {
  padding: 0 50px 0 0;
  position: relative;
  overflow: hidden;
}

.project-filter button {
  position: absolute;
  top: 0;
  right: 0;
  width: 50px;
  height: 50px;
  background: #af1c1a;
  border: 0;
  text-indent: -9999px;
}

.project-filter button i {
  position: absolute;
  top: 0;
  right: 0;
  display: block;
  width: 50px;
  text-align: center;
  font-size: 20px;
  line-height: 49px;
  color: #fff;
  text-indent: 0;
}

@media (max-width: 992px) {
  .project-filter select {
    display: block;
    opacity: 0;
    filter: alpha(opacity=0);
    z-index: 20;
  }
  .project-filter .select-field,
  .project-filter .input-field {
    width: 100%;
  }
  .project-filter .select-field + .select-field,
  .project-filter .input-field {
    margin: 17px 0 0;
  }
  .project-filter .select {
    display: none !important;
  }
  .project-filter label {
    margin: 0;
  }
}

.project-list {
  margin-top: 50px;
}

.project-list .load-more-container {
  margin-bottom: 54px;
  text-align: center;
}

.project-list .project-list-item {
  margin-bottom: 24px;
  min-height: 278px;
}

@media (min-width: 768px) {
  .project-list .project-list-item {
    min-height: 362px !important;
  }
}

@media (min-width: 992px) {
  .project-list .project-list-item {
    min-height: 295px;
  }
}

@media (min-width: 1200px) {
  .project-list .project-list-item {
    min-height: 320px;
  }
}

.project-list .project-list-item img {
  display: block;
  width: 100%;
  height: auto !important;
}

.project-list .project-list-item .quartier-fleck {
  position: absolute;
  top: 10px;
  left: 30px;
  font-family: $font-family-header-bold;
  color: #ffffff;
  font-size: 16px;
}

.project-list .project-list-item .project-name {
  font-family: $font-family-header-bold;
  font-size: 20px;
  margin: 7px 2px 0;
}

.project-list .project-list-item .project-usage,
.project-list .project-list-item .project-area {
  font-family: $font-family;
  font-size: 16px;
}

.project-list .project-list-item .project-usage {
  margin: 3px 2px 0;
}

.project-list .project-list-item .project-area {
  margin: 0 2px 0;
}

.project-list .project-list-item .project-image {
  position: relative;
}

.project-list .project-list-item .project-status-7,
.project-list .project-list-item .project-status-2 {
  width: 80px;
  height: 80px;
  text-align: center;
  position: absolute;
  right: 0;
  bottom: 0;
  border-style: solid;
  border-width: 0 0 80px 80px;
  border-color: transparent transparent #af1c1a transparent;
}

.project-list .project-list-item .project-status-7 i,
.project-list .project-list-item .project-status-2 i {
  font-size: 28px;
  line-height: 110px;
  color: #fff;
  margin-left: -42px;
}

.project-list .project-list-item .project-status-7 i:before,
.project-list .project-list-item .project-status-2 i:before {
  content: '\e600';
}

/***********************
	Projektdetailseite
************************/
/*
	Slider im Header
*/
.header-img-wrap {
  border: none;
  margin: 0;
}

.header-img-wrap img {
  max-width: none;
}

.header-img-wrap .slides > li {
  position: relative;
  overflow: hidden;
  /* fixes a translate bug */
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.header-img-wrap .flex-viewport,
.header-img-wrap.flexslider .slides,
.header-img-wrap.flexslider .slides > li {
  height: 100%;
  padding: 0;
}

@media (max-width: 768px) {
  header .hidden-small {
    display: none !important;
  }
}

/*
	Detailseite
*/
.project-overview a {
  color: #af1c1a;
}

.project-detail .project-headline-overall,
.project-overview .project-headline-overall {
  overflow: hidden;
}

.project-detail .tooltip,
.project-overview .tooltip {
  font-family: $font-family;
  padding: 0 8px;
  background: #f5f5f5;
  border: 6px solid #f5f5f5;
  border-radius: 6px;
  color: #000;
  text-transform: uppercase;
  display: block;
  font-size: 14px;
  white-space: nowrap;
  position: absolute;
  top: -40px;
  left: 55%;
  transform: translate(-50%, 0);
  opacity: 0;
  filter: alpha(opacity=0);
  /* transition */
  -webkit-transition: opacity 0.15s cubic-bezier(0.02, 0.01, 0.47, 1);
  -moz-transition: opacity 0.15s cubic-bezier(0.02, 0.01, 0.47, 1);
  -o-transition: opacity 0.15s cubic-bezier(0.02, 0.01, 0.47, 1);
  transition: opacity 0.15s cubic-bezier(0.02, 0.01, 0.47, 1);
}

.project-detail .tooltip:after,
.project-overview .tooltip:after {
  top: 100%;
  left: 50%;
  border: solid transparent;
  content: ' ';
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-color: rgba(245, 245, 245, 0);
  border-top-color: #f5f5f5;
  border-width: 12px;
  margin-left: -12px;
}

.project-detail *:hover > .tooltip,
.project-overview *:hover > .tooltip {
  opacity: 1;
  filter: alpha(opacity=100);
}

.project-overview h1.headline {
  font-family: $font-family-header;
  font-size: 36px;
  margin: 31px 0 4px;
  border-bottom: 1px solid #eeeeee;
  padding-bottom: 20px;
  color: #af1c1a;
}

.project-detail h1.headline .project-icons,
.project-overview h1.headline .project-icons {
  position: absolute;
  text-align: right;
  bottom: 19px;
  right: 15px;
  display: table;
}

@media (max-width: 768px) {
  .project-detail h1.headline .project-icons,
  .project-overview h1.headline .project-icons {
    position: relative;
    right: 0;
    bottom: 0;
    margin-top: 20px;
  }
}

.project-detail h1.headline .project-icons:before,
.project-overview h1.headline .project-icons:before,
.project-detail h1.headline .project-icons:after,
.project-overview h1.headline .project-icons:after {
  content: ' ';
  display: table;
}

.project-detail h1.headline .project-icons:after,
.project-overview h1.headline .project-icons:after {
  clear: both;
}

.project-detail h1.headline .project-icons > *,
.project-overview h1.headline .project-icons > * {
  display: table-cell;
  vertical-align: bottom;
  padding-left: 30px;
}

.project-detail h1.headline .project-status-7,
.project-overview h1.headline .project-status-7 {
  /* display: none; */
}

.project-detail h1.headline .project-status-7 + .tooltip,
.project-overview h1.headline .project-status-7 + .tooltip {
  display: none;
}

.project-detail h1.headline .project-status-7,
.project-overview h1.headline .project-status-7,
.project-detail h1.headline .project-status-2,
.project-overview h1.headline .project-status-2 {
  display: block;
  width: 46px;
  height: 46px;
  text-align: center;
  border-style: solid;
  border-width: 23px;
  border-color: #af1c1a;
  border-radius: 50%;
  position: relative;
  cursor: default;
}

.project-detail h1.headline .project-status-7 i,
.project-overview h1.headline .project-status-7 i,
.project-detail h1.headline .project-status-2 i,
.project-overview h1.headline .project-status-2 i {
  font-size: 28px;
  line-height: 46px;
  color: #fff;
  margin-left: -40px;
}

.project-detail h1.headline .project-status-7 i:before,
.project-overview h1.headline .project-status-7 i:before,
.project-detail h1.headline .project-status-2 i:before,
.project-overview h1.headline .project-status-2 i:before {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -23px;
  margin-left: -11px;
  content: '\e600';
}

.project-detail .breadcrumb-section,
.project-overview .breadcrumb-section {
  background: none;
}

.project-detail .breadcrumb-section ul.breadcrumb,
.project-overview .breadcrumb-section ul.breadcrumb {
  background: none;
  padding: 8px 0;
}

.project-detail .col-main p,
.project-overview .col-main p {
  font-size: 18px;
  line-height: 27px;
}

.project-detail .col-main p.rtetitle1,
.project-overview .col-main p.rtetitle1,
.project-detail .col-main p.rtetitle2,
.project-overview .col-main p.rtetitle2,
.project-detail .col-main p.rtetitle3,
.project-overview .col-main p.rtetitle3 {
  margin-top: 20px;
  margin-bottom: 10px;
  font-family: $font-family-header;
  font-weight: 500;
  line-height: 1.1;
  color: #333333;
}

.project-detail .col-main p.rtetitle1,
.project-overview .col-main p.rtetitle1 {
  font-size: 30px;
}

.project-detail .col-main p.rtetitle2,
.project-overview .col-main p.rtetitle2 {
  font-size: 24px;
}

.project-detail .col-main p.rtetitle3,
.project-overview .col-main p.rtetitle3 {
  font-size: 18px;
  color: #af1c1a;
}

.project-detail .col-main h2,
.project-overview .col-main h2,
.project-detail .col-main p.rtetitle1,
.project-overview .col-main p.rtetitle1 {
  margin: 10px 0 15px;
  line-height: 35px;
}

.project-detail .col-main .excerpt,
.project-overview .col-main .excerpt {
  margin-bottom: 50px;
}

.project-detail .col-main .expnums,
.project-overview .col-main .expnums {
  margin-bottom: 61px;
}

@media (min-width: 992px) and (max-width: 1200px) {
  .col-sidebar {
    padding-left: 40px;
  }
}

/*
	Slider im Content
*/
.body-img-wrap {
  border: none;
  padding: 0;
  margin: 50px 0 0;
}

.body-img-wrap ul,
.body-img-wrap li {
  margin: 0;
  padding: 0;
}

.body-img-wrap .flex-viewport,
.body-img-wrap.flexslider .slides,
.body-img-wrap.flexslider .slides > li {
  height: 100%;
}

.body-img-wrap .flex-direction-nav a.flex-prev:before,
.body-img-wrap .flex-direction-nav a.flex-next:before {
  content: '';
}

.body-img-wrap a.fullscreen,
.body-img-wrap i.fullscreen {
  display: block;
  position: absolute;
  top: 16px;
  right: 10px;
  z-index: 200;
  width: 36px;
  height: 36px;
  opacity: 0;
}

.body-img-wrap a.fullscreen-image {
  position: static;
  top: 0;
  right: 0;
  width: auto;
  height: auto;
  opacity: 1;
  z-index: 100;
}

.body-img-wrap .flex-direction-nav a {
  width: 20px;
  height: 36px;
}

.body-img-wrap .flex-direction-nav a.flex-prev {
  left: 30px !important;
}

.body-img-wrap .flex-direction-nav a.flex-next {
  right: 30px !important;
}

.body-img-wrap .flex-direction-nav a span {
  font-size: 36px;
  line-height: 36px;
  position: absolute;
  top: 0;
  left: 0;
  color: #ffffff;
}

.body-img-wrap:hover a.fullscreen,
.body-img-wrap:hover i.fullscreen {
  opacity: 1;
}

.body-img-wrap:hover a.fullscreen-icon,
.body-img-wrap:hover i.fullscreen-icon {
  opacity: 1;
}

.body-img-wrap .slides > li {
  position: relative;
  overflow: hidden;
  /* fixes a translate bug */
  -webkit-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
}

.project-overview .body-img-wrap {
  margin: 0 0 45px;
  height: 747px;
}

.slider-meta {
  margin: 11px 0 50px;
  color: #777777;
}

.slider-meta i {
  font-size: 11px;
  padding: 0 9px;
}

/*
	Sidebar
*/
.project-data {
  margin-bottom: 30px;
}

.project-data dl:before,
.project-data dl:after {
  content: ' ';
  display: table;
}

.project-data dl:after {
  clear: both;
}

.project-data dl dt {
  display: inline-block;
  font-family: $font-family-header-bold;
  margin-bottom: 5px;
  font-size: 16px;
  font-weight: normal;
  word-wrap: break-word;
}

.project-data dl dd {
  display: inline-block;
  margin-bottom: 18px;
  font-size: 17px;
  word-wrap: break-word;
}

@media (min-width: 992px) {
  .project-data dl dt,
  .project-data dl dd {
    display: block;
  }
}

.quarter-project {
  border-top: 1px solid #eeeeee;
  border-bottom: 1px solid #eeeeee;
  margin: 0 0 48px;
  padding: 18px 0 30px;
}

.quarter-project ul {
  padding: 0;
  list-style-type: none;
  margin: 0;
}

/*
	Google map
*/
.project-footer {
  margin: 50px 0 0;
}

.project-footer .infoBox h4 {
  font-size: 25px;
  font-family: $font-family-header;
  margin-bottom: 7px;
}

.project-footer .infoBox p {
  font-size: 20px;
  font-family: $font-family-header;
}

.uebersicht-properties-wrapper {
  padding-top: 50px;
}

.uebersicht-contact-wrapper {
  padding-top: 64px;
}

.project-back-button {
  color: #333333;
  padding-top: 15px;
  padding-left: 8px;
}

.project-back-button:hover {
  cursor: pointer;
  cursor: hand;
}

.project-back-button:before {
  font-family: FontAwesome;
  content: '\f104';
  font-size: 11px;
  position: absolute;
  left: 13px;
  top: 18px;
}

.white-color:before {
  color: #ffffff;
}

a.fullscreen .ps-icon-expand:before {
  color: #ffffff;
}

body {
  padding: 0;
  font-family: $font-family;
}

a,
button,
input {
  outline: none !important;
}

.main > * {
  margin-right: auto;
  margin-left: auto;
  padding-left: 10px;
  padding-right: 10px;
  padding-left: 15px;
  padding-right: 15px;
}

@media (min-width: 768px) {
  .main > * {
    width: 740px;
  }
}

@media (min-width: 992px) {
  .main > * {
    width: 960px;
  }
}

@media (min-width: 1200px) {
  .main > * {
    width: 1160px;
  }
}

.main > * {
  margin-bottom: 20px;
}

@media (max-width: 768px) {
  .main > *:not(.frc-tiles):not(.content-teaser) {
    padding-right: 15px !important;
  }
}

@media (max-width: 768px) {
  .main > *:not(.frc-fullwidth):not(.content-teaser) {
    padding-right: 15px !important;
  }
}

a.internal-link {
  color: inherit;
  font-size: inherit;
}

@media (min-width: 992px) and (max-width: 1200px) {
  .col-sidebar {
    padding-left: 40px;
  }
}

.btn-default,
.btn-primary,
.btn-success,
.btn-info,
.btn-warning,
.btn-danger {
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.2);
  /* box-shadow */
  -webkit-box-shadow:
    inset 0 1px 0 rgba(255, 255, 255, 0.15),
    0 1px 1px rgba(0, 0, 0, 0.075);
  -moz-box-shadow:
    inset 0 1px 0 rgba(255, 255, 255, 0.15),
    0 1px 1px rgba(0, 0, 0, 0.075);
  box-shadow:
    inset 0 1px 0 rgba(255, 255, 255, 0.15),
    0 1px 1px rgba(0, 0, 0, 0.075);
}

.btn-default:active,
.btn-primary:active,
.btn-success:active,
.btn-info:active,
.btn-warning:active,
.btn-danger:active,
.btn-default.active,
.btn-primary.active,
.btn-success.active,
.btn-info.active,
.btn-warning.active,
.btn-danger.active {
  /* box-shadow */
  -webkit-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  -moz-box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
}

.btn-default .badge,
.btn-primary .badge,
.btn-success .badge,
.btn-info .badge,
.btn-warning .badge,
.btn-danger .badge {
  text-shadow: none;
}

.btn-default {
  text-shadow: 0 1px 0 #fff;
  border-color: #ccc;
}

.btn-default:hover,
.btn-default:focus {
  background-position: 0 -15px;
}

.btn-primary:hover,
.btn-primary:focus {
  background-position: 0 -15px;
}

.btn-success:hover,
.btn-success:focus {
  background-position: 0 -15px;
}

.btn-info:hover,
.btn-info:focus {
  background-position: 0 -15px;
}

.btn-warning:hover,
.btn-warning:focus {
  background-position: 0 -15px;
}

.btn-danger:hover,
.btn-danger:focus {
  background-position: 0 -15px;
}

.thumbnail,
.img-thumbnail {
  /* box-shadow */
  -webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.075);
  -moz-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.075);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.075);
}

.dropdown-menu {
  background-clip: none;
  background-color: #ffffff;
  border: 0;
  box-shadow: none;
}

#subnav {
  display: none;
}

.divider hr {
  margin: 50px 0;
}

@media (max-width: 992px) {
  .navbar-fixed-top {
    position: fixed;
    top: 0 !important;
    width: 100%;
  }
  .touch .navbar-nav a:hover {
    color: inherit !important;
  }
}

.navbar-default {
  min-height: 137px;
  border: 0;
  background-color: #fff;
  border-color: #fff;
  /* box-shadow */
  -webkit-box-shadow: 0 -4px 3px 6px rgba(34, 34, 34, 0.1);
  -moz-box-shadow: 0 -4px 3px 6px rgba(34, 34, 34, 0.1);
  box-shadow: 0 -4px 3px 6px rgba(34, 34, 34, 0.1);
}

.navbar-default .no-touch .navbar-nav > li:hover > a .caret-new:before,
.navbar-default .navbar-nav > .open > a .caret-new:before {
  content: '\f106';
}

.navbar-header .navbar-brand {
  margin-left: 0 !important;
}

.navbar-default-microsite {
  min-height: 70px;
}

@media (min-width: 992px) {
  .navbar-default-microsite .usernavbar,
  .navbar-default-microsite .usernavbar .list-language,
  .navbar-default-microsite .corporate-site-link {
    padding-top: 4px;
  }
}

.navbar-nav {
  margin-top: 97px;
}

.navbar-nav .dropdown li a:hover {
  color: #a0a0a0;
}

@media (min-width: 992px) {
  .navbar-nav {
    float: right !important;
  }
}

.navbar-brand,
.navbar-nav > li > a {
  text-shadow: 0 1px 0 rgba(255, 255, 255, 0.25);
}

.navbar-inverse {
  background-image: -webkit-linear-gradient(top, #3c3c3c 0%, #222222 100%);
  background-image: -o-linear-gradient(top, #3c3c3c 0%, #222222 100%);
  background-image: linear-gradient(to bottom, #3c3c3c 0%, #222222 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff3c3c3c', endColorstr='#ff222222', GradientType=0);
  filter: progid:DXImageTransform.Microsoft.gradient(enabled = false);
}

.navbar-inverse .no-touch .navbar-nav > li.dropdown:hover > a .navbar-nav > .open > a,
.navbar-inverse .navbar-nav > .active > a {
  background-image: -webkit-linear-gradient(top, #080808 0%, #0f0f0f 100%);
  background-image: -o-linear-gradient(top, #080808 0%, #0f0f0f 100%);
  background-image: linear-gradient(to bottom, #080808 0%, #0f0f0f 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff080808', endColorstr='#ff0f0f0f', GradientType=0);
  /* box-shadow */
  -webkit-box-shadow: inset 0 3px 9px rgba(0, 0, 0, 0.25);
  -moz-box-shadow: inset 0 3px 9px rgba(0, 0, 0, 0.25);
  box-shadow: inset 0 3px 9px rgba(0, 0, 0, 0.25);
}

.navbar-inverse .navbar-brand,
.navbar-inverse .navbar-nav > li > a {
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
}

.navbar-static-top,
.navbar-fixed-top,
.navbar-fixed-bottom {
  border-radius: 0;
}

.navbar-fixed-top {
  position: static;
}

.navbar-min .navbar-fixed-top {
  position: fixed;
  top: 0 !important;
}

@media (max-width: 991px) {
  .navbar .navbar-nav .open .dropdown-menu > .active > a,
  .navbar .navbar-nav .open .dropdown-menu > .active > a:hover,
  .navbar .navbar-nav .open .dropdown-menu > .active > a:focus {
    color: #fff;
    background-image: -webkit-linear-gradient(top, #af1c1a 0%, #991817 100%);
    background-image: -o-linear-gradient(top, #af1c1a 0%, #991817 100%);
    background-image: linear-gradient(to bottom, #af1c1a 0%, #991817 100%);
    background-repeat: repeat-x;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffaf1c1a', endColorstr='#ff991817', GradientType=0);
  }
}

.alert-success {
  background-image: -webkit-linear-gradient(top, #dff0d8 0%, #c8e5bc 100%);
  background-image: -o-linear-gradient(top, #dff0d8 0%, #c8e5bc 100%);
  background-image: linear-gradient(to bottom, #dff0d8 0%, #c8e5bc 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffdff0d8', endColorstr='#ffc8e5bc', GradientType=0);
  border-color: #b2dba1;
}

.alert-info {
  background-image: -webkit-linear-gradient(top, #d9edf7 0%, #b9def0 100%);
  background-image: -o-linear-gradient(top, #d9edf7 0%, #b9def0 100%);
  background-image: linear-gradient(to bottom, #d9edf7 0%, #b9def0 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffd9edf7', endColorstr='#ffb9def0', GradientType=0);
  border-color: #9acfea;
}

.alert-warning {
  color: #333333;
  font-family: $font-family-header;
  font-size: 20px;
  background: none !important;
  border: 0;
  text-align: center;
  padding-bottom: 50px;
}

.alert-danger {
  background-image: -webkit-linear-gradient(top, #f2dede 0%, #e7c3c3 100%);
  background-image: -o-linear-gradient(top, #f2dede 0%, #e7c3c3 100%);
  background-image: linear-gradient(to bottom, #f2dede 0%, #e7c3c3 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#fff2dede', endColorstr='#ffe7c3c3', GradientType=0);
  border-color: #dca7a7;
}

.progress {
  background-image: -webkit-linear-gradient(top, #ebebeb 0%, #f5f5f5 100%);
  background-image: -o-linear-gradient(top, #ebebeb 0%, #f5f5f5 100%);
  background-image: linear-gradient(to bottom, #ebebeb 0%, #f5f5f5 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffebebeb', endColorstr='#fff5f5f5', GradientType=0);
}

.progress-bar {
  background-image: -webkit-linear-gradient(top, #af1c1a 0%, #831513 100%);
  background-image: -o-linear-gradient(top, #af1c1a 0%, #831513 100%);
  background-image: linear-gradient(to bottom, #af1c1a 0%, #831513 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffaf1c1a', endColorstr='#ff831513', GradientType=0);
}

.progress-bar-success {
  background-image: -webkit-linear-gradient(top, #5cb85c 0%, #449d44 100%);
  background-image: -o-linear-gradient(top, #5cb85c 0%, #449d44 100%);
  background-image: linear-gradient(to bottom, #5cb85c 0%, #449d44 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff5cb85c', endColorstr='#ff449d44', GradientType=0);
}

.progress-bar-info {
  background-image: -webkit-linear-gradient(top, #5bc0de 0%, #31b0d5 100%);
  background-image: -o-linear-gradient(top, #5bc0de 0%, #31b0d5 100%);
  background-image: linear-gradient(to bottom, #5bc0de 0%, #31b0d5 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff5bc0de', endColorstr='#ff31b0d5', GradientType=0);
}

.progress-bar-warning {
  background-image: -webkit-linear-gradient(top, #f0ad4e 0%, #ec971f 100%);
  background-image: -o-linear-gradient(top, #f0ad4e 0%, #ec971f 100%);
  background-image: linear-gradient(to bottom, #f0ad4e 0%, #ec971f 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#fff0ad4e', endColorstr='#ffec971f', GradientType=0);
}

.progress-bar-danger {
  background-image: -webkit-linear-gradient(top, #d9534f 0%, #c9302c 100%);
  background-image: -o-linear-gradient(top, #d9534f 0%, #c9302c 100%);
  background-image: linear-gradient(to bottom, #d9534f 0%, #c9302c 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffd9534f', endColorstr='#ffc9302c', GradientType=0);
}

.progress-bar-striped {
  background-image: -webkit-linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.15) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.15) 50%,
    rgba(255, 255, 255, 0.15) 75%,
    transparent 75%,
    transparent
  );
  background-image: -o-linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.15) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.15) 50%,
    rgba(255, 255, 255, 0.15) 75%,
    transparent 75%,
    transparent
  );
  background-image: linear-gradient(
    45deg,
    rgba(255, 255, 255, 0.15) 25%,
    transparent 25%,
    transparent 50%,
    rgba(255, 255, 255, 0.15) 50%,
    rgba(255, 255, 255, 0.15) 75%,
    transparent 75%,
    transparent
  );
}

.list-group {
  border-radius: 4px;
  /* box-shadow */
  -webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.075);
  -moz-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.075);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.075);
}

.list-group-item.active,
.list-group-item.active:hover,
.list-group-item.active:focus {
  text-shadow: 0 -1px 0 #831513;
  background-image: -webkit-linear-gradient(top, #af1c1a 0%, #8e1715 100%);
  background-image: -o-linear-gradient(top, #af1c1a 0%, #8e1715 100%);
  background-image: linear-gradient(to bottom, #af1c1a 0%, #8e1715 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffaf1c1a', endColorstr='#ff8e1715', GradientType=0);
  border-color: #8e1715;
}

.list-group-item.active .badge,
.list-group-item.active:hover .badge,
.list-group-item.active:focus .badge {
  text-shadow: none;
}

.panel {
  /* box-shadow */
  -webkit-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
}

.panel-heading {
  cursor: pointer;
}

.panel-default > .panel-heading {
  background-image: -webkit-linear-gradient(top, #f5f5f5 0%, #e8e8e8 100%);
  background-image: -o-linear-gradient(top, #f5f5f5 0%, #e8e8e8 100%);
  background-image: linear-gradient(to bottom, #f5f5f5 0%, #e8e8e8 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#fff5f5f5', endColorstr='#ffe8e8e8', GradientType=0);
}

.panel-primary > .panel-heading {
  background-image: -webkit-linear-gradient(top, #af1c1a 0%, #991817 100%);
  background-image: -o-linear-gradient(top, #af1c1a 0%, #991817 100%);
  background-image: linear-gradient(to bottom, #af1c1a 0%, #991817 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffaf1c1a', endColorstr='#ff991817', GradientType=0);
}

.panel-success > .panel-heading {
  background-image: -webkit-linear-gradient(top, #dff0d8 0%, #d0e9c6 100%);
  background-image: -o-linear-gradient(top, #dff0d8 0%, #d0e9c6 100%);
  background-image: linear-gradient(to bottom, #dff0d8 0%, #d0e9c6 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffdff0d8', endColorstr='#ffd0e9c6', GradientType=0);
}

.panel-info > .panel-heading {
  background-image: -webkit-linear-gradient(top, #d9edf7 0%, #c4e3f3 100%);
  background-image: -o-linear-gradient(top, #d9edf7 0%, #c4e3f3 100%);
  background-image: linear-gradient(to bottom, #d9edf7 0%, #c4e3f3 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffd9edf7', endColorstr='#ffc4e3f3', GradientType=0);
}

.panel-warning > .panel-heading {
  background-image: -webkit-linear-gradient(top, #fcf8e3 0%, #faf2cc 100%);
  background-image: -o-linear-gradient(top, #fcf8e3 0%, #faf2cc 100%);
  background-image: linear-gradient(to bottom, #fcf8e3 0%, #faf2cc 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#fffcf8e3', endColorstr='#fffaf2cc', GradientType=0);
}

.panel-danger > .panel-heading {
  background-image: -webkit-linear-gradient(top, #f2dede 0%, #ebcccc 100%);
  background-image: -o-linear-gradient(top, #f2dede 0%, #ebcccc 100%);
  background-image: linear-gradient(to bottom, #f2dede 0%, #ebcccc 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#fff2dede', endColorstr='#ffebcccc', GradientType=0);
}

.well {
  background-image: -webkit-linear-gradient(top, #e8e8e8 0%, #f5f5f5 100%);
  background-image: -o-linear-gradient(top, #e8e8e8 0%, #f5f5f5 100%);
  background-image: linear-gradient(to bottom, #e8e8e8 0%, #f5f5f5 100%);
  background-repeat: repeat-x;
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffe8e8e8', endColorstr='#fff5f5f5', GradientType=0);
  border-color: #dcdcdc;
  /* box-shadow */
  -webkit-box-shadow:
    inset 0 1px 3px rgba(0, 0, 0, 0.05),
    0 1px 0 rgba(255, 255, 255, 0.1);
  -moz-box-shadow:
    inset 0 1px 3px rgba(0, 0, 0, 0.05),
    0 1px 0 rgba(255, 255, 255, 0.1);
  box-shadow:
    inset 0 1px 3px rgba(0, 0, 0, 0.05),
    0 1px 0 rgba(255, 255, 255, 0.1);
}

/*/******************************************************************************************************/
header .hero {
  position: relative;
}

@media (max-width: 768px) {
  header + .hero .expnum {
    display: none;
  }
}

header + .hero .herowrapper {
  position: absolute;
  bottom: 0;
  width: 100%;
}

@media (max-width: 768px) {
  header .hero .hidden-small {
    display: none !important;
  }
}

header .hero .story-titel {
  display: none;
}

.hero-full {
  width: 100% !important;
  padding: 0 !important;
  margin-bottom: 0;
}

.herowrapper {
  position: absolute;
  bottom: 0;
  width: 100%;
}

.hero-unit {
  position: relative;
  background-repeat: no-repeat;
}

.hero-shader {
  height: 100%;
}

.darken-overlay {
  background-color: #000000;
}

.darken-gradient-top {
  background: linear-gradient(to bottom, #000000 0%, rgba(0, 0, 0, 0) 100%);
}

.darken-gradient-bottom {
  background: linear-gradient(to top, #000000 0%, rgba(0, 0, 0, 0) 100%);
}

.sitelogo {
  display: block;
  background: url(../Images/caimmo_logo.png);
  height: 77px;
  width: 216px;
  margin: 32px 0 0;
  padding: 0;
  text-indent: -9999px;
  background-size: 216px 77px;
  position: relative;
  z-index: 2300;
}

@media only screen and (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
  .sitelogo {
    background: url(../Images/caimmo_logo-2x.png);
    background-size: 216px 77px;
  }
}

.sitelogo-microsite {
  background: url(../Images/caimmo_logo_mobile.png) no-repeat 50% 50%;
  max-width: 150px;
  background-size: 100% auto;
  margin: 0;
  margin-left: 5px !important;
}

img.microsite-logo-ie8 {
  position: absolute;
  top: 24px;
  height: 29px;
  width: 150px;
}

.hideBackground {
  background: none !important;
}

h3.csc-firstHeader > a {
  color: #af1c1a;
}

.main ul:not(.flex-direction-nav):not(.news-list) {
  margin-left: -12px;
}

.main ul:not(.flex-direction-nav):not(.news-list) li {
  list-style: none;
  position: relative;
}

.main ul:not(.flex-direction-nav):not(.project .slides):not(.news-list) li:before {
  padding-right: 20px;
  margin-left: -27px;
  font-size: 25px;
  content: '\00B7';
  vertical-align: middle;
  padding-bottom: 10px;
  position: absolute;
  top: -6px;
  color: #af1c1a;
}

.main .story-titel {
  display: none;
}

.breadcrumb-section {
  background: none;
}

.breadcrumb-section .breadcrumb {
  background: none;
  padding-top: 20px !important;
}

.breadcrumb-section .breadcrumb a {
  color: #000000;
  font-size: 15px;
}

.breadcrumb-section .breadcrumb li::before {
  color: #000000;
  position: relative;
  top: 1px;
}

.breadcrumb-section .breadcrumb li.current .current {
  color: #af1c1a;
}

.breadcrumb-section .breadcrumb > li + li:before {
  padding: 0 0 2px 3px;
}

.fontawesome-icon {
  font-family: FontAwesome;
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 12px;
}

.usernavbar {
  background: none;
  margin: 22px 0 0;
  position: absolute;
  z-index: 2200;
  right: 0;
  left: 0;
  top: 0;
  line-height: 27px;
}

.usernavbar .list-inline li {
  margin: 0;
  padding: 0;
}

.usernavbar .list-inline li a,
.usernavbar .list-inline li span {
  font-size: 15px;
  line-height: 27px;
  white-space: nowrap;
  text-decoration: none !important;
}

.usernavbar .corporate-site-link .list-inline li a,
.usernavbar .corporate-site-link .list-inline li span {
  font-size: 16px;
  color: #000;
}

.usernavbar .user-links {
  margin: 0;
  padding: 0;
}

.usernavbar .user-links li {
  position: relative;
  border-right: 1px solid #c9c9c9;
}

.usernavbar .user-links li.parent a:hover,
.usernavbar .user-links li.parent a:focus,
.usernavbar .user-links li.parent a:active {
  text-decoration: none;
}

.usernavbar .user-links li.parent > a {
  padding-right: 50px;
}

.usernavbar .user-links li.parent > a:after {
  position: absolute;
  top: 7px;
  right: 33px;
  content: '\f107';
  font-family: FontAwesome;
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 12px;
}

.usernavbar .user-links li a {
  padding-right: 30px;
  padding-left: 30px;
}

.usernavbar .user-links li ul {
  visibility: hidden;
  opacity: 0;
  transition:
    visibility 0s linear 0.1s,
    opacity 0.1s linear;
  position: absolute;
  top: 50px;
  left: 50%;
  -webkit-transform: translate(-50%, 0);
  -ms-transform: translate(-50%, 0);
  -o-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
  padding: 15px 10px;
  background-color: #fff;
  text-align: center;
  /* box-shadow */
  -webkit-box-shadow: 0 0px 3px #dddddd;
  -moz-box-shadow: 0 0px 3px #dddddd;
  box-shadow: 0 0px 3px #dddddd;
}

.usernavbar .user-links li ul li {
  min-width: 156px;
  border: none;
}

.usernavbar .user-links li ul li a {
  line-height: 38px;
  padding: 0;
}

.usernavbar .user-links li ul li a:before {
  color: #af1c1a;
  position: absolute;
  top: 13px;
  left: -16px;
  font-family: FontAwesome;
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 12px;
}

.usernavbar .user-links li ul:after {
  content: '';
  position: absolute;
  width: 0;
  height: 0;
  margin-left: -1.5em;
  top: -4px;
  left: 58%;
  box-sizing: border-box;
  border: 5px solid black;
  border-color: transparent transparent white white;
  -webkit-transform: rotate(135deg);
  -ms-transform: rotate(135deg);
  -o-transform: rotate(135deg);
  transform: rotate(135deg);
  /* box-shadow */
  -webkit-box-shadow: -1px 1px 1px 0 #dddddd;
  -moz-box-shadow: -1px 1px 1px 0 #dddddd;
  box-shadow: -1px 1px 1px 0 #dddddd;
}

.usernavbar .user-links li.open > a:after {
  content: '\f106';
}

.usernavbar .user-links li.open ul {
  visibility: visible;
  opacity: 1;
  transition-delay: 0s;
}

.usernavbar .corporate-site-link {
  float: right;
  color: #707070;
  font-family: $font-family;
}

.usernavbar .list-language {
  margin: 0;
  padding: 0;
  margin-left: 16px;
  font-size: 0;
  margin-right: -11px;
}

.usernavbar .list-language li .deactivated {
  opacity: 0.5;
}

.usernavbar .list-language li a,
.usernavbar .list-language li .deactivated {
  padding: 0 10px;
  border-right: 1px solid #c9c9c9;
}

.usernavbar .list-language li.last a {
  border-right: none;
}

.usernavbar .list-language li.last .deactivated {
  border-right: none;
}

.usernavbar .list-language li.current a {
  opacity: 0.5;
}

.footer-section {
  border-top: none;
  background: none;
  background-color: #f2f2f2;
  padding-top: 30px;
  padding-bottom: 50px;
  margin-top: 0;
}

.footer-section .content-container {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.footer-section ul,
.footer-section .nav-stacked {
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;
  margin-right: -15px;
}

.footer-section ul a,
.footer-section .nav-stacked a {
  font-family: $font-family;
  line-height: 20px;
  color: #000;
  font-size: 14px;
  position: relative;
  display: block;
  padding: 2px 0px;
}

.footer-section ul a:hover,
.footer-section .nav-stacked a:hover {
  text-decoration: none;
  opacity: 0.6;
}

.footer-section ul > li + li,
.footer-section .nav-stacked > li + li {
  margin-top: 8px;
}

.footer-section h4.rtetitle3 {
  clear: both;
}

.follow_links:hover {
  text-decoration: none !important;
}

.footer-links a {
  color: #707070;
  font-family: $font-family;
  font-size: 15px;
  padding-bottom: 11px;
}

.footer-links a:hover {
  text-decoration: none;
  opacity: 0.5;
}

@media (max-width: 768px) {
  .footer-links li {
    padding-left: 0;
  }
}

.copyright {
  color: #707070;
  font-family: $font-family;
  font-size: 15px;
}

.bottom-footer {
  margin-top: 50px;
}

.microsite-links {
  float: right !important;
  margin-right: -15px;
}

.microsite-footer-link {
  margin-left: 20px;
}

.navbar .caret-new,
.navbar .submenu-link-arrow {
  display: inline-block;
  font-family: FontAwesome;
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.navbar .caret-new {
  width: 7px;
  height: 4px;
  margin: 3px 0 0;
  font-size: 0.8em;
  vertical-align: top;
}

.navbar .caret-new:before {
  content: '\f107';
}

.navbar ul.dropdown-menu-col li a:hover {
  text-decoration: none;
}

.navbar a.img-link:hover span {
  opacity: 0.7;
  text-decoration: none;
}

.navbar a.img-link:hover {
  text-decoration: none;
}

.navbar a.singlequicklink:hover {
  text-decoration: none;
  opacity: 0.7;
}

.usernavbar ul.user-links li a:hover {
  text-decoration: none;
  opacity: 0.5;
}

#subnav {
  display: none;
}

#subnav a:hover {
  color: #707070;
  text-decoration: none;
}

@media (min-width: 992px) {
  .navbar {
    /* border-radius */
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
  }
  .header-nav-title {
    font-family: $font-family-header;
    text-transform: uppercase;
    font-size: 16px;
    color: #000000;
  }
  .nav {
    margin-bottom: 0;
    margin-right: -15px;
  }
  .nav > li {
    padding-left: 0;
  }
  .nav > li:first-child {
    margin-left: 0;
  }
  .no-touch .nav > li:hover,
  .nav > li.dropdown.open {
    position: static;
  }
  .no-touch .nav > li.dropdown:hover > .dropdown-menu,
  .nav > li.dropdown.open > .dropdown-menu {
    display: table;
    width: 100%;
    text-align: center;
    left: 0;
    right: 0;
    /* box-shadow */
    -webkit-box-shadow: inset 0 20px 10px -23px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: inset 0 20px 10px -23px rgba(0, 0, 0, 0.3);
    box-shadow: inset 0 20px 10px -23px rgba(0, 0, 0, 0.3);
  }
  .no-touch .nav > li.dropdown:hover > .dropdown-menu:after,
  .nav > li.dropdown.open > .dropdown-menu:after {
    content: '';
    display: block;
    position: absolute;
    background-color: transparent;
    bottom: -10px;
    height: 10px;
    width: 100%;
    box-shadow: inset 0 20px 10px -23px rgba(0, 0, 0, 0.5);
  }
  .navbar-nav > li > a {
    padding-left: 35px;
    padding-bottom: 47px;
    padding-top: 8px;
    height: 30px;
  }
  li:hover > .header-nav-title {
    color: #af1c1a !important;
  }
  .navbar-nav > .open > a,
  .navbar-nav > .active > a {
    color: #af1c1a;
  }
  .dropdown-menu {
    padding: 40px 0;
    background: #fafafa;
    /* border-radius */
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    border-radius: 0;
  }
  .dropdown-menu .container {
    text-align: right;
    font-size: 0;
  }
  .dropdown-menu ul,
  .dropdown-menu li {
    list-style: none;
    margin: 0;
    padding: 0;
  }
  .dropdown-menu li {
    position: relative;
  }
  .dropdown-menu a {
    display: block;
    color: #000;
    padding: 11px 26px 10px 63px;
    font-size: 16px;
    line-height: 120%;
  }
  .dropdown-menu .submenu-link-arrow {
    position: absolute;
    top: 14px;
    left: 41px;
    width: 13px;
    height: 13px;
    font-size: 13px;
    display: block;
    color: #af1c1a;
  }
  .dropdown-menu .submenu-link-arrow:before {
    content: '\f105';
  }
  .dropdown-menu-col {
    display: inline-block;
    font-size: 17px;
    width: 28%;
    vertical-align: top;
  }
  .dropdown-menu-col:before {
    display: block;
    content: '';
    position: absolute;
    top: 40px;
    bottom: 40px;
    width: 1px;
    background: #d5d5d5;
  }
  .dropdown-menu-col:first-child:before {
    display: none;
  }
  .dropdown-menu-col.img-link {
    float: left;
    width: 21.4%;
    padding: 0;
    color: #af1c1a;
    text-align: left;
    font-weight: 700;
    font-size: 15px;
    line-height: 1.2;
    font-family: $font-family;
  }
  .dropdown-menu-col.img-link .img-holder {
    background: #f2f2f2;
    padding-bottom: 88%;
    vertical-align: middle;
    position: relative;
  }
  .dropdown-menu-col.img-link img {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    width: auto;
    display: block;
    max-height: 80%;
    /* box-shadow */
    -webkit-box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.25);
    -moz-box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.25);
    box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.25);
  }
  .dropdown-menu-col.img-link span {
    display: block;
    margin: 20px 0 0;
  }
  ul.dropdown-menu-col {
    text-align: left;
    padding: 16px 0;
  }
  .dropdown-menu .quicklinks {
    width: 15%;
    padding: 25px 0 24px 0;
  }
  .dropdown-menu .quicklinks-title {
    font-size: 20px;
    line-height: 1.2;
    font-family: $font-family-header;
    display: block;
    margin: 0 0 11px 52px;
  }
  .dropdown-menu .quicklinks a {
    font-size: 14px;
    line-height: 1.2;
    font-family: $font-family;
    color: #af1c1a;
    padding: 7px 0 7px 52px;
    max-width: 210px;
  }
  .navbar-nav .active a,
  .navbar-nav .open a,
  .navbar-nav .active a:hover,
  .navbar-nav .open a:hover,
  .navbar-nav .active a:focus,
  .navbar-nav .open a:focus {
    background-color: transparent !important;
  }
  .navbar-nav .open .header-nav-title {
    color: #af1c1a !important;
  }
  .main-content .affix-top {
    position: static;
    margin-top: 30px;
  }
  .main-content .affix {
    position: fixed !important;
    top: 120px;
    width: 210px;
  }
  #subnav {
    background: #f2f2f2;
    font-size: 0;
    text-align: center;
  }
  #subnav ul {
    margin-bottom: 0;
    padding-top: 9px;
    padding-bottom: 9px;
  }
  #subnav li {
    margin: 0;
    padding: 0;
  }
  #subnav li {
    display: inline-block;
    font-size: 16px;
    line-height: 1.2;
    font-family: $font-family;
  }
  #subnav li a {
    display: inline-block;
    padding: 6px 19px 4px;
  }
  #subnav li .current {
    color: #707070;
  }
  .navbar-min .usernavbar {
    display: none;
  }
  .navbar-min .navbar-default-microsite .usernavbar {
    display: block;
    margin-top: 12px;
  }
  .navbar-min .navbar {
    min-height: 0;
    height: 65px;
  }
  .navbar-min .navbar-nav {
    margin-top: 14px;
  }
  .navbar-min .sitelogo {
    background: url(../Images/caimmo_logo_min.png);
    height: 29px;
    width: 147px;
    margin: 17px 0 0;
    padding: 0;
    background-size: 147px 29px;
    opacity: 1 !important;
  }
}

@media (min-width: 992px) and (max-width: 1200px) {
  .dropdown-menu .quicklinks a {
    max-width: 190px;
  }
}

@media (min-width: 992px) and (-webkit-min-device-pixel-ratio: 2), (min-width: 992px) and (min-resolution: 192dpi) {
  .navbar-min .sitelogo {
    background: url(../Images/caimmo_logo_min-2x.png);
    background-size: 147px 29px;
    opacity: 1 !important;
  }
}

@media only screen and (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
  .navbar-min .sitelogo {
    background: url(../Images/caimmo_logo_min-2x.png) no-repeat 50% 50%;
    background-size: 147px 29px;
    opacity: 1;
  }
}

.visible-phone {
  display: none !important;
}

@media (max-width: 991px) {
  .visible-phone {
    display: block !important;
  }
  .usernavbar {
    margin: 0;
  }
  .usernavbar .container {
    width: auto;
  }
  .navbar-collapse.in > ul {
    margin-bottom: 125px !important;
  }
  .usernavbar .list-language {
    position: absolute;
    top: 5px;
    right: 60px;
    background: #fff;
    height: 45px;
    overflow: hidden;
  }
  .usernavbar .list-language li {
    display: block;
  }
  .usernavbar .list-language li a {
    display: block;
    border: 0;
    padding: 5px 10px;
  }
  .usernavbar .list-language .current a {
    padding: 10px;
  }
  .usernavbar .list-language .current a:after {
    display: inline-block;
    font-family: FontAwesome;
    font-style: normal;
    font-weight: normal;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    width: 7px;
    height: 4px;
    margin: 5px 0 0 5px;
    font-size: 1em;
    vertical-align: top;
    content: '\f107';
  }
  .usernavbar .list-language.open {
    top: 15px;
    height: auto;
    overflow: visible;
    /* box-shadow */
    -webkit-box-shadow: 0 4px 5px rgba(0, 0, 0, 0.1);
    -moz-box-shadow: 0 4px 5px rgba(0, 0, 0, 0.1);
    box-shadow: 0 4px 5px rgba(0, 0, 0, 0.1);
  }
  .usernavbar .list-language.open .current a {
    padding: 0 10px;
  }
  .usernavbar .list-language.open li {
    display: block !important;
  }
  .usernavbar .list-language.open .current a:after {
    content: '\f106';
  }
  .dropdown-menu-col.img-link,
  .dropdown-menu-col.quicklinks,
  .usernavbar > .container > .user-links,
  .usernavbar > .container > .corporate-site-link > .user-links,
  .usernavbar .list-language .inactive {
    display: none;
  }
  .navbar .container {
    width: auto;
  }
  .sitelogo {
    background: none;
    width: 147px;
    height: 55px;
    background-size: 147px 29px;
    margin: 0;
    font-size: 0;
    line-height: 0;
    background: url(../Images/caimmo_logo_min.png) no-repeat 50% 50%;
    position: relative;
    left: 15px;
    opacity: 1 !important;
  }
  .navbar-default {
    min-height: 55px;
  }
  .navbar-default > .container nav > ul {
    margin: 24px 0;
  }
  .navbar-default > .container ul,
  .navbar-default > .container li {
    padding: 0;
    list-style: none;
    text-align: center;
  }
  .navbar-default > .container nav a {
    background: none !important;
    font-size: 17px;
    line-height: 1.2;
    font-family: $font-family-header;
  }
  .navbar-default > .container nav > ul > li > a {
    font-family: $font-family-header-bold;
    text-transform: uppercase;
    padding: 14px 7px;
  }
  .navbar-default > .container li li a {
    display: block;
    padding: 12px 0 13px;
  }
  .navbar-default .dropdown-menu {
    padding: 2px 0;
  }
  .navbar-default .caret-new {
    position: absolute;
    margin: 2px 0 0 6px;
    font-size: 0.9em;
  }
  .navbar-default-microsite .usernavbar .corporate-site-link {
    margin: 15px 70px 0 0;
  }
  .navbar-collapse {
    border: 0;
    padding: 0;
    background: #fff;
  }
  .navbar-collapse.in,
  .navbar-collapse.collapsing {
    /* box-shadow */
    -webkit-box-shadow: inset 0 1px 1px #f4f4f4;
    -moz-box-shadow: inset 0 1px 1px #f4f4f4;
    box-shadow: inset 0 1px 1px #f4f4f4;
  }
  .navbar-toggle {
    margin: 0px -10px 0 0;
    padding: 0;
    background: none;
    border: 0;
    overflow: visible;
    height: 55px;
    width: 55px;
    background: none !important;
    /* box-shadow */
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    box-shadow: none !important;
    padding: 15px;
    z-index: 9999;
  }
  .navbar-toggle i,
  .navbar-toggle i:before,
  .navbar-toggle i:after {
    content: '';
    display: inline-block;
    float: left;
    background: #000;
    width: 20px;
    height: 2px;
    text-indent: -9999px;
    /* border-radius */
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    border-radius: 3px;
    margin: 0 auto;
    -webkit-transform-origin: 50% 50%;
    -o-transform-origin: 50% 50%;
    -moz-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
  }
  .navbar-toggle i:before {
    margin-top: -6px;
  }
  .navbar-toggle i:after {
    margin-top: 6px;
  }
  .navbar-toggle.in i:before {
    margin-top: 0;
    -webkit-transform: rotate(-45deg);
    -o-transform: rotate(-45deg);
    -moz-transform: rotate(-45deg);
    transform: rotate(-45deg);
  }
  .navbar-toggle.in i {
    background: none;
  }
  .navbar-toggle.in i:after {
    margin-top: -2px;
    -webkit-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    transform: rotate(45deg);
  }
}

@media only screen and (max-width: 991px) and (-webkit-min-device-pixel-ratio: 2),
  only screen and (max-width: 991px) and (min-resolution: 192dpi) {
  .sitelogo {
    background: url(../Images/caimmo_logo_min-2x.png) no-repeat 50% 50%;
    background-size: 147px 29px;
    opacity: 1 !important;
  }
}

/* ---------------- Exposed numbers -----------------*/
.expnum-title {
  font-family: $font-family-header;
  font-size: 30px;
  margin-bottom: 20px;
}

.single-expnum {
  margin-top: 20px;
}

.expnum-smalltitle {
  font-size: 16px;
  font-family: $font-family;
  margin-bottom: 5px;
}

.expnum-number-wrapper {
  display: table;
  margin-left: -3px;
}

.expnum-number-wrapper .expnum-number-big {
  font-size: 120px;
  line-height: 96px;
  letter-spacing: -12px;
}

.expnum-number-wrapper .expnum-unit-big {
  font-size: 32px;
  line-height: 32px;
}

.expnum-number-wrapper .expnum-number-medium,
.expnum-number-wrapper .expnum-number.expnum-medium {
  font-size: 60px;
  line-height: 48px;
  letter-spacing: -3px;
}

.expnum-number-wrapper .expnum-unit-medium,
.expnum-number-wrapper .expnum-unit-wrapper.expnum-medium {
  font-size: 32px;
  line-height: 32px;
}

.expnum-number-wrapper .expnum-number-small,
.expnum-number-wrapper .expnum-number.expnum-small {
  font-size: 60px;
  line-height: 48px;
  letter-spacing: -3px;
}

.expnum-number-wrapper .expnum-unit-small,
.expnum-number-wrapper .expnum-unit-wrapper.expnum-small {
  font-size: 18px;
  line-height: 18px;
}

.expnum-number {
  display: table-cell;
}

.expnum-number {
  color: #af1c1a;
  font-family: $font-family-header;
}

header + .hero .expnum-smalltitle,
header + .hero .expnum-number,
header + .hero .expnum-unit-wrapper,
header + .hero .expnum-text,
header + .hero .expnum-link {
  color: #ffffff;
}

.expnum-unit-wrapper {
  display: table-cell;
  vertical-align: bottom;
  color: #af1c1a;
  font-family: $font-family-header;
  padding-left: 10px;
}

.expnum-text {
  font-family: $font-family;
  font-size: 16px;
  line-height: 21px;
  margin-top: 20px;
}

.expnum-link-wrapper {
  position: relative;
}

.expnum-link {
  color: #af1c1a;
  margin-bottom: 20px;
  font-family: $font-family-bold;
  font-size: 17px;
}

/* ----------- File downloader -----*/
.fido-title {
  color: #af1c1a;
  font-family: $font-family-header;
  font-size: 18px;
  text-transform: uppercase;
  margin-bottom: 30px;
}

.samplefido {
  margin-bottom: 20px;
}

.fido-sampleimage {
  padding: 50px 0;
  margin-bottom: 10px;
  background-color: #f2f2f2;
}

.fido-sample-image {
  display: block;
  /* box-shadow */
  -webkit-box-shadow: 10px 10px 5px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 10px 10px 5px rgba(0, 0, 0, 0.2);
  box-shadow: 10px 10px 5px rgba(0, 0, 0, 0.2);
  margin: auto;
}

.fido-text {
  font-family: $font-family-header;
  font-size: 17px;
  line-height: 22px;
  color: #333333;
}

.fido-link {
  color: #af1c1a;
  font-family: $font-family-bold;
  font-size: 16px;
  margin-top: 5px;
}

.fido-link a {
  color: #af1c1a;
}

.fido-link:hover {
  color: #af1c1a;
  text-decoration: none;
}

.fido-noimg-wrapper {
  display: table;
  width: 100%;
  cursor: pointer;
}

.fido-noimg-text {
  display: table-cell;
  width: 80%;
  background-color: #f2f2f2;
  padding: 15px;
}

.fido-noimg-download {
  display: table-cell;
  width: 20%;
  background: #af1c1a url('../Images/download_arrow.svg') no-repeat 50% 50%;
  background-size: 40px 40px;
}

/* ------------- Special Headline ---------------*/
.special-headline-text,
header + .hero .csc-header h1 {
  font-family: $font-family-header;
  color: #af1c1a;
  font-size: 36px;
  line-height: 36px;
}

header .special-headline-text,
header + .hero .csc-header h1 {
  color: #ffffff;
  text-align: center;
}

header + .hero .csc-header h1 {
  padding-bottom: 20px;
  border-bottom: 1px solid #ffffff;
}

.container.alt-grid {
  margin-right: auto;
  margin-left: auto;
  padding-left: 10px;
  padding-right: 10px;
}

@media (min-width: 768px) {
  .container.alt-grid {
    width: 740px;
  }
}

@media (min-width: 992px) {
  .container.alt-grid {
    width: 960px;
  }
}

@media (min-width: 1200px) {
  .container.alt-grid {
    width: 1160px;
  }
}

.row.alt-grid {
  margin-left: -10px;
  margin-right: -10px;
}

.row.alt-grid .col-xs-1,
.row.alt-grid .col-sm-1,
.row.alt-grid .col-md-1,
.row.alt-grid .col-lg-1,
.row.alt-grid .col-xs-2,
.row.alt-grid .col-sm-2,
.row.alt-grid .col-md-2,
.row.alt-grid .col-lg-2,
.row.alt-grid .col-xs-3,
.row.alt-grid .col-sm-3,
.row.alt-grid .col-md-3,
.row.alt-grid .col-lg-3,
.row.alt-grid .col-xs-4,
.row.alt-grid .col-sm-4,
.row.alt-grid .col-md-4,
.row.alt-grid .col-lg-4,
.row.alt-grid .col-xs-5,
.row.alt-grid .col-sm-5,
.row.alt-grid .col-md-5,
.row.alt-grid .col-lg-5,
.row.alt-grid .col-xs-6,
.row.alt-grid .col-sm-6,
.row.alt-grid .col-md-6,
.row.alt-grid .col-lg-6,
.row.alt-grid .col-xs-7,
.row.alt-grid .col-sm-7,
.row.alt-grid .col-md-7,
.row.alt-grid .col-lg-7,
.row.alt-grid .col-xs-8,
.row.alt-grid .col-sm-8,
.row.alt-grid .col-md-8,
.row.alt-grid .col-lg-8,
.row.alt-grid .col-xs-9,
.row.alt-grid .col-sm-9,
.row.alt-grid .col-md-9,
.row.alt-grid .col-lg-9,
.row.alt-grid .col-xs-10,
.row.alt-grid .col-sm-10,
.row.alt-grid .col-md-10,
.row.alt-grid .col-lg-10,
.row.alt-grid .col-xs-11,
.row.alt-grid .col-sm-11,
.row.alt-grid .col-md-11,
.row.alt-grid .col-lg-11,
.row.alt-grid .col-xs-12,
.row.alt-grid .col-sm-12,
.row.alt-grid .col-md-12,
.row.alt-grid .col-lg-12 {
  position: relative;
  min-height: 1px;
  padding-left: 10px;
  padding-right: 10px;
}

.row.alt-grid .col-xs-1,
.col-xs-2,
.col-xs-3,
.col-xs-4,
.col-xs-5,
.col-xs-6,
.col-xs-7,
.col-xs-8,
.col-xs-9,
.col-xs-10,
.col-xs-11,
.col-xs-12 {
  float: left;
}

.row.alt-grid .col-xs-12 {
  width: 100%;
}

.row.alt-grid .col-xs-11 {
  width: 91.66666667%;
}

.row.alt-grid .col-xs-10 {
  width: 83.33333333%;
}

.row.alt-grid .col-xs-9 {
  width: 75%;
}

.row.alt-grid .col-xs-8 {
  width: 66.66666667%;
}

.row.alt-grid .col-xs-7 {
  width: 58.33333333%;
}

.row.alt-grid .col-xs-6 {
  width: 50%;
}

.row.alt-grid .col-xs-5 {
  width: 41.66666667%;
}

.row.alt-grid .col-xs-4 {
  width: 33.33333333%;
}

.row.alt-grid .col-xs-3 {
  width: 25%;
}

.row.alt-grid .col-xs-2 {
  width: 16.66666667%;
}

.row.alt-grid .col-xs-1 {
  width: 8.33333333%;
}

.row.alt-grid .col-xs-pull-12 {
  right: 100%;
}

.row.alt-grid .col-xs-pull-11 {
  right: 91.66666667%;
}

.row.alt-grid .col-xs-pull-10 {
  right: 83.33333333%;
}

.row.alt-grid .col-xs-pull-9 {
  right: 75%;
}

.row.alt-grid .col-xs-pull-8 {
  right: 66.66666667%;
}

.row.alt-grid .col-xs-pull-7 {
  right: 58.33333333%;
}

.row.alt-grid .col-xs-pull-6 {
  right: 50%;
}

.row.alt-grid .col-xs-pull-5 {
  right: 41.66666667%;
}

.row.alt-grid .col-xs-pull-4 {
  right: 33.33333333%;
}

.row.alt-grid .col-xs-pull-3 {
  right: 25%;
}

.row.alt-grid .col-xs-pull-2 {
  right: 16.66666667%;
}

.row.alt-grid .col-xs-pull-1 {
  right: 8.33333333%;
}

.row.alt-grid .col-xs-pull-0 {
  right: auto;
}

.row.alt-grid .col-xs-push-12 {
  left: 100%;
}

.row.alt-grid .col-xs-push-11 {
  left: 91.66666667%;
}

.row.alt-grid .col-xs-push-10 {
  left: 83.33333333%;
}

.row.alt-grid .col-xs-push-9 {
  left: 75%;
}

.row.alt-grid .col-xs-push-8 {
  left: 66.66666667%;
}

.row.alt-grid .col-xs-push-7 {
  left: 58.33333333%;
}

.row.alt-grid .col-xs-push-6 {
  left: 50%;
}

.row.alt-grid .col-xs-push-5 {
  left: 41.66666667%;
}

.row.alt-grid .col-xs-push-4 {
  left: 33.33333333%;
}

.row.alt-grid .col-xs-push-3 {
  left: 25%;
}

.row.alt-grid .col-xs-push-2 {
  left: 16.66666667%;
}

.row.alt-grid .col-xs-push-1 {
  left: 8.33333333%;
}

.row.alt-grid .col-xs-push-0 {
  left: auto;
}

.row.alt-grid .col-xs-offset-12 {
  margin-left: 100%;
}

.row.alt-grid .col-xs-offset-11 {
  margin-left: 91.66666667%;
}

.row.alt-grid .col-xs-offset-10 {
  margin-left: 83.33333333%;
}

.row.alt-grid .col-xs-offset-9 {
  margin-left: 75%;
}

.row.alt-grid .col-xs-offset-8 {
  margin-left: 66.66666667%;
}

.row.alt-grid .col-xs-offset-7 {
  margin-left: 58.33333333%;
}

.row.alt-grid .col-xs-offset-6 {
  margin-left: 50%;
}

.row.alt-grid .col-xs-offset-5 {
  margin-left: 41.66666667%;
}

.row.alt-grid .col-xs-offset-4 {
  margin-left: 33.33333333%;
}

.row.alt-grid .col-xs-offset-3 {
  margin-left: 25%;
}

.row.alt-grid .col-xs-offset-2 {
  margin-left: 16.66666667%;
}

.row.alt-grid .col-xs-offset-1 {
  margin-left: 8.33333333%;
}

.row.alt-grid .col-xs-offset-0 {
  margin-left: 0%;
}

@media (min-width: 768px) {
  .row.alt-grid .col-sm-1,
  .col-sm-2,
  .col-sm-3,
  .col-sm-4,
  .col-sm-5,
  .col-sm-6,
  .col-sm-7,
  .col-sm-8,
  .col-sm-9,
  .col-sm-10,
  .col-sm-11,
  .col-sm-12 {
    float: left;
  }
  .row.alt-grid .col-sm-12 {
    width: 100%;
  }
  .row.alt-grid .col-sm-11 {
    width: 91.66666667%;
  }
  .row.alt-grid .col-sm-10 {
    width: 83.33333333%;
  }
  .row.alt-grid .col-sm-9 {
    width: 75%;
  }
  .row.alt-grid .col-sm-8 {
    width: 66.66666667%;
  }
  .row.alt-grid .col-sm-7 {
    width: 58.33333333%;
  }
  .row.alt-grid .col-sm-6 {
    width: 50%;
  }
  .row.alt-grid .col-sm-5 {
    width: 41.66666667%;
  }
  .row.alt-grid .col-sm-4 {
    width: 33.33333333%;
  }
  .row.alt-grid .col-sm-3 {
    width: 25%;
  }
  .row.alt-grid .col-sm-2 {
    width: 16.66666667%;
  }
  .row.alt-grid .col-sm-1 {
    width: 8.33333333%;
  }
  .row.alt-grid .col-sm-pull-12 {
    right: 100%;
  }
  .row.alt-grid .col-sm-pull-11 {
    right: 91.66666667%;
  }
  .row.alt-grid .col-sm-pull-10 {
    right: 83.33333333%;
  }
  .row.alt-grid .col-sm-pull-9 {
    right: 75%;
  }
  .row.alt-grid .col-sm-pull-8 {
    right: 66.66666667%;
  }
  .row.alt-grid .col-sm-pull-7 {
    right: 58.33333333%;
  }
  .row.alt-grid .col-sm-pull-6 {
    right: 50%;
  }
  .row.alt-grid .col-sm-pull-5 {
    right: 41.66666667%;
  }
  .row.alt-grid .col-sm-pull-4 {
    right: 33.33333333%;
  }
  .row.alt-grid .col-sm-pull-3 {
    right: 25%;
  }
  .row.alt-grid .col-sm-pull-2 {
    right: 16.66666667%;
  }
  .row.alt-grid .col-sm-pull-1 {
    right: 8.33333333%;
  }
  .row.alt-grid .col-sm-pull-0 {
    right: auto;
  }
  .row.alt-grid .col-sm-push-12 {
    left: 100%;
  }
  .row.alt-grid .col-sm-push-11 {
    left: 91.66666667%;
  }
  .row.alt-grid .col-sm-push-10 {
    left: 83.33333333%;
  }
  .row.alt-grid .col-sm-push-9 {
    left: 75%;
  }
  .row.alt-grid .col-sm-push-8 {
    left: 66.66666667%;
  }
  .row.alt-grid .col-sm-push-7 {
    left: 58.33333333%;
  }
  .row.alt-grid .col-sm-push-6 {
    left: 50%;
  }
  .row.alt-grid .col-sm-push-5 {
    left: 41.66666667%;
  }
  .row.alt-grid .col-sm-push-4 {
    left: 33.33333333%;
  }
  .row.alt-grid .col-sm-push-3 {
    left: 25%;
  }
  .row.alt-grid .col-sm-push-2 {
    left: 16.66666667%;
  }
  .row.alt-grid .col-sm-push-1 {
    left: 8.33333333%;
  }
  .row.alt-grid .col-sm-push-0 {
    left: auto;
  }
  .row.alt-grid .col-sm-offset-12 {
    margin-left: 100%;
  }
  .row.alt-grid .col-sm-offset-11 {
    margin-left: 91.66666667%;
  }
  .row.alt-grid .col-sm-offset-10 {
    margin-left: 83.33333333%;
  }
  .row.alt-grid .col-sm-offset-9 {
    margin-left: 75%;
  }
  .row.alt-grid .col-sm-offset-8 {
    margin-left: 66.66666667%;
  }
  .row.alt-grid .col-sm-offset-7 {
    margin-left: 58.33333333%;
  }
  .row.alt-grid .col-sm-offset-6 {
    margin-left: 50%;
  }
  .row.alt-grid .col-sm-offset-5 {
    margin-left: 41.66666667%;
  }
  .row.alt-grid .col-sm-offset-4 {
    margin-left: 33.33333333%;
  }
  .row.alt-grid .col-sm-offset-3 {
    margin-left: 25%;
  }
  .row.alt-grid .col-sm-offset-2 {
    margin-left: 16.66666667%;
  }
  .row.alt-grid .col-sm-offset-1 {
    margin-left: 8.33333333%;
  }
  .row.alt-grid .col-sm-offset-0 {
    margin-left: 0%;
  }
}

@media (min-width: 992px) {
  .row.alt-grid .col-md-1,
  .col-md-2,
  .col-md-3,
  .col-md-4,
  .col-md-5,
  .col-md-6,
  .col-md-7,
  .col-md-8,
  .col-md-9,
  .col-md-10,
  .col-md-11,
  .col-md-12 {
    float: left;
  }
  .row.alt-grid .col-md-12 {
    width: 100%;
  }
  .row.alt-grid .col-md-11 {
    width: 91.66666667%;
  }
  .row.alt-grid .col-md-10 {
    width: 83.33333333%;
  }
  .row.alt-grid .col-md-9 {
    width: 75%;
  }
  .row.alt-grid .col-md-8 {
    width: 66.66666667%;
  }
  .row.alt-grid .col-md-7 {
    width: 58.33333333%;
  }
  .row.alt-grid .col-md-6 {
    width: 50%;
  }
  .row.alt-grid .col-md-5 {
    width: 41.66666667%;
  }
  .row.alt-grid .col-md-4 {
    width: 33.33333333%;
  }
  .row.alt-grid .col-md-3 {
    width: 25%;
  }
  .row.alt-grid .col-md-2 {
    width: 16.66666667%;
  }
  .row.alt-grid .col-md-1 {
    width: 8.33333333%;
  }
  .row.alt-grid .col-md-pull-12 {
    right: 100%;
  }
  .row.alt-grid .col-md-pull-11 {
    right: 91.66666667%;
  }
  .row.alt-grid .col-md-pull-10 {
    right: 83.33333333%;
  }
  .row.alt-grid .col-md-pull-9 {
    right: 75%;
  }
  .row.alt-grid .col-md-pull-8 {
    right: 66.66666667%;
  }
  .row.alt-grid .col-md-pull-7 {
    right: 58.33333333%;
  }
  .row.alt-grid .col-md-pull-6 {
    right: 50%;
  }
  .row.alt-grid .col-md-pull-5 {
    right: 41.66666667%;
  }
  .row.alt-grid .col-md-pull-4 {
    right: 33.33333333%;
  }
  .row.alt-grid .col-md-pull-3 {
    right: 25%;
  }
  .row.alt-grid .col-md-pull-2 {
    right: 16.66666667%;
  }
  .row.alt-grid .col-md-pull-1 {
    right: 8.33333333%;
  }
  .row.alt-grid .col-md-pull-0 {
    right: auto;
  }
  .row.alt-grid .col-md-push-12 {
    left: 100%;
  }
  .row.alt-grid .col-md-push-11 {
    left: 91.66666667%;
  }
  .row.alt-grid .col-md-push-10 {
    left: 83.33333333%;
  }
  .row.alt-grid .col-md-push-9 {
    left: 75%;
  }
  .row.alt-grid .col-md-push-8 {
    left: 66.66666667%;
  }
  .row.alt-grid .col-md-push-7 {
    left: 58.33333333%;
  }
  .row.alt-grid .col-md-push-6 {
    left: 50%;
  }
  .row.alt-grid .col-md-push-5 {
    left: 41.66666667%;
  }
  .row.alt-grid .col-md-push-4 {
    left: 33.33333333%;
  }
  .row.alt-grid .col-md-push-3 {
    left: 25%;
  }
  .row.alt-grid .col-md-push-2 {
    left: 16.66666667%;
  }
  .row.alt-grid .col-md-push-1 {
    left: 8.33333333%;
  }
  .row.alt-grid .col-md-push-0 {
    left: auto;
  }
  .row.alt-grid .col-md-offset-12 {
    margin-left: 100%;
  }
  .row.alt-grid .col-md-offset-11 {
    margin-left: 91.66666667%;
  }
  .row.alt-grid .col-md-offset-10 {
    margin-left: 83.33333333%;
  }
  .row.alt-grid .col-md-offset-9 {
    margin-left: 75%;
  }
  .row.alt-grid .col-md-offset-8 {
    margin-left: 66.66666667%;
  }
  .row.alt-grid .col-md-offset-7 {
    margin-left: 58.33333333%;
  }
  .row.alt-grid .col-md-offset-6 {
    margin-left: 50%;
  }
  .row.alt-grid .col-md-offset-5 {
    margin-left: 41.66666667%;
  }
  .row.alt-grid .col-md-offset-4 {
    margin-left: 33.33333333%;
  }
  .row.alt-grid .col-md-offset-3 {
    margin-left: 25%;
  }
  .row.alt-grid .col-md-offset-2 {
    margin-left: 16.66666667%;
  }
  .row.alt-grid .col-md-offset-1 {
    margin-left: 8.33333333%;
  }
  .row.alt-grid .col-md-offset-0 {
    margin-left: 0%;
  }
}

@media (min-width: 1200px) {
  .row.alt-grid .col-lg-1,
  .col-lg-2,
  .col-lg-3,
  .col-lg-4,
  .col-lg-5,
  .col-lg-6,
  .col-lg-7,
  .col-lg-8,
  .col-lg-9,
  .col-lg-10,
  .col-lg-11,
  .col-lg-12 {
    float: left;
  }
  .row.alt-grid .col-lg-12 {
    width: 100%;
  }
  .row.alt-grid .col-lg-11 {
    width: 91.66666667%;
  }
  .row.alt-grid .col-lg-10 {
    width: 83.33333333%;
  }
  .row.alt-grid .col-lg-9 {
    width: 75%;
  }
  .row.alt-grid .col-lg-8 {
    width: 66.66666667%;
  }
  .row.alt-grid .col-lg-7 {
    width: 58.33333333%;
  }
  .row.alt-grid .col-lg-6 {
    width: 50%;
  }
  .row.alt-grid .col-lg-5 {
    width: 41.66666667%;
  }
  .row.alt-grid .col-lg-4 {
    width: 33.33333333%;
  }
  .row.alt-grid .col-lg-3 {
    width: 25%;
  }
  .row.alt-grid .col-lg-2 {
    width: 16.66666667%;
  }
  .row.alt-grid .col-lg-1 {
    width: 8.33333333%;
  }
  .row.alt-grid .col-lg-pull-12 {
    right: 100%;
  }
  .row.alt-grid .col-lg-pull-11 {
    right: 91.66666667%;
  }
  .row.alt-grid .col-lg-pull-10 {
    right: 83.33333333%;
  }
  .row.alt-grid .col-lg-pull-9 {
    right: 75%;
  }
  .row.alt-grid .col-lg-pull-8 {
    right: 66.66666667%;
  }
  .row.alt-grid .col-lg-pull-7 {
    right: 58.33333333%;
  }
  .row.alt-grid .col-lg-pull-6 {
    right: 50%;
  }
  .row.alt-grid .col-lg-pull-5 {
    right: 41.66666667%;
  }
  .row.alt-grid .col-lg-pull-4 {
    right: 33.33333333%;
  }
  .row.alt-grid .col-lg-pull-3 {
    right: 25%;
  }
  .row.alt-grid .col-lg-pull-2 {
    right: 16.66666667%;
  }
  .row.alt-grid .col-lg-pull-1 {
    right: 8.33333333%;
  }
  .row.alt-grid .col-lg-pull-0 {
    right: auto;
  }
  .row.alt-grid .col-lg-push-12 {
    left: 100%;
  }
  .row.alt-grid .col-lg-push-11 {
    left: 91.66666667%;
  }
  .row.alt-grid .col-lg-push-10 {
    left: 83.33333333%;
  }
  .row.alt-grid .col-lg-push-9 {
    left: 75%;
  }
  .row.alt-grid .col-lg-push-8 {
    left: 66.66666667%;
  }
  .row.alt-grid .col-lg-push-7 {
    left: 58.33333333%;
  }
  .row.alt-grid .col-lg-push-6 {
    left: 50%;
  }
  .row.alt-grid .col-lg-push-5 {
    left: 41.66666667%;
  }
  .row.alt-grid .col-lg-push-4 {
    left: 33.33333333%;
  }
  .row.alt-grid .col-lg-push-3 {
    left: 25%;
  }
  .row.alt-grid .col-lg-push-2 {
    left: 16.66666667%;
  }
  .row.alt-grid .col-lg-push-1 {
    left: 8.33333333%;
  }
  .row.alt-grid .col-lg-push-0 {
    left: auto;
  }
  .row.alt-grid .col-lg-offset-12 {
    margin-left: 100%;
  }
  .row.alt-grid .col-lg-offset-11 {
    margin-left: 91.66666667%;
  }
  .row.alt-grid .col-lg-offset-10 {
    margin-left: 83.33333333%;
  }
  .row.alt-grid .col-lg-offset-9 {
    margin-left: 75%;
  }
  .row.alt-grid .col-lg-offset-8 {
    margin-left: 66.66666667%;
  }
  .row.alt-grid .col-lg-offset-7 {
    margin-left: 58.33333333%;
  }
  .row.alt-grid .col-lg-offset-6 {
    margin-left: 50%;
  }
  .row.alt-grid .col-lg-offset-5 {
    margin-left: 41.66666667%;
  }
  .row.alt-grid .col-lg-offset-4 {
    margin-left: 33.33333333%;
  }
  .row.alt-grid .col-lg-offset-3 {
    margin-left: 25%;
  }
  .row.alt-grid .col-lg-offset-2 {
    margin-left: 16.66666667%;
  }
  .row.alt-grid .col-lg-offset-1 {
    margin-left: 8.33333333%;
  }
  .row.alt-grid .col-lg-offset-0 {
    margin-left: 0%;
  }
}

.frc-default .content-container {
  margin-right: auto;
  margin-left: auto;
  padding-left: 10px;
  padding-right: 10px;
  width: 100%;
}

header .frc-default .content-container {
  padding-left: 15px;
  padding-right: 15px;
}

@media (min-width: 768px) {
  header .frc-default .content-container {
    width: 740px;
  }
}

@media (min-width: 992px) {
  header .frc-default .content-container {
    width: 960px;
  }
}

@media (min-width: 1200px) {
  header .frc-default .content-container {
    width: 1160px;
  }
}

.main .frc-default .content-container {
  padding-left: 0;
  padding-right: 0;
}

p strong {
  font-family: $font-family-bold;
}

/* CE TeaserTile */
.frc-tiles {
  width: 100%;
  padding: 0;
  margin-bottom: 0;
  position: relative;
}

.frc-tiles .content-container {
  width: 100%;
}

.frc-tiles .row {
  margin: 0;
}

.frc-tiles > .content-container > .row > [class*='col-'] {
  padding: 0;
}

.frc-fullwidth {
  width: 100%;
  padding: 0;
  margin-bottom: 0;
}

.frc-fullwidth .content-container {
  width: 100%;
}

.frc-fullwidth .row {
  margin: 0;
}

.frc-fullwidth [class*='col-'] {
  padding: 0;
}

.frc-default .teaser-tile {
  margin-bottom: 30px;
}

.teaser-tile {
  display: block;
  position: relative;
  text-align: center;
  overflow: hidden;
  height: 200px;
  text-decoration: none !important;
}

@media (min-width: 768px) {
  .teaser-tile {
    height: 315px;
  }
}

.frc-tiles .row > .col-xs-12 .teaser-tile {
  height: auto;
  padding-bottom: 81.25%;
}

.frc-fullwidth .row > .col-xs-12 .teaser-tile {
  height: auto;
  padding-bottom: 81.25%;
}

@media (min-width: 768px) {
  .frc-tiles .row > .col-xs-12 .teaser-tile {
    height: auto;
    padding-bottom: 39.31451613%;
  }
  .frc-fullwidth .row > .col-xs-12 .teaser-tile {
    height: auto;
    padding-bottom: 39.31451613%;
  }
  .frc-tiles .row > .col-sm-12 .teaser-tile {
    height: auto;
    padding-bottom: 39.31451613%;
  }
  .frc-fullwidth .row > .col-sm-12 .teaser-tile {
    height: auto;
    padding-bottom: 39.31451613%;
  }
}

@media (min-width: 992px) {
  .frc-tiles .row > .col-xs-12 .teaser-tile {
    height: auto;
  }
  .frc-fullwidth .row > .col-xs-12 .teaser-tile {
    height: auto;
  }
  .frc-tiles .row > .col-sm-12 .teaser-tile {
    height: auto;
  }
  .frc-fullwidth .row > .col-sm-12 .teaser-tile {
    height: auto;
  }
  .frc-tiles .row > .col-md-12 .teaser-tile {
    height: auto;
  }
  .frc-fullwidth .row > .col-md-12 .teaser-tile {
    height: auto;
  }
}

@media (min-width: 1200px) {
  .frc-tiles .row > .col-xs-12 .teaser-tile {
    height: auto;
    padding-bottom: 32.5%;
  }
  .frc-fullwidth .row > .col-xs-12 .teaser-tile {
    height: auto;
    padding-bottom: 32.5%;
  }
  .frc-tiles .row > .col-sm-12 .teaser-tile {
    height: auto;
    padding-bottom: 32.5%;
  }
  .frc-fullwidth .row > .col-sm-12 .teaser-tile {
    height: auto;
    padding-bottom: 32.5%;
  }
  .frc-tiles .row > .col-md-12 .teaser-tile {
    height: auto;
    padding-bottom: 32.5%;
  }
  .frc-fullwidth .row > .col-md-12 .teaser-tile {
    height: auto;
    padding-bottom: 32.5%;
  }
  .frc-tiles .row > .col-lg-12 .teaser-tile {
    height: auto;
    padding-bottom: 32.5%;
  }
  .frc-fullwidth .row > .col-lg-12 .teaser-tile {
    height: auto;
    padding-bottom: 32.5%;
  }
}

.frc-tiles .row > .col-xs-11 .teaser-tile {
  height: auto;
  padding-bottom: 88.63636364%;
}

.frc-fullwidth .row > .col-xs-11 .teaser-tile {
  height: auto;
  padding-bottom: 88.63636364%;
}

@media (min-width: 768px) {
  .frc-tiles .row > .col-xs-11 .teaser-tile {
    height: auto;
    padding-bottom: 42.88856305%;
  }
  .frc-fullwidth .row > .col-xs-11 .teaser-tile {
    height: auto;
    padding-bottom: 42.88856305%;
  }
  .frc-tiles .row > .col-sm-11 .teaser-tile {
    height: auto;
    padding-bottom: 42.88856305%;
  }
  .frc-fullwidth .row > .col-sm-11 .teaser-tile {
    height: auto;
    padding-bottom: 42.88856305%;
  }
}

@media (min-width: 992px) {
  .frc-tiles .row > .col-xs-11 .teaser-tile {
    height: auto;
    padding-bottom: 35.45454545%;
  }
  .frc-fullwidth .row > .col-xs-11 .teaser-tile {
    height: auto;
    padding-bottom: 35.45454545%;
  }
  .frc-tiles .row > .col-sm-11 .teaser-tile {
    height: auto;
    padding-bottom: 35.45454545%;
  }
  .frc-fullwidth .row > .col-sm-11 .teaser-tile {
    height: auto;
    padding-bottom: 35.45454545%;
  }
  .frc-tiles .row > .col-md-11 .teaser-tile {
    height: auto;
    padding-bottom: 35.45454545%;
  }
  .frc-fullwidth .row > .col-md-11 .teaser-tile {
    height: auto;
    padding-bottom: 35.45454545%;
  }
}

@media (min-width: 1200px) {
  .frc-tiles .row > .col-xs-11 .teaser-tile {
    height: auto;
    padding-bottom: 35.45454545%;
  }
  .frc-fullwidth .row > .col-xs-11 .teaser-tile {
    height: auto;
    padding-bottom: 35.45454545%;
  }
  .frc-tiles .row > .col-sm-11 .teaser-tile {
    height: auto;
    padding-bottom: 35.45454545%;
  }
  .frc-fullwidth .row > .col-sm-11 .teaser-tile {
    height: auto;
    padding-bottom: 35.45454545%;
  }
  .frc-tiles .row > .col-md-11 .teaser-tile {
    height: auto;
    padding-bottom: 35.45454545%;
  }
  .frc-fullwidth .row > .col-md-11 .teaser-tile {
    height: auto;
    padding-bottom: 35.45454545%;
  }
  .frc-tiles .row > .col-lg-11 .teaser-tile {
    height: auto;
    padding-bottom: 35.45454545%;
  }
  .frc-fullwidth .row > .col-lg-11 .teaser-tile {
    height: auto;
    padding-bottom: 35.45454545%;
  }
}

.frc-tiles .row > .col-xs-10 .teaser-tile {
  height: auto;
  padding-bottom: 97.5%;
}

.frc-fullwidth .row > .col-xs-10 .teaser-tile {
  height: auto;
  padding-bottom: 97.5%;
}

@media (min-width: 768px) {
  .frc-tiles .row > .col-xs-10 .teaser-tile {
    height: auto;
    padding-bottom: 47.17741935%;
  }
  .frc-fullwidth .row > .col-xs-10 .teaser-tile {
    height: auto;
    padding-bottom: 47.17741935%;
  }
  .frc-tiles .row > .col-sm-10 .teaser-tile {
    height: auto;
    padding-bottom: 47.17741935%;
  }
  .frc-fullwidth .row > .col-sm-10 .teaser-tile {
    height: auto;
    padding-bottom: 47.17741935%;
  }
}

@media (min-width: 992px) {
  .frc-tiles .row > .col-xs-10 .teaser-tile {
    height: auto;
    padding-bottom: 39%;
  }
  .frc-fullwidth .row > .col-xs-10 .teaser-tile {
    height: auto;
    padding-bottom: 39%;
  }
  .frc-tiles .row > .col-sm-10 .teaser-tile {
    height: auto;
    padding-bottom: 39%;
  }
  .frc-fullwidth .row > .col-sm-10 .teaser-tile {
    height: auto;
    padding-bottom: 39%;
  }
  .frc-tiles .row > .col-md-10 .teaser-tile {
    height: auto;
    padding-bottom: 39%;
  }
  .frc-fullwidth .row > .col-md-10 .teaser-tile {
    height: auto;
    padding-bottom: 39%;
  }
}

@media (min-width: 1200px) {
  .frc-tiles .row > .col-xs-10 .teaser-tile {
    height: auto;
    padding-bottom: 39%;
  }
  .frc-fullwidth .row > .col-xs-10 .teaser-tile {
    height: auto;
    padding-bottom: 39%;
  }
  .frc-tiles .row > .col-sm-10 .teaser-tile {
    height: auto;
    padding-bottom: 39%;
  }
  .frc-fullwidth .row > .col-sm-10 .teaser-tile {
    height: auto;
    padding-bottom: 39%;
  }
  .frc-tiles .row > .col-md-10 .teaser-tile {
    height: auto;
    padding-bottom: 39%;
  }
  .frc-fullwidth .row > .col-md-10 .teaser-tile {
    height: auto;
    padding-bottom: 39%;
  }
  .frc-tiles .row > .col-lg-10 .teaser-tile {
    height: auto;
    padding-bottom: 39%;
  }
  .frc-fullwidth .row > .col-lg-10 .teaser-tile {
    height: auto;
    padding-bottom: 39%;
  }
}

.frc-tiles .row > .col-xs-9 .teaser-tile {
  height: auto;
  padding-bottom: 108.33333333%;
}

.frc-fullwidth .row > .col-xs-9 .teaser-tile {
  height: auto;
  padding-bottom: 108.33333333%;
}

@media (min-width: 768px) {
  .frc-tiles .row > .col-xs-9 .teaser-tile {
    height: auto;
    padding-bottom: 52.41935484%;
  }
  .frc-fullwidth .row > .col-xs-9 .teaser-tile {
    height: auto;
    padding-bottom: 52.41935484%;
  }
  .frc-tiles .row > .col-sm-9 .teaser-tile {
    height: auto;
    padding-bottom: 52.41935484%;
  }
  .frc-fullwidth .row > .col-sm-9 .teaser-tile {
    height: auto;
    padding-bottom: 52.41935484%;
  }
}

@media (min-width: 992px) {
  .frc-tiles .row > .col-xs-9 .teaser-tile {
    height: auto;
    padding-bottom: 43.33333333%;
  }
  .frc-fullwidth .row > .col-xs-9 .teaser-tile {
    height: auto;
    padding-bottom: 43.33333333%;
  }
  .frc-tiles .row > .col-sm-9 .teaser-tile {
    height: auto;
    padding-bottom: 43.33333333%;
  }
  .frc-fullwidth .row > .col-sm-9 .teaser-tile {
    height: auto;
    padding-bottom: 43.33333333%;
  }
  .frc-tiles .row > .col-md-9 .teaser-tile {
    height: auto;
    padding-bottom: 43.33333333%;
  }
  .frc-fullwidth .row > .col-md-9 .teaser-tile {
    height: auto;
    padding-bottom: 43.33333333%;
  }
}

@media (min-width: 1200px) {
  .frc-tiles .row > .col-xs-9 .teaser-tile {
    height: auto;
    padding-bottom: 43.33333333%;
  }
  .frc-fullwidth .row > .col-xs-9 .teaser-tile {
    height: auto;
    padding-bottom: 43.33333333%;
  }
  .frc-tiles .row > .col-sm-9 .teaser-tile {
    height: auto;
    padding-bottom: 43.33333333%;
  }
  .frc-fullwidth .row > .col-sm-9 .teaser-tile {
    height: auto;
    padding-bottom: 43.33333333%;
  }
  .frc-tiles .row > .col-md-9 .teaser-tile {
    height: auto;
    padding-bottom: 43.33333333%;
  }
  .frc-fullwidth .row > .col-md-9 .teaser-tile {
    height: auto;
    padding-bottom: 43.33333333%;
  }
  .frc-tiles .row > .col-lg-9 .teaser-tile {
    height: auto;
    padding-bottom: 43.33333333%;
  }
  .frc-fullwidth .row > .col-lg-9 .teaser-tile {
    height: auto;
    padding-bottom: 43.33333333%;
  }
}

.frc-tiles .row > .col-xs-8 .teaser-tile {
  height: auto;
  padding-bottom: 121.875%;
}

.frc-fullwidth .row > .col-xs-8 .teaser-tile {
  height: auto;
  padding-bottom: 121.875%;
}

@media (min-width: 768px) {
  .frc-tiles .row > .col-xs-8 .teaser-tile {
    height: auto;
    padding-bottom: 58.97177419%;
  }
  .frc-fullwidth .row > .col-xs-8 .teaser-tile {
    height: auto;
    padding-bottom: 58.97177419%;
  }
  .frc-tiles .row > .col-sm-8 .teaser-tile {
    height: auto;
    padding-bottom: 58.97177419%;
  }
  .frc-fullwidth .row > .col-sm-8 .teaser-tile {
    height: auto;
    padding-bottom: 58.97177419%;
  }
}

@media (min-width: 992px) {
  .frc-tiles .row > .col-xs-8 .teaser-tile {
    height: auto;
    padding-bottom: 48.75%;
  }
  .frc-fullwidth .row > .col-xs-8 .teaser-tile {
    height: auto;
    padding-bottom: 48.75%;
  }
  .frc-tiles .row > .col-sm-8 .teaser-tile {
    height: auto;
    padding-bottom: 48.75%;
  }
  .frc-fullwidth .row > .col-sm-8 .teaser-tile {
    height: auto;
    padding-bottom: 48.75%;
  }
  .frc-tiles .row > .col-md-8 .teaser-tile {
    height: auto;
    padding-bottom: 48.75%;
  }
  .frc-fullwidth .row > .col-md-8 .teaser-tile {
    height: auto;
    padding-bottom: 48.75%;
  }
}

@media (min-width: 1200px) {
  .frc-tiles .row > .col-xs-8 .teaser-tile {
    height: auto;
    padding-bottom: 48.75%;
  }
  .frc-fullwidth .row > .col-xs-8 .teaser-tile {
    height: auto;
    padding-bottom: 48.75%;
  }
  .frc-tiles .row > .col-sm-8 .teaser-tile {
    height: auto;
    padding-bottom: 48.75%;
  }
  .frc-fullwidth .row > .col-sm-8 .teaser-tile {
    height: auto;
    padding-bottom: 48.75%;
  }
  .frc-tiles .row > .col-md-8 .teaser-tile {
    height: auto;
    padding-bottom: 48.75%;
  }
  .frc-fullwidth .row > .col-md-8 .teaser-tile {
    height: auto;
    padding-bottom: 48.75%;
  }
  .frc-tiles .row > .col-lg-8 .teaser-tile {
    height: auto;
    padding-bottom: 48.75%;
  }
  .frc-fullwidth .row > .col-lg-8 .teaser-tile {
    height: auto;
    padding-bottom: 48.75%;
  }
}

.frc-tiles .row > .col-xs-7 .teaser-tile {
  height: auto;
  padding-bottom: 139.28571429%;
}

.frc-fullwidth .row > .col-xs-7 .teaser-tile {
  height: auto;
  padding-bottom: 139.28571429%;
}

@media (min-width: 768px) {
  .frc-tiles .row > .col-xs-7 .teaser-tile {
    height: auto;
    padding-bottom: 67.39631335999999%;
  }
  .frc-fullwidth .row > .col-xs-7 .teaser-tile {
    height: auto;
    padding-bottom: 67.39631335999999%;
  }
  .frc-tiles .row > .col-sm-7 .teaser-tile {
    height: auto;
    padding-bottom: 67.39631335999999%;
  }
  .frc-fullwidth .row > .col-sm-7 .teaser-tile {
    height: auto;
    padding-bottom: 67.39631335999999%;
  }
}

@media (min-width: 992px) {
  .frc-tiles .row > .col-xs-7 .teaser-tile {
    height: auto;
    padding-bottom: 55.71428571%;
  }
  .frc-fullwidth .row > .col-xs-7 .teaser-tile {
    height: auto;
    padding-bottom: 55.71428571%;
  }
  .frc-tiles .row > .col-sm-7 .teaser-tile {
    height: auto;
    padding-bottom: 55.71428571%;
  }
  .frc-fullwidth .row > .col-sm-7 .teaser-tile {
    height: auto;
    padding-bottom: 55.71428571%;
  }
  .frc-tiles .row > .col-md-7 .teaser-tile {
    height: auto;
    padding-bottom: 55.71428571%;
  }
  .frc-fullwidth .row > .col-md-7 .teaser-tile {
    height: auto;
    padding-bottom: 55.71428571%;
  }
}

@media (min-width: 1200px) {
  .frc-tiles .row > .col-xs-7 .teaser-tile {
    height: auto;
    padding-bottom: 55.71428571%;
  }
  .frc-fullwidth .row > .col-xs-7 .teaser-tile {
    height: auto;
    padding-bottom: 55.71428571%;
  }
  .frc-tiles .row > .col-sm-7 .teaser-tile {
    height: auto;
    padding-bottom: 55.71428571%;
  }
  .frc-fullwidth .row > .col-sm-7 .teaser-tile {
    height: auto;
    padding-bottom: 55.71428571%;
  }
  .frc-tiles .row > .col-md-7 .teaser-tile {
    height: auto;
    padding-bottom: 55.71428571%;
  }
  .frc-fullwidth .row > .col-md-7 .teaser-tile {
    height: auto;
    padding-bottom: 55.71428571%;
  }
  .frc-tiles .row > .col-lg-7 .teaser-tile {
    height: auto;
    padding-bottom: 55.71428571%;
  }
  .frc-fullwidth .row > .col-lg-7 .teaser-tile {
    height: auto;
    padding-bottom: 55.71428571%;
  }
}

.frc-tiles .row > .col-xs-6 .teaser-tile {
  height: auto;
  padding-bottom: 162.5%;
}

.frc-fullwidth .row > .col-xs-6 .teaser-tile {
  height: auto;
  padding-bottom: 162.5%;
}

@media (min-width: 768px) {
  .frc-tiles .row > .col-xs-6 .teaser-tile {
    height: auto;
    padding-bottom: 78.62903226%;
  }
  .frc-fullwidth .row > .col-xs-6 .teaser-tile {
    height: auto;
    padding-bottom: 78.62903226%;
  }
  .frc-tiles .row > .col-sm-6 .teaser-tile {
    height: auto;
    padding-bottom: 78.62903226%;
  }
  .frc-fullwidth .row > .col-sm-6 .teaser-tile {
    height: auto;
    padding-bottom: 78.62903226%;
  }
}

@media (min-width: 992px) {
  .frc-tiles .row > .col-xs-6 .teaser-tile {
    height: auto;
    padding-bottom: 65%;
  }
  .frc-fullwidth .row > .col-xs-6 .teaser-tile {
    height: auto;
    padding-bottom: 65%;
  }
  .frc-tiles .row > .col-sm-6 .teaser-tile {
    height: auto;
    padding-bottom: 65%;
  }
  .frc-fullwidth .row > .col-sm-6 .teaser-tile {
    height: auto;
    padding-bottom: 65%;
  }
  .frc-tiles .row > .col-md-6 .teaser-tile {
    height: auto;
    padding-bottom: 65%;
  }
  .frc-fullwidth .row > .col-md-6 .teaser-tile {
    height: auto;
    padding-bottom: 65%;
  }
}

@media (min-width: 1200px) {
  .frc-tiles .row > .col-xs-6 .teaser-tile {
    height: auto;
    padding-bottom: 65%;
  }
  .frc-fullwidth .row > .col-xs-6 .teaser-tile {
    height: auto;
    padding-bottom: 65%;
  }
  .frc-tiles .row > .col-sm-6 .teaser-tile {
    height: auto;
    padding-bottom: 65%;
  }
  .frc-fullwidth .row > .col-sm-6 .teaser-tile {
    height: auto;
    padding-bottom: 65%;
  }
  .frc-tiles .row > .col-md-6 .teaser-tile {
    height: auto;
    padding-bottom: 65%;
  }
  .frc-fullwidth .row > .col-md-6 .teaser-tile {
    height: auto;
    padding-bottom: 65%;
  }
  .frc-tiles .row > .col-lg-6 .teaser-tile {
    height: auto;
    padding-bottom: 65%;
  }
  .frc-fullwidth .row > .col-lg-6 .teaser-tile {
    height: auto;
    padding-bottom: 65%;
  }
}

.frc-tiles .row > .col-xs-5 .teaser-tile {
  height: auto;
  padding-bottom: 195%;
}

.frc-fullwidth .row > .col-xs-5 .teaser-tile {
  height: auto;
  padding-bottom: 195%;
}

@media (min-width: 768px) {
  .frc-tiles .row > .col-xs-5 .teaser-tile {
    height: auto;
    padding-bottom: 94.35483871%;
  }
  .frc-fullwidth .row > .col-xs-5 .teaser-tile {
    height: auto;
    padding-bottom: 94.35483871%;
  }
  .frc-tiles .row > .col-sm-5 .teaser-tile {
    height: auto;
    padding-bottom: 94.35483871%;
  }
  .frc-fullwidth .row > .col-sm-5 .teaser-tile {
    height: auto;
    padding-bottom: 94.35483871%;
  }
}

@media (min-width: 992px) {
  .frc-tiles .row > .col-xs-5 .teaser-tile {
    height: auto;
    padding-bottom: 78%;
  }
  .frc-fullwidth .row > .col-xs-5 .teaser-tile {
    height: auto;
    padding-bottom: 78%;
  }
  .frc-tiles .row > .col-sm-5 .teaser-tile {
    height: auto;
    padding-bottom: 78%;
  }
  .frc-fullwidth .row > .col-sm-5 .teaser-tile {
    height: auto;
    padding-bottom: 78%;
  }
  .frc-tiles .row > .col-md-5 .teaser-tile {
    height: auto;
    padding-bottom: 78%;
  }
  .frc-fullwidth .row > .col-md-5 .teaser-tile {
    height: auto;
    padding-bottom: 78%;
  }
}

@media (min-width: 1200px) {
  .frc-tiles .row > .col-xs-5 .teaser-tile {
    height: auto;
    padding-bottom: 78%;
  }
  .frc-fullwidth .row > .col-xs-5 .teaser-tile {
    height: auto;
    padding-bottom: 78%;
  }
  .frc-tiles .row > .col-sm-5 .teaser-tile {
    height: auto;
    padding-bottom: 78%;
  }
  .frc-fullwidth .row > .col-sm-5 .teaser-tile {
    height: auto;
    padding-bottom: 78%;
  }
  .frc-tiles .row > .col-md-5 .teaser-tile {
    height: auto;
    padding-bottom: 78%;
  }
  .frc-fullwidth .row > .col-md-5 .teaser-tile {
    height: auto;
    padding-bottom: 78%;
  }
  .frc-tiles .row > .col-lg-5 .teaser-tile {
    height: auto;
    padding-bottom: 78%;
  }
  .frc-fullwidth .row > .col-lg-5 .teaser-tile {
    height: auto;
    padding-bottom: 78%;
  }
}

.frc-tiles .row > .col-xs-4 .teaser-tile {
  height: auto;
  padding-bottom: 243.75%;
}

.frc-fullwidth .row > .col-xs-4 .teaser-tile {
  height: auto;
  padding-bottom: 243.75%;
}

@media (min-width: 768px) {
  .frc-tiles .row > .col-xs-4 .teaser-tile {
    height: auto;
    padding-bottom: 117.94354839%;
  }
  .frc-fullwidth .row > .col-xs-4 .teaser-tile {
    height: auto;
    padding-bottom: 117.94354839%;
  }
  .frc-tiles .row > .col-sm-4 .teaser-tile {
    height: auto;
    padding-bottom: 117.94354839%;
  }
  .frc-fullwidth .row > .col-sm-4 .teaser-tile {
    height: auto;
    padding-bottom: 117.94354839%;
  }
}

@media (min-width: 992px) {
  .frc-tiles .row > .col-xs-4 .teaser-tile {
    height: auto;
    padding-bottom: 97.5%;
  }
  .frc-fullwidth .row > .col-xs-4 .teaser-tile {
    height: auto;
    padding-bottom: 97.5%;
  }
  .frc-tiles .row > .col-sm-4 .teaser-tile {
    height: auto;
    padding-bottom: 97.5%;
  }
  .frc-fullwidth .row > .col-sm-4 .teaser-tile {
    height: auto;
    padding-bottom: 97.5%;
  }
  .frc-tiles .row > .col-md-4 .teaser-tile {
    height: auto;
    padding-bottom: 97.5%;
  }
  .frc-fullwidth .row > .col-md-4 .teaser-tile {
    height: auto;
    padding-bottom: 97.5%;
  }
}

@media (min-width: 1200px) {
  .frc-tiles .row > .col-xs-4 .teaser-tile {
    height: auto;
    padding-bottom: 97.5%;
  }
  .frc-fullwidth .row > .col-xs-4 .teaser-tile {
    height: auto;
    padding-bottom: 97.5%;
  }
  .frc-tiles .row > .col-sm-4 .teaser-tile {
    height: auto;
    padding-bottom: 97.5%;
  }
  .frc-fullwidth .row > .col-sm-4 .teaser-tile {
    height: auto;
    padding-bottom: 97.5%;
  }
  .frc-tiles .row > .col-md-4 .teaser-tile {
    height: auto;
    padding-bottom: 97.5%;
  }
  .frc-fullwidth .row > .col-md-4 .teaser-tile {
    height: auto;
    padding-bottom: 97.5%;
  }
  .frc-tiles .row > .col-lg-4 .teaser-tile {
    height: auto;
    padding-bottom: 97.5%;
  }
  .frc-fullwidth .row > .col-lg-4 .teaser-tile {
    height: auto;
    padding-bottom: 97.5%;
  }
}

.frc-tiles .row > .col-xs-3 .teaser-tile {
  height: auto;
  padding-bottom: 325%;
}

.frc-fullwidth .row > .col-xs-3 .teaser-tile {
  height: auto;
  padding-bottom: 325%;
}

@media (min-width: 768px) {
  .frc-tiles .row > .col-xs-3 .teaser-tile {
    height: auto;
    padding-bottom: 157.25806452%;
  }
  .frc-fullwidth .row > .col-xs-3 .teaser-tile {
    height: auto;
    padding-bottom: 157.25806452%;
  }
  .frc-tiles .row > .col-sm-3 .teaser-tile {
    height: auto;
    padding-bottom: 157.25806452%;
  }
  .frc-fullwidth .row > .col-sm-3 .teaser-tile {
    height: auto;
    padding-bottom: 157.25806452%;
  }
}

@media (min-width: 992px) {
  .frc-tiles .row > .col-xs-3 .teaser-tile {
    height: auto;
    padding-bottom: 130%;
  }
  .frc-fullwidth .row > .col-xs-3 .teaser-tile {
    height: auto;
    padding-bottom: 130%;
  }
  .frc-tiles .row > .col-sm-3 .teaser-tile {
    height: auto;
    padding-bottom: 130%;
  }
  .frc-fullwidth .row > .col-sm-3 .teaser-tile {
    height: auto;
    padding-bottom: 130%;
  }
  .frc-tiles .row > .col-md-3 .teaser-tile {
    height: auto;
    padding-bottom: 130%;
  }
  .frc-fullwidth .row > .col-md-3 .teaser-tile {
    height: auto;
    padding-bottom: 130%;
  }
}

@media (min-width: 1200px) {
  .frc-tiles .row > .col-xs-3 .teaser-tile {
    height: auto;
    padding-bottom: 130%;
  }
  .frc-fullwidth .row > .col-xs-3 .teaser-tile {
    height: auto;
    padding-bottom: 130%;
  }
  .frc-tiles .row > .col-sm-3 .teaser-tile {
    height: auto;
    padding-bottom: 130%;
  }
  .frc-fullwidth .row > .col-sm-3 .teaser-tile {
    height: auto;
    padding-bottom: 130%;
  }
  .frc-tiles .row > .col-md-3 .teaser-tile {
    height: auto;
    padding-bottom: 130%;
  }
  .frc-fullwidth .row > .col-md-3 .teaser-tile {
    height: auto;
    padding-bottom: 130%;
  }
  .frc-tiles .row > .col-lg-3 .teaser-tile {
    height: auto;
    padding-bottom: 130%;
  }
  .frc-fullwidth .row > .col-lg-3 .teaser-tile {
    height: auto;
    padding-bottom: 130%;
  }
}

.frc-tiles .row > .col-xs-2 .teaser-tile {
  height: auto;
  padding-bottom: 487.5%;
}

.frc-fullwidth .row > .col-xs-2 .teaser-tile {
  height: auto;
  padding-bottom: 487.5%;
}

@media (min-width: 768px) {
  .frc-tiles .row > .col-xs-2 .teaser-tile {
    height: auto;
    padding-bottom: 235.88709677%;
  }
  .frc-fullwidth .row > .col-xs-2 .teaser-tile {
    height: auto;
    padding-bottom: 235.88709677%;
  }
  .frc-tiles .row > .col-sm-2 .teaser-tile {
    height: auto;
    padding-bottom: 235.88709677%;
  }
  .frc-fullwidth .row > .col-sm-2 .teaser-tile {
    height: auto;
    padding-bottom: 235.88709677%;
  }
}

@media (min-width: 992px) {
  .frc-tiles .row > .col-xs-2 .teaser-tile {
    height: auto;
    padding-bottom: 195%;
  }
  .frc-fullwidth .row > .col-xs-2 .teaser-tile {
    height: auto;
    padding-bottom: 195%;
  }
  .frc-tiles .row > .col-sm-2 .teaser-tile {
    height: auto;
    padding-bottom: 195%;
  }
  .frc-fullwidth .row > .col-sm-2 .teaser-tile {
    height: auto;
    padding-bottom: 195%;
  }
  .frc-tiles .row > .col-md-2 .teaser-tile {
    height: auto;
    padding-bottom: 195%;
  }
  .frc-fullwidth .row > .col-md-2 .teaser-tile {
    height: auto;
    padding-bottom: 195%;
  }
}

@media (min-width: 1200px) {
  .frc-tiles .row > .col-xs-2 .teaser-tile {
    height: auto;
    padding-bottom: 195%;
  }
  .frc-fullwidth .row > .col-xs-2 .teaser-tile {
    height: auto;
    padding-bottom: 195%;
  }
  .frc-tiles .row > .col-sm-2 .teaser-tile {
    height: auto;
    padding-bottom: 195%;
  }
  .frc-fullwidth .row > .col-sm-2 .teaser-tile {
    height: auto;
    padding-bottom: 195%;
  }
  .frc-tiles .row > .col-md-2 .teaser-tile {
    height: auto;
    padding-bottom: 195%;
  }
  .frc-fullwidth .row > .col-md-2 .teaser-tile {
    height: auto;
    padding-bottom: 195%;
  }
  .frc-tiles .row > .col-lg-2 .teaser-tile {
    height: auto;
    padding-bottom: 195%;
  }
  .frc-fullwidth .row > .col-lg-2 .teaser-tile {
    height: auto;
    padding-bottom: 195%;
  }
}

.frc-tiles .row > .col-xs-1 .teaser-tile {
  height: auto;
  padding-bottom: 975%;
}

.frc-fullwidth .row > .col-xs-1 .teaser-tile {
  height: auto;
  padding-bottom: 975%;
}

@media (min-width: 768px) {
  .frc-tiles .row > .col-xs-1 .teaser-tile {
    height: auto;
    padding-bottom: 471.77419355%;
  }
  .frc-fullwidth .row > .col-xs-1 .teaser-tile {
    height: auto;
    padding-bottom: 471.77419355%;
  }
  .frc-tiles .row > .col-sm-1 .teaser-tile {
    height: auto;
    padding-bottom: 471.77419355%;
  }
  .frc-fullwidth .row > .col-sm-1 .teaser-tile {
    height: auto;
    padding-bottom: 471.77419355%;
  }
}

@media (min-width: 992px) {
  .frc-tiles .row > .col-xs-1 .teaser-tile {
    height: auto;
    padding-bottom: 390%;
  }
  .frc-fullwidth .row > .col-xs-1 .teaser-tile {
    height: auto;
    padding-bottom: 390%;
  }
  .frc-tiles .row > .col-sm-1 .teaser-tile {
    height: auto;
    padding-bottom: 390%;
  }
  .frc-fullwidth .row > .col-sm-1 .teaser-tile {
    height: auto;
    padding-bottom: 390%;
  }
  .frc-tiles .row > .col-md-1 .teaser-tile {
    height: auto;
    padding-bottom: 390%;
  }
  .frc-fullwidth .row > .col-md-1 .teaser-tile {
    height: auto;
    padding-bottom: 390%;
  }
}

@media (min-width: 1200px) {
  .frc-tiles .row > .col-xs-1 .teaser-tile {
    height: auto;
    padding-bottom: 390%;
  }
  .frc-fullwidth .row > .col-xs-1 .teaser-tile {
    height: auto;
    padding-bottom: 390%;
  }
  .frc-tiles .row > .col-sm-1 .teaser-tile {
    height: auto;
    padding-bottom: 390%;
  }
  .frc-fullwidth .row > .col-sm-1 .teaser-tile {
    height: auto;
    padding-bottom: 390%;
  }
  .frc-tiles .row > .col-md-1 .teaser-tile {
    height: auto;
    padding-bottom: 390%;
  }
  .frc-fullwidth .row > .col-md-1 .teaser-tile {
    height: auto;
    padding-bottom: 390%;
  }
  .frc-tiles .row > .col-lg-1 .teaser-tile {
    height: auto;
    padding-bottom: 390%;
  }
  .frc-fullwidth .row > .col-lg-1 .teaser-tile {
    height: auto;
    padding-bottom: 390%;
  }
}

.frc-tiles .teaser-tile {
  /*height: 300px;

		@media (min-width: @screen-md-min) {
		  height: 330px;
		}

		@media (min-width: @screen-lg-min) {
		  height: 440px;
		}

		@media (min-width: @screen-xl-min) {
		  height: 485px;
		}

		@media (min-width: @screen-xxl-min) {
		  height: 520px;
		}*/
}

.teaser-tile .cover {
  background-size: cover !important;
}

.teaser-tile .contain {
  background-size: contain !important;
}

.teaser-tile .fitWidth {
  background-size: 100% auto !important;
}

.teaser-tile .fitHeight {
  background-size: auto 100% !important;
}

.teaser-tile .front,
.teaser-tile .back {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: table;
}

.teaser-tile picture img {
  position: absolute;
  top: 0;
  left: 0;
}

.teaser-tile .front {
  background-repeat: no-repeat !important;
  opacity: 100;
  filter: alpha(opacity=10000);
}

a.teaser-tile:after {
  content: '';
  display: block;
  position: absolute;
  top: 19px;
  right: 25px;
  z-index: 9;
  font-size: 27px;
  font-family: 'ps-icon';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #000;
  /* transition */
  -webkit-transition: opacity 0.25s;
  -moz-transition: opacity 0.25s;
  -o-transition: opacity 0.25s;
  transition: opacity 0.25s;
}

a.showicon.teaser-tile:after {
  content: '\e605';
}

a.teaser-tile.dark:after {
  color: #fff;
}

a.teaser-tile.nbc:after {
  display: none;
}

.teaser-tile.hover:after {
  opacity: 0;
  filter: alpha(opacity=0);
}

.teaser-tile .back {
  z-index: 10;
  opacity: 0;
  filter: alpha(opacity=0);
  /* transition */
  -webkit-transition: opacity 0.25s;
  -moz-transition: opacity 0.25s;
  -o-transition: opacity 0.25s;
  transition: opacity 0.25s;
}

.teaser-tile.hover .back {
  opacity: 100;
  filter: alpha(opacity=10000);
}

.teaser-tile .content {
  position: relative;
  color: inherit;
  padding: 0 15px;
  font-size: 18px;
  line-height: 1.2;
  font-family: $font-family-header;
  z-index: 5;
  display: table-cell;
  vertical-align: middle;
}

.teaser-tile .content-inner {
  position: relative;
  z-index: 10;
}

.teaser-tile .shadow {
  position: relative;
  text-shadow: 0 0 40px #000000;
}

.teaser-tile .shadow:before {
  content: '';
  position: absolute;
  top: -40px;
  right: -40px;
  bottom: -40px;
  left: -40px;
  z-index: 5;
}

.teaser-tile .overlay {
  position: absolute;
  top: -5px;
  right: -5px;
  bottom: -5px;
  left: -5px;
  z-index: 3;
  opacity: 0.75;
  filter: alpha(opacity=75);
}

.teaser-tile .title {
  margin: 0;
  padding: 0;
  color: inherit;
}

.teaser-tile .subtitle {
  font-family: $font-family-header;
}

.teaser-tile .text {
  font-family: $font-family;
}

.teaser-tile * + .title {
  display: block;
  margin: 9px 0 0;
}

.teaser-tile * + .subtitle {
  display: block;
  margin: 16px 0 0;
}

.teaser-tile * + .text {
  display: block;
  margin: 32px 0 0;
}

.teaser-tile .white {
  color: #fff;
}

.teaser-tile .black {
  color: #000;
}

.teaser-tile .red {
  color: #af1c1a;
}

.teaser-tile strong {
  font-family: $font-family-bold;
  font-size: 15px;
  text-align: center;
  font-weight: normal !important;
}

.teaser-tile strong:before {
  display: block;
  content: '';
  overflow: hidden;
  margin: 21px auto 23px;
  width: 25px;
  border-top: 2px solid;
}

.teaser-tile a {
  color: inherit;
}

.teaser-tile p {
  margin: 0 0 0.8em;
}

.teaser-tile p:last-child {
  margin: 0;
}

@media (min-width: 768px) {
  .teaser-tile p {
    margin: 0 0 1.2em;
  }
}

/* ------------- RTE ---------------*/
p {
  font-weight: normal !important;
  /*    span {
        font-weight: normal !important;
        font-family: $font-family-bold;
    }*/
}

strong,
b {
  font-family: $font-family-bold;
}

p.rteproximabold {
  font-family: $font-family-bold;
}

/* --------------- Simply image -----------*/
.simplyimage_wrapper {
  position: relative;
}

.simplyimage_slide {
  position: relative;
  background-repeat: no-repeat;
  background-size: contain;
}

@media (max-width: 991px) {
  .simplyimage_slide {
    margin-bottom: 30px;
  }
}

a.fullscreen,
span.fullscreen {
  font-family: 'ps-icon';
  font-size: 30px;
  display: block;
  height: 36px;
  width: 36px;
  position: absolute;
  right: 15px;
  top: 15px;
  z-index: 200;
  content: '\e603';
  color: black;
}

.simplyimage-popup {
  display: block;
}

span.fullscreen {
  color: #ffffff;
}

a.fullscreen:hover,
a.fullscreen:active,
a.fullscreen:focus {
  text-decoration: none;
}

.simplyimage-image {
  position: relative;
  width: 100%;
  height: 100%;
}

.simplyimage-subtitle,
.news-img-caption {
  position: relative;
  font-family: $font-family;
  color: #a6a6a6;
  line-height: 23px;
  font-size: 15px;
  margin-top: 10px;
  display: block;
}

/* ----------- PS Slider ---------------*/
.feature-slider-holder {
  font-family: $font-family-header;
}

@media (min-width: 768px) {
  .feature-slider-holder {
    margin-top: 0px !important;
  }
}

.feature-slider-holder .container {
  padding-top: 0;
}

.feature-slider-holder .slides > li:before {
  display: none;
}

.feature-slider-holder .slider_info {
  width: 100%;
}

.feature-slider-holder .slider_info .psslider-title {
  font-size: 50px;
  padding: 0 30px;
}

.feature-slider-holder .slider_info .psslider-textcontent {
  font-size: 18px;
  font-family: $font-family-header !important;
  text-shadow: 0 3px 7px #000;
}

.feature-slider-holder .slider_info .psslider-subtitle {
  font-size: 14px;
  text-shadow: 0 3px 7px #000;
}

.feature-slider-holder .slider_info .psslider-title {
  font-size: 15px;
  padding: 0 20px;
  text-shadow: 0 3px 7px #000;
}

@media (min-width: 768px) {
  .feature-slider-holder .slider_info .psslider-title {
    font-size: 35px;
    padding: 0 60px;
  }
}

@media (min-width: 1200px) {
  .feature-slider-holder .slider_info .psslider-title {
    font-size: 50px;
    padding: 0 30px;
  }
}

@media (min-width: 768px) {
  .feature-slider-holder .slider_info .psslider-textcontent {
    font-size: 24px;
    font-family: $font-family-header !important;
  }
  .feature-slider-holder .slider_info .psslider-subtitle {
    font-size: 32px;
  }
}

.feature-slider-holder .white {
  color: #ffffff;
  text-shadow: 0 0 20px #000;
}

.feature-slider-holder .black {
  color: #000000;
  text-shadow: 0 0 20px #fff;
}

.feature-slider-holder .red {
  color: #af1c1a;
  text-shadow: 0 0 20px #000;
}

.feature-slider-holder .flex-direction-nav a {
  font-size: 36px;
  color: #ffffff;
  line-height: 36px;
}

.flexslider {
  box-shadow: none !important;
}

.flexslider .flex-control-nav {
  display: none;
}

@media (min-width: 768px) {
  .flexslider .flex-control-nav {
    display: block;
  }
}

/** STORY PAGE STYLES **/
header .story-hero {
  margin-bottom: 60px;
}

header .story-hero .feature-slider-holder {
  display: none;
}

@media (min-width: 768px) {
  header .story-hero .feature-slider-holder {
    display: block;
  }
}

header .story-hero .feature-slider-holder .slider_info {
  width: auto;
  padding-left: 15px;
  padding-right: 15px;
  bottom: 20px;
}

@media (min-width: 1200px) {
  header .story-hero .feature-slider-holder .slider_info {
    padding-left: 115px;
    bottom: 60px;
  }
}

@media (min-width: 992px) {
  header .story-hero .feature-slider-holder .slider_info {
    bottom: 60px;
    padding-left: 115px;
  }
}

header .story-hero .feature-slider-holder .slider_info .psslider-title {
  color: #af1c1a;
  text-shadow: none;
  background: #ffffff;
  opacity: 0.9;
  font-size: 36px;
  line-height: 36px;
  padding: 30px;
  font-family: $font-family-header-bold;
}

header .story-hero .feature-slider-holder .verticalposition-top {
  top: inherit;
}

header .story-hero .feature-slider-holder .flex-control-nav,
header .story-hero .feature-slider-holder .flex-direction-nav {
  display: none;
}

header .story-hero .story-titel {
  color: #af1c1a;
  text-shadow: none;
  background: #ffffff;
  opacity: 0.9;
  font-size: 36px;
  line-height: 36px;
  padding: 30px;
  display: block;
  font-family: $font-family-header-bold;
}

@media (min-width: 768px) {
  header .story-hero .story-titel {
    display: none;
  }
}

@media (max-width: 768px) {
  header .story-hero .hidden-small {
    display: block !important;
  }
}

.story-main {
  margin-bottom: 50px;
}

.story-main .frc-default {
  margin-bottom: 50px;
}

.story-main .frc-fullwidth {
  background: #f2f2f2;
  padding-top: 60px;
  padding-bottom: 60px;
  margin-bottom: 50px;
}

.story-main .frc-fullwidth > * {
  margin-right: auto;
  margin-left: auto;
  padding-left: 10px;
  padding-right: 10px;
  padding-left: 0;
  padding-right: 0;
  padding-left: 15px;
  padding-right: 15px;
}

@media (min-width: 768px) {
  .story-main .frc-fullwidth > * {
    width: 740px;
  }
}

@media (min-width: 992px) {
  .story-main .frc-fullwidth > * {
    width: 960px;
  }
}

@media (min-width: 1200px) {
  .story-main .frc-fullwidth > * {
    width: 1160px;
  }
}

.story-main .frc-fullwidth p {
  margin: 0;
  font-size: 18px;
  line-height: 32px;
  font-family: $font-family-header;
  word-wrap: break-word;
}

@media (min-width: 768px) {
  .story-main .frc-fullwidth p {
    font-size: 28px;
    line-height: 42px;
  }
}

.story-main .frc-fullwidth h1 {
  color: #af1c1a;
  font-size: 50px;
  padding-bottom: 30px;
  margin: 0;
}

@media (min-width: 768px) {
  .story-main .frc-fullwidth h1 {
    font-size: 50px;
  }
}

.story-main > .csc-header-1 {
  padding: 0;
  margin-left: 30px;
  padding-bottom: 30px;
}

@media (min-width: 1200px) {
  .story-main > .csc-header-1 {
    width: 930px;
    margin: 0 auto;
  }
}

.story-main > .csc-header-1 h1 {
  color: #af1c1a;
  font-family: $font-family-header-bold;
  font-size: 28px;
  line-height: 37px;
}

.story-main .story-row:before {
  content: '';
  display: table;
}

.story-main .story-row:after {
  clear: both;
  content: ' ';
  display: table;
}

@media (min-width: 1200px) {
  .story-main .story-row {
    width: 990px;
    margin: 0 auto;
  }
}

.story-main .story-row .col-lg-4,
.story-main .story-row .col-md-4 {
  margin-bottom: 50px;
}

@media (min-width: 1200px) {
  .story-main .story-row .col-lg-4,
  .story-main .story-row .col-md-4 {
    padding-left: 30px;
    padding-right: 30px;
    margin-bottom: 0;
  }
}

@media (max-width: 768px) {
  .story-main .col-sm-6 {
    width: 100%;
  }
}

.story-main b {
  font-family: $font-family-header-bold;
}

@media (min-width: 1200px) {
  .story-teaser,
  .story-teaser-video {
    width: 930px !important;
    margin: 0 auto;
  }
}

.story-teaser-video {
  margin-top: 40px;
  margin-bottom: 25px;
}

.story-teaser .story-teaser-text {
  font-size: 22px;
  line-height: 32px;
  margin-bottom: 0;
  font-family: $font-family-header;
}

@media (min-width: 768px) {
  .story-teaser .story-teaser-text {
    font-size: 28px;
    line-height: 42px;
    margin-bottom: 0;
  }
}

.story-teaser .story-teaser-text::first-letter {
  color: #af1c1a;
  font-size: 105px;
  line-height: 94px;
  float: left;
  padding-right: 23px;
}

@media (min-width: 768px) {
  .story-teaser .story-teaser-text::first-letter {
    font-size: 146px;
    line-height: 116px;
  }
}

.story-teaser .high-letter::first-letter {
  color: #af1c1a;
  font-size: 105px;
  line-height: 80px;
  float: left;
  padding-right: 23px;
}

@media (min-width: 768px) {
  .story-teaser .high-letter::first-letter {
    font-size: 135px;
    line-height: 110px;
  }
}

@media (min-width: 1200px) {
  .story-teaser .story-teaser-wrapper {
    padding-left: 200px;
  }
}

.story-teaser-tile {
  display: inline-block;
}

.story-teaser-tile a {
  text-decoration: none;
}

.story-teaser-tile a:focus {
  text-decoration: none;
}

.story-teaser-tile img {
  height: 200px;
  width: 100%;
}

@media (min-width: 768px) {
  .story-teaser-tile img {
    height: inherit;
  }
}

.story-teaser-tile .story-teaser-name {
  font-family: $font-family-header-bold;
  font-size: 20px;
  margin: 7px 2px 0;
}

.story-teaser-tile .story-teaser-name:focus {
  text-decoration: none;
}

.story-teaser-tile .story-teaser-usage,
.story-teaser-tile .story-teaser-area {
  font-family: $font-family;
  font-size: 16px;
  margin: 3px 2px 0;
}

.story-teaser-tile .story-teaser-usage:focus,
.story-teaser-tile .story-teaser-area:focus {
  text-decoration: none;
}

.story-teaser-tile .story-front,
.story-teaser-tile .story-back {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: table;
}

.story-teaser-tile .story-front {
  background-repeat: no-repeat !important;
  opacity: 100;
  filter: alpha(opacity=10000);
}

.story-teaser-tile .story-back {
  z-index: 10;
  opacity: 0;
  filter: alpha(opacity=0);
  /* transition */
  -webkit-transition: opacity 0.25s;
  -moz-transition: opacity 0.25s;
  -o-transition: opacity 0.25s;
  transition: opacity 0.25s;
}

.story-teaser-tile.hover .story-back {
  opacity: 100;
  filter: alpha(opacity=10000);
}

.story-teaser-text {
  margin-top: 15px;
  margin-bottom: 15px;
}

@media (min-width: 768px) {
  .story-teaser-text {
    margin-bottom: 25px;
  }
}

.story-teaser-text p {
  font-size: 16px;
  line-height: 24px;
  margin: 0;
  padding: 0;
}

.story-teaser-author {
  font-family: $font-family;
  font-size: 13px;
}

a.showicon.story-teaser-tile:after {
  color: #fff;
  top: 10px;
  right: 10px;
}

.story-imagetext {
  font-family: $font-family-header;
  font-size: 20px;
  line-height: 32px;
  margin: 0 auto;
  margin-bottom: 50px;
}

@media (min-width: 768px) {
  .story-imagetext {
    font-size: 21px;
    line-height: 37px;
  }
}

@media (min-width: 1200px) {
  .story-imagetext {
    width: 930px;
  }
}

@media (min-width: 1200px) {
  .story-imagetext .firstPart {
    padding-left: 200px;
  }
}

@media (min-width: 1200px) {
  .story-imagetext .secondPart {
    padding-left: 200px;
  }
}

.story-imagetext .story-img {
  position: relative;
  display: block;
  margin-left: auto;
  margin-right: 0;
  margin-top: 40px;
  margin-bottom: 25px;
}

@media (min-width: 992px) {
  .story-imagetext .story-img {
    margin-top: 50px;
    margin-right: 55px;
    float: left;
  }
}

.story-imagetext .story-img.no-top-margin {
  margin-top: 12px !important;
}

.story-imagetext .story-img a.fullscreen-link {
  display: none;
}

.story-imagetext .story-img:hover a.fullscreen-link {
  display: block;
}

.story-imagetext .story-img .story-img-caption {
  text-align: left;
  font-size: 18px;
  color: #707070;
  line-height: 19px;
  margin-top: 20px;
  font-family: $font-family;
}

.story-imagetext .story-img img {
  width: 400px;
  height: auto;
}

@media (max-width: 1200px) {
  .story-imagetext .story-img img {
    width: 100%;
    height: auto;
  }
}

.story-imagetext h2 {
  font-family: $font-family-header-bold;
  font-size: 28px;
  line-height: 37px;
  margin-top: 50px;
}

@media (min-width: 1200px) {
  .story-imagetext h2 {
    padding-left: 200px;
  }
}

.story-quote {
  font-family: $font-family-header;
  font-size: 20px;
  line-height: 32px;
  margin: 0 auto;
}

@media (min-width: 768px) {
  .story-quote {
    font-size: 21px;
    line-height: 37px;
  }
}

@media (min-width: 1200px) {
  .story-quote {
    width: 930px;
  }
}

@media (min-width: 1200px) {
  .story-quote .firstPart {
    padding-left: 200px;
  }
}

@media (min-width: 1200px) {
  .story-quote .secondPart {
    padding-left: 200px;
  }
}

.story-quote h2 {
  font-family: $font-family-header-bold;
  font-size: 28px;
  line-height: 37px;
  margin-top: 50px;
}

@media (min-width: 1200px) {
  .story-quote h2 {
    padding-left: 200px;
  }
}

.story-quote .story-quote-wrapper {
  width: 100%;
  border-top: 6px solid #af1c1a;
  border-bottom: 1px solid #c9c9c9;
  float: left;
  display: block;
  margin-left: auto;
  margin-top: 35px;
  margin-bottom: 35px;
}

@media (min-width: 992px) {
  .story-quote .story-quote-wrapper {
    width: 400px;
    margin-top: 50px;
    margin-right: 55px;
  }
}

.story-quote .story-quote-wrapper.no-top-margin {
  margin-top: 12px !important;
}

.story-quote .story-quote-wrapper .story-quote-number {
  font-size: 120px;
  line-height: 106px;
  font-family: $font-family-header;
  color: #af1c1a;
  float: left;
  display: block;
  margin-top: 30px;
}

.story-quote .story-quote-wrapper .story-quote-unit {
  font-size: 40px;
  float: left;
  font-family: $font-family-header;
  color: #af1c1a;
  margin-right: 15px;
  margin-top: 90px;
  margin-bottom: 10px;
}

.story-quote .story-quote-wrapper .story-quote-text {
  font-size: 25px;
  line-height: 30px;
  color: #000;
  font-family: $font-family-bold;
  display: block;
  margin-top: 40px;
  margin-bottom: 40px;
}

.story-quote .story-quote-wrapper .story-quote-text.no-margin {
  margin-top: 40px;
  margin-bottom: 0px;
}

.story-quote .story-quote-wrapper .story-quote-text.long-num {
  margin-top: 0;
  float: left;
}

.story-quote .story-quote-wrapper .story-quote-imagewrap {
  float: left;
  margin-bottom: 20px;
  margin-top: 15px;
}

@media (min-width: 768px) {
  .story-quote .story-quote-wrapper .story-quote-imagewrap {
    margin-top: 35px;
  }
}

.story-quote .story-quote-wrapper .story-quote-img {
  width: 65px;
  float: left;
  margin-right: 13px;
  margin-bottom: 15px;
}

@media (min-width: 768px) {
  .story-quote .story-quote-wrapper .story-quote-img {
    margin-bottom: 0;
  }
}

.story-quote .story-quote-wrapper .story-quote-img img {
  height: 65px;
  width: 65px;
  float: left;
}

.story-quote .story-quote-wrapper .story-img-desc-wrap {
  float: left;
}

@media (min-width: 1200px) {
  .story-quote .story-quote-wrapper .story-img-desc-wrap {
    width: 320px;
  }
}

.story-quote .story-quote-wrapper .story-img-desc-wrap .story-quote-name {
  font-size: 14px;
  line-height: 17px;
  font-family: $font-family-bold;
  color: #707070;
}

.story-quote .story-quote-wrapper .story-img-desc-wrap .story-quote-desc {
  font-size: 14px;
  line-height: 17px;
  font-family: $font-family;
  color: #707070;
}

.story-quote-big {
  font-family: $font-family-header;
  font-size: 20px;
  line-height: 32px;
  margin: 0 auto;
}

@media (min-width: 768px) {
  .story-quote-big {
    font-size: 21px;
    line-height: 37px;
  }
}

@media (min-width: 1200px) {
  .story-quote-big {
    width: 930px;
  }
}

@media (min-width: 1200px) {
  .story-quote-big .firstPart {
    padding-left: 200px;
  }
}

@media (min-width: 1200px) {
  .story-quote-big .secondPart {
    padding-left: 200px;
  }
}

.story-quote-big h2 {
  font-family: $font-family-header-bold;
  font-size: 28px;
  line-height: 37px;
  margin-top: 50px;
}

@media (min-width: 1200px) {
  .story-quote-big h2 {
    padding-left: 200px;
  }
}

.story-quote-big .story-quote-wrapper {
  width: 100%;
  border-bottom: 1px solid #c9c9c9;
  float: left;
  display: block;
  margin-left: auto;
  margin-top: 35px;
  margin-bottom: 35px;
}

@media (min-width: 992px) {
  .story-quote-big .story-quote-wrapper {
    width: 400px;
    margin-top: 50px;
    margin-right: 55px;
  }
}

.story-quote-big .story-quote-wrapper.no-top-margin {
  margin-top: -4px !important;
}

.story-quote-big .story-quote-wrapper .story-quote-text {
  font-size: 25px;
  line-height: 30px;
  color: #000;
  font-family: $font-family-bold;
  display: inline-block;
  margin-bottom: 20px;
}

.story-quote-big .story-quote-wrapper .story-quote-text.no-margin {
  margin-bottom: 20px;
}

.story-quote-big .story-quote-wrapper .story-quote-imagewrap {
  float: left;
  margin-bottom: 20px;
  margin-top: 15px;
}

.story-quote-big .story-quote-wrapper .story-quote-img {
  width: 400px;
  float: left;
  margin-bottom: 15px;
}

@media (min-width: 768px) {
  .story-quote-big .story-quote-wrapper .story-quote-img {
    margin-bottom: 0;
  }
}

.story-quote-big .story-quote-wrapper .story-quote-img img {
  width: 400px;
  height: auto;
  float: left;
}

.story-quote-big .story-quote-wrapper .story-img-desc-wrap {
  float: left;
  margin-bottom: 20px;
}

@media (min-width: 1200px) {
  .story-quote-big .story-quote-wrapper .story-img-desc-wrap {
    width: 320px;
  }
}

.story-quote-big .story-quote-wrapper .story-img-desc-wrap .story-quote-name {
  font-size: 18px;
  line-height: 19px;
  font-family: $font-family-bold;
  color: #707070;
}

.story-quote-big .story-quote-wrapper .story-img-desc-wrap .story-quote-desc {
  font-size: 17px;
  line-height: 18px;
  font-family: $font-family;
  color: #707070;
}

.story-text-slider {
  font-family: $font-family-header;
  font-size: 20px;
  line-height: 32px;
  margin-bottom: 50px;
  margin: 0 auto;
  margin-right: 0;
}

@media (min-width: 768px) {
  .story-text-slider {
    font-size: 21px;
    line-height: 37px;
  }
}

@media (min-width: 1200px) {
  .story-text-slider {
    width: 930px;
  }
}

@media (min-width: 1200px) {
  .story-text-slider .firstPart {
    padding-left: 100px;
    padding-right: 100px;
  }
}

@media (min-width: 1200px) {
  .story-text-slider .secondPart {
    padding-left: 100px;
    padding-right: 100px;
  }
}

.story-text-slider h2 {
  font-family: $font-family-header-bold;
  font-size: 28px;
  line-height: 37px;
  margin-top: 50px;
}

@media (min-width: 1200px) {
  .story-text-slider h2 {
    padding-left: 100px;
  }
}

.story-text-slider .slider-holder {
  width: 100%;
  display: block;
  margin-right: auto;
  margin-top: 35px;
  margin-bottom: 35px;
}

@media (min-width: 992px) {
  .story-text-slider .slider-holder {
    width: 560px;
    height: 420px;
    margin-left: 55px;
    margin-top: 50px;
    margin-bottom: 50px;
    float: right;
  }
}

.story-text-slider .slider-holder.no-top-margin {
  margin-top: 12px !important;
}

.story-text-slider .slider-holder .slider-desc {
  text-align: left;
  font-size: 18px;
  color: #707070;
  line-height: 19px;
  margin-top: 20px;
  font-family: $font-family;
}

.story-text-slider .slider-holder a.fullscreen-image {
  position: static;
  top: 0;
  right: 0;
  width: auto;
  height: auto;
  opacity: 1;
  z-index: 100;
}

.story-author-box {
  border-top: 6px solid #af1c1a;
  border-bottom: 1px solid #c9c9c9;
  width: 100%;
  padding-top: 25px;
  padding-bottom: 25px;
  margin: 0;
}

@media (min-width: 1200px) {
  .story-author-box {
    position: absolute;
    bottom: 22px;
    left: 115px;
    width: 180px;
  }
}

@media (max-width: 1199px) {
  .story-author-box {
    margin-top: 30px;
  }
}

.story-author-box span {
  width: 100%;
  display: block;
  font-size: 16px;
  line-height: 25px;
  word-wrap: break-word;
}

.story-author-box span.headline {
  font-family: $font-family-header-bold;
  color: #af1c1a;
}

.story-author-box span.authorname {
  font-family: $font-family-header-bold;
}

.story-author-box span.authormail,
.story-author-box span.authordesc,
.story-author-box spanauthordate {
  font-family: $font-family-header;
}

.story-share-buttons {
  position: absolute;
  bottom: 22px;
  left: 15px;
  cursor: pointer;
}

@media (max-width: 1199px) {
  .story-share-buttons {
    position: relative;
    left: 0;
    margin-top: 25px;
  }
}

.story-share-buttons .facebook-share-button,
.story-share-buttons .twitter-share-button,
.story-share-buttons .linkedin-share-button,
.story-share-buttons .mail-share-button {
  display: block;
  height: 36px;
  width: 36px;
}

@media (max-width: 1199px) {
  .story-share-buttons .facebook-share-button,
  .story-share-buttons .twitter-share-button,
  .story-share-buttons .linkedin-share-button,
  .story-share-buttons .mail-share-button {
    display: inline-block;
  }
}

.story-share-buttons .facebook-share-button {
  background: #3b5998 url('../Images/fb-share-icon.png') no-repeat;
}

.story-share-buttons .twitter-share-button {
  background: #1da1f2 url('../Images/twitter-share-icon.png') no-repeat;
}

.story-share-buttons .linkedin-share-button {
  background: #007bb6 url('../Images/linkedin-share-icon.png') no-repeat;
}

.story-share-buttons .mail-share-button {
  background: #969696 url('../Images/mail-share-icon.png') no-repeat;
}

.story-share-buttons .facebook-share-button:hover,
.story-share-buttons .twitter-share-button:hover,
.story-share-buttons .linkedin-share-button:hover,
.story-share-buttons .mail-share-button:hover {
  opacity: 0.8;
}

.event {
  margin-bottom: 15px;
}

.event .more-link {
  font-style: italic;
  cursor: pointer;
}

.event .more-link span.closed {
  display: none;
}

.event .more-link.collapsed span.closed {
  display: block;
}

.event .more-link.collapsed span.opened {
  display: none;
}

.event .description p {
  margin: 0;
}

.event .story-share-buttons {
  margin-top: 10px;
  position: static;
  display: block;
  cursor: auto;
}

.event .facebook-share-button,
.event .story-share-buttons .twitter-share-button,
.event .story-share-buttons .linkedin-share-button,
.event .story-share-buttons .mail-share-button {
  cursor: auto;
  height: 28px;
  width: 28px;
  display: inline-block;
  background-size: contain;
  image-rendering: -webkit-optimize-contrast;
}

.event .facebook-share-button a,
.event .story-share-buttons .twitter-share-button a,
.event .story-share-buttons .linkedin-share-button a,
.event .story-share-buttons .mail-share-button a {
  cursor: pointer;
  display: inline-block;
}

.event .speaker-wrapper {
  width: 100%;
  display: inline-block;
  margin: 10px 0 5px 0;
}

.event .speaker-wrapper .speaker {
  float: left;
  margin-right: 20px;
}

.event .speaker-wrapper .speaker img {
  width: 70px;
  height: auto;
}

.story-end {
  display: inline-block;
  height: 15px;
  width: 15px;
  background: #af1c1a;
  margin-left: 10px;
}

.no-story-headline {
  margin-top: 50px;
}

/** STORY PAGE STYLES end **/
/* --------------- news ---------------*/
.news-header {
  color: #af1c1a;
}

.news {
  /*--------------------------------------*/
  /*    @media (max-width: @screen-md) {
        .news-piclib-item img{
            width: 100%;
            height: auto !important;
        }
    }*/
}

.news .header h3 {
  font-family: $font-family-header;
  font-size: 24px;
  line-height: 30px;
}

.news .footer {
  font-family: $font-family;
  font-size: 16px;
  border: 0 !important;
}

.news .footer p {
  margin-bottom: -13px;
}

.news .article {
  padding-bottom: 0;
}

.news .article .teaser-text {
  margin-top: 7px;
  font-family: $font-family-bold;
  color: #333333;
  font-size: 16px;
}

.news .article .teaser-text a {
  color: #af1c1a;
}

.news .article .news-text-wrap {
  margin-top: 30px;
  font-family: $font-family;
  color: #333333;
  font-size: 16px;
}

.news .article .news-text-wrap li {
  pointer-events: none;
}

.news .article .news-text-wrap li:hover {
  background: none !important;
  font-weight: normal !important;
  font-family: inherit !important;
}

.news .article .news-text-wrap li:before {
  display: inline;
  padding-bottom: 8px;
  margin-left: -25px;
}

.news .news-list-view {
  padding-bottom: 50px;
}

.news .news-list-view .article {
  border-bottom: 1px solid #f2f2f2;
  padding-bottom: 10px;
}

.news .news-list-view .teaser-text {
  margin-top: 7px;
  font-family: $font-family;
  color: #333333;
  font-size: 16px;
}

.news .news-list-view .teaser-text a {
  color: #af1c1a;
}

.news.news-single {
  margin-bottom: 100px;
}

.news .news-menu-view {
  background-color: #f2f2f2;
  padding: 30px 34px;
}

.news .archive-wrapper label {
  font-weight: normal !important;
}

.news .archive-wrapper label li:hover {
  font-weight: normal !important;
}

.news .archive-years {
  display: inline-block;
  background: #f2f2f2;
  position: absolute;
  border-radius: 5px;
  margin: 0;
  padding: 0;
  right: 0;
  top: -55px;
}

.news .archive-years li {
  width: 200px;
  border-radius: 5px;
  font-size: 15px;
}

.news .archive-years li a {
  font-family: $font-family-bold;
  color: #333333;
  text-decoration: none;
  display: block;
  padding: 10px 15px 10px 10px;
  text-align: right;
}

.news .archive-years li a:after {
  display: inline-block;
  content: '';
  width: 15px;
  padding-left: 55px;
  padding-right: 5px;
}

.news .archive-years li.itemactive a:after {
  display: inline-block;
  font-family: FontAwesome;
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  content: '\f107';
}

.news .archive-years li:hover {
  background: #e2e2e2;
}

.news .archive-years li:before {
  content: none;
  margin: 0;
  padding: 0;
}

.news .archive-years:hover > li:not(.itemactive) {
  display: block;
}

.news label,
.news input[type='text'] {
  display: block;
  height: 50px;
  position: relative;
  font-size: 18px;
  line-height: 50px;
  padding: 0 0 0 24px;
}

.news label {
  cursor: pointer;
}

.news label i {
  float: right;
  vertical-align: top;
  line-height: 50px;
  font-size: 14px;
  font-weight: bolder;
  margin: 0 20px 0 0;
}

.news select {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: none;
}

.news label,
.news .select,
.news .input {
  background: #fff;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
}

.news ul,
.news li {
  list-style: none;
  margin: 0;
  padding: 0;
}

.news .select {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 10;
  padding: 47px 0 19px;
  font-size: 18px;
  line-height: 1.2;
  font-family: $font-family;
  font-weight: 400;
  /* box-shadow */
  -webkit-box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
  overflow-x: hidden;
}

.news .select > ul {
  max-height: 340px;
  overflow: auto;
}

.news .selected {
  padding-right: 20px;
}

.news .category-list-li {
  color: #f2f2f2;
  padding: 3px 0px;
}

.news .category-list-li .active {
  font-family: $font-family-bold;
}

.news .category-list-li:hover {
  background: none;
}

.news .category-list-li:hover a {
  text-decoration: none !important;
}

.news .default,
.news .selected,
.news i {
  position: relative;
  z-index: 15;
}

.news .open .select {
  display: block;
}

.news .open i:before {
  content: '\f106';
}

.news input[type='text'] {
  width: 100%;
  border: 0;
  font-family: $font-family-bold;
}

.news .input {
  padding: 0 50px 0 0;
  position: relative;
  overflow: hidden;
}

.news button {
  position: absolute;
  top: 0;
  right: 0;
  width: 50px;
  height: 50px;
  background: #af1c1a;
  border: 0;
  text-indent: -9999px;
}

.news button i {
  position: absolute;
  top: 0;
  right: 0;
  display: block;
  width: 50px;
  text-align: center;
  font-size: 20px;
  line-height: 49px;
  color: #fff;
  text-indent: 0;
}

.news .page-navigation:first-child {
  display: none;
}

.news .page-navigation {
  font-family: $font-family-header;
  font-size: 24px;
  margin-top: 50px;
  display: block;
  text-align: center;
}

.news .page-navigation p {
  display: none;
}

.news .page-navigation .f3-widget-paginator {
  display: inline-block;
  height: 32px;
  margin: 0 auto;
  float: none !important;
}

.news .page-navigation .f3-widget-paginator li {
  padding: 0 7px;
}

.news .page-navigation .f3-widget-paginator li a {
  color: #a0a0a0;
  padding: 0;
}

.news .page-navigation .f3-widget-paginator li.next {
  margin-left: 10px;
}

.news .page-navigation .f3-widget-paginator li.next a,
.news .page-navigation .f3-widget-paginator li.previous a {
  color: #af1c1a;
  font-family: $font-family-header-bold;
  font-size: 20px;
  -webkit-transform: scale(0.6, 1.02);
  -moz-transform: scale(0.6, 1.02);
  -ms-transform: scale(0.6, 1.02);
  -o-transform: scale(0.6, 1.02);
  transform: scale(0.6, 1.02);
  margin-top: 2px;
  position: relative;
  left: -10px;
}

.news .page-navigation .f3-widget-paginator li.current {
  color: #af1c1a;
}

.news .page-navigation .f3-widget-paginator li:hover {
  font-family: $font-family-header;
}

.news .news-img-wrap .mediaelement-image {
  position: relative;
  padding: 4px;
  border: 1px solid #ddd;
  margin-bottom: 5px;
}

.news .news-img-wrap .mediaelement-image .piclib-popup-overlay {
  width: 100%;
  height: 100%;
  background: none !important;
  padding: 4px;
  border: none !important;
}

.news .news-img-wrap .mediaelement-image img {
  max-width: 100%;
}

@media (max-width: 992px) {
  .news select {
    display: block;
    opacity: 0;
    filter: alpha(opacity=0);
    z-index: 20;
  }
  .news .select-field {
    width: 100%;
  }
  .news .select-field + .select-field {
    margin: 17px 0 0;
  }
  .news .select {
    display: none !important;
  }
  .news label {
    margin: 0;
  }
}

/* --------- video ---------- */
.tx-parkside-video {
  padding-top: 22px;
}

/* --------- single contact ---------- */

.tx-parkside-contact .single-contact {
  background-color: #f5f5f5;
  margin: 0px 0 30px;
  padding: 28px;
}

.tx-parkside-contact .single-contact:before,
.tx-parkside-contact .single-contact:after {
  content: ' ';
  display: table;
}

.tx-parkside-contact .single-contact:after {
  clear: both;
}

.tx-parkside-contact .single-contact .col-image {
  width: 76px;
  height: 100px;
}

.tx-parkside-contact .single-contact .col-image img {
  max-width: 100%;
  max-height: 100%;
}

.tx-parkside-contact .single-contact .single-contact-image {
  float: left;
}

.tx-parkside-contact .single-contact .col-data {
  padding-left: 110px;
  font-family: $font-family;
}

.tx-parkside-contact .single-contact .col-data .name,
.tx-parkside-contact .single-contact .col-data .position {
  font-family: $font-family;
  font-size: 17px;
}

.tx-parkside-contact .single-contact .col-data .position {
  margin-bottom: 13px;
  margin-top: 2px;
  font-size: 16px;
  line-height: 21px;
  font-family: $font-family;
}

.tx-parkside-contact .single-contact .col-data .address {
  font-size: 16px;
  line-height: 24px;
  padding: 1px 0 3px 0;
}

.tx-parkside-contact .single-contact .col-data .communication .quote-text {
  padding-top: 20px;
}

.tx-parkside-contact .single-contact .col-data .communication .quote-text p:last-child {
  margin-bottom: 0;
}

.tx-parkside-contact .single-contact .no-image-col-data {
  padding-left: 0px;
}

@media (max-width: 1200px) {
  .tx-parkside-contact .single-contact .col-data {
    width: 100%;
    padding-left: 100px;
  }
}

/* --------- related link ---------- */
.tx-parkside-related-links-wrapper {
  background: #f5f5f5;
  margin: 0 0 30px;
  padding: 28px;
}
.tx-parkside-related-links-social-links {
  margin-top: 20px;

  .social__link {
    margin: 0 15px 0 0;
  }
}

/* ------------------ infobar ----------------- */
.infobar {
  margin-top: 40px;
}

.infobar .infobar-news {
  text-align: left;
}

.infobar .infobar-news .infobar-news-single {
  margin-bottom: 35px;
}

.infobar .infobar-news .infobar-news-single .infobar-news-title {
  color: #333333;
  font-family: $font-family-header;
  font-size: 20px;
  padding-top: 10px;
  line-height: 29px;
}

.infobar .infobar-news .infobar-news-single .infobar-news-more {
  margin-top: 15px;
}

@media (min-width: 1200px) {
  .infobar .infobar-graph {
    overflow: hidden;
  }
}

.infobar .infobar-graph img {
  display: inline-block;
}

.infobar .infobar-quicklinks {
  text-align: right !important;
}

.infobar .infobar-quicklinks .infobar-quicklinks-title {
  font-family: $font-family-header;
  color: #333333;
  font-size: 20px;
  padding-bottom: 10px;
}

@media (max-width: 1200px) {
  .infobar-graph {
    text-align: right;
  }
  .infobar-graph img {
    width: auto;
    max-width: 100%;
    height: auto;
    margin: 0 0 35px;
  }
}

@media (max-width: 768px) {
  .infobar .infobar-quicklinks {
    text-align: left !important;
  }
  .infobar .infobar-quicklinks-dist {
    margin-top: 40px;
  }
  .infobar-graph {
    text-align: center;
  }
  .corporate-site-link {
    display: none;
  }
}

/* ------------------ megamenu graph -------------- */
.dropdown-menu-col.megamenu-graph {
  color: #af1c1a;
  float: left;
  padding: 0 10px;
  text-align: left;
  width: 25%;
  background-color: #ffffff;
  margin-top: 30px;
}

dropdown iframe {
  height: 209px !important;
}

.megamenu-graph.dropdown-menu-col:before {
  background: none;
}

.megamenu-graph + .dropdown-menu-col:before {
  background: none;
}

@media (max-width: 1200px) {
  .dropdown-menu-col.megamenu-graph {
    width: 23.4%;
  }
}

@media (max-width: 992px) {
  .dropdown-menu-col.megamenu-graph {
    display: none;
  }
}

/* ------------------ single graph ---------------- */
.single-graph iframe {
  overflow: hidden;
}

/* ------------------ latest news ----------------- */
.latest-news .infobar-news-category {
  font-size: 16px;
  font-family: $font-family;
}

.latest-news .infobar-news-teaser {
  font-size: 16px !important;
  font-family: $font-family;
}

.latest-news .infobar-news-more-a {
  font-family: $font-family-bold;
  font-size: 16px !important;
}

.latest-news .latest-news-title {
  line-height: 30px !important;
  font-size: 24px !important;
  padding-bottom: 15px;
}

/* -------------- maps2 ------------ */
.titleWrapper {
  color: #af1c1a;
  font-family: $font-family-header;
  font-size: 26px;
  margin-bottom: 5px;
}

.addressWrapper {
  color: #333333;
  font-family: $font-family;
  font-size: 19px;
}

.gm-style-iw {
  padding: 10px;
}

.gm-style {
  color: #333333;
  font-family: $font-family;
  font-size: 19px !important;
}

/*-------------- picture library ------------------*/
.project-list .project-list-item.picture-list-item {
  min-height: 308px;
}

@media (min-width: 768px) {
  .project-list .project-list-item.picture-list-item {
    min-height: 340px !important;
  }
}

@media (min-width: 992px) {
  .project-list .project-list-item.picture-list-item {
    min-height: 295px;
  }
}

@media (min-width: 1200px) {
  .project-list .project-list-item.picture-list-item {
    min-height: 320px;
  }
}

.picturelib-h2 {
  font-family: $font-family-header !important;
}

.picturelib-name {
  font-family: $font-family;
  font-size: 17px;
  margin: 7px 0px 0;
}

.picturelib-description {
  font-family: $font-family;
  font-size: 15px;
  margin-top: 1px;
  margin-bottom: 2px;
  color: #777777;
}

.picturelib-area {
  font-family: $font-family-bold;
  font-size: 16px;
}

.picturelib-filter-select-main {
  font-family: $font-family-bold;
  margin-bottom: 2px !important;
  margin-top: 10px !important;
}

.piclib-parent-parent-title,
.piclib-parent-title {
  font-family: $font-family-header;
  color: #333333;
  font-size: 30px;
  margin-bottom: 30px;
  margin-left: 0;
  margin-right: 0;
  clear: both;
}

.piclib-parent-parent-title {
  font-weight: 400;
  font-size: 30px;
  line-height: 1.2;
  font-family: $font-family-header;
  color: #af1c1a;
}

.piclib-category-title {
  font-family: $font-family-header;
  color: #af1c1a;
  font-size: 18px;
  text-transform: uppercase;
  padding-bottom: 15px;
  padding-left: 0 !important;
  border-bottom: 1px solid #c9c9c9;
  margin-bottom: 25px;
  margin-left: 25px;
  padding: 0;
  margin-left: 10px;
  margin-right: 10px;
  float: none;
  width: auto !important;
  clear: both;
}

.project-filter .select-field div.select {
  z-index: 1000;
}

.text-with-placeholder {
  color: #333333;
}

.loadmore {
  color: #af1c1a;
  font-family: $font-family-bold;
  padding-top: 100px;
  padding-bottom: 50px;
}

.loadmore:after {
  content: '\f107';
  font-family: FontAwesome;
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 12px;
  font-weight: bold;
  margin-left: 8px;
  font-size: 13px;
}

.loadmore:hover {
  cursor: pointer;
}

.bild-archiv-list .loadmore {
  padding-bottom: 90px;
}

.piclib-filesize {
  font-size: 14px;
  font-family: $font-family;
  color: #777777;
}

a.piclib-popup-overlay {
  width: 100%;
  height: 192px;
  position: absolute;
  top: 0;
  right: 0;
}

.picturelib-img-wrapper {
  height: 192px;
  width: 270px;
  text-align: center;
}

.picturelib-img-wrapper img {
  vertical-align: middle;
  display: inline-block !important;
  max-width: 265px;
}

.picturelib-img-wrapper:before {
  content: ' ';
  display: inline-block;
  height: 100%;
  vertical-align: middle;
}

.picturelib-same-ratio {
  height: auto !important;
  width: auto !important;
  max-width: 270px;
  max-height: 192px;
  margin: auto;
}

@media (max-width: 1200px) {
  .picturelib-img-wrapper {
    text-align: left;
    height: 156px;
  }
  .picturelib-img-wrapper img {
    max-width: 220px;
  }
  .picturelib-same-ratio {
    max-height: 160px;
  }
}

@media (max-width: 992px) {
  .picturelib-img-wrapper {
    text-align: center;
    height: 240px;
  }
  .picturelib-same-ratio {
    max-height: 192px;
  }
}

/* --------------- file downloader ------------ */
.file-downloader .loader {
  text-align: center;
  padding-top: 100px;
}

.file-downloader .doc-downloader-list-item {
  text-align: left;
  margin-top: 30px;
  min-height: 400px;
}

.file-downloader .doc-downloader-image-wrap {
  background-color: #f2f2f2;
  text-align: center;
  margin-bottom: 10px;
}

.file-downloader .doc-downloader-image {
  display: inline-block;
  height: 178px;
  width: 124px;
  margin: 50px auto;
  /* box-shadow */
  -webkit-box-shadow: 10px 10px 5px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 10px 10px 5px rgba(0, 0, 0, 0.2);
  box-shadow: 10px 10px 5px rgba(0, 0, 0, 0.2);
  background: url(../Images/fileDownloader_bg.jpg);
  position: relative;
}

.file-downloader .doc-downloader-image:hover {
  cursor: pointer;
}

.file-downloader .doc-downloader-cover {
  width: 40px;
  height: 40px;
  position: absolute;
}

.file-downloader .doc-downloader-cover-1 {
  background: url(../Images/fb_pres.png);
  background-repeat: no-repeat;
  bottom: 40px;
  left: 42px;
}

.file-downloader .doc-downloader-cover-2 {
  background: url(../Images/fb_img.png);
  background-repeat: no-repeat;
  bottom: 40px;
  left: 42px;
}

.file-downloader .doc-downloader-cover-3 {
  font-family: $font-family-header;
  color: #ffffff;
  font-size: 70px;
  bottom: 50px;
  left: 10px;
  letter-spacing: -5px;
}

.file-downloader .doc-downloader-text {
  font-family: $font-family-header;
  font-size: 17px;
  line-height: 22px;
  color: #333333;
}

.file-downloader .doc-downloader-link {
  color: #af1c1a;
  font-family: $font-family-bold;
  font-size: 16px;
  margin-top: 5px;
}

.file-downloader .doc-downloader-link a {
  color: #af1c1a;
}

.file-downloader .doc-downloader-link:hover {
  color: #af1c1a;
  text-decoration: none;
}

.file-downloader .alert-warning {
  margin-top: 50px;
}

.file-downloader .project-filter {
  padding: 22px;
}

.file-downloader .project-filter h2 {
  position: relative;
  top: 13px;
  left: 15px;
}

@media (max-width: 480px) {
  .file-downloader span.default {
    display: none;
  }
}

/* -------------- loader ----------------- */
.load-more-container {
  text-align: center;
  margin-bottom: 50px;
}

.dropdown-menu-loader {
  position: absolute;
  margin: 15% 50%;
}

.single-graph-menu-loader {
  margin-left: 50%;
}

.single-graph-menu-loader img {
  width: 26px !important;
  height: 26px !important;
}

.file-downloader .loader,
.project-list .loader {
  padding-bottom: 50px;
}

.file-downloader .loader img,
.project-list .loader img {
  width: 26px !important;
  height: 26px !important;
}

.bild-archiv-list .loader {
  margin-top: 90px;
  margin-bottom: 40px;
}

/* ------------ mail order --------------- */
.mail-order-title h3 {
  margin: 30px 0;
}

.mail-order {
  background-color: #eeeeee;
  text-align: center;
}

.mail-order form {
  text-align: left;
  padding: 25px 35px;
}

.mail-order table {
  width: 100%;
}

.mail-order input,
.mail-order select,
.mail-order textarea {
  width: 100%;
  height: 45px;
  border: 0;
  margin: 10px 0;
  border-radius: 5px;
  padding-left: 15px;
  color: #a0a0a0;
}

.mail-order textarea {
  height: 100px;
  padding-top: 10px;
}

.mail-order .placeholder {
  color: #a0a0a0;
}

.mail-order select {
  width: 100%;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  height: 45px;
}

.mail-order select option {
  height: 33px;
  border: 0;
  padding-top: 8px;
}

.mail-order select:before {
  position: absolute;
  top: 7px;
  right: 33px;
  font-family: FontAwesome;
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 12px;
  content: '\f107';
}

.mail-order input[name='btn_submit'] {
  background-color: #af1c1a;
  color: #ffffff;
  text-transform: uppercase;
  font-family: $font-family-bold;
  letter-spacing: 1px;
  width: 100%;
  height: 45px;
  border: 0;
  margin: 10px 0;
  border-radius: 5px;
  padding-left: 15px;
  padding-top: 3px;
}

.mail-order .errortext {
  display: none;
  color: #af1c1a;
  font-style: italic;
}

/* ---------------- tabelle ------------------*/
.customTable {
  background-color: #f2f2f2;
}

.customTable tr:nth-child(even) {
  background: #e2e2e2;
}

.customTable td {
  padding: 10px 40px;
  border-bottom: 2px solid #fafafa;
  font-size: 16px;
  line-height: 23px;
}

@media (max-width: 1200px) {
  .customTable td {
    padding: 10px 24px;
  }
}

@media (max-width: 542px) {
  .customTable td {
    padding: 10px 14px;
  }
}

@media (max-width: 480px) {
  .customTable td {
    padding: 10px 5px;
  }
}

/* --------------- 404 error ---------------- */
.err404 {
  display: table;
  margin: 0 auto;
}

.err404 .err404-wrap {
  height: 500px;
  display: table-cell;
  vertical-align: middle;
}

.err404 .err404-wrap .err404-content {
  text-align: left;
  display: inline-block;
}

.err404 .err404-wrap .err404-content .err404-image {
  float: left;
  display: block;
  background: url(../Images/404-image.png);
  height: 101px;
  width: 101px;
  margin-right: 35px;
}

.err404 .err404-wrap .err404-content .err404-text {
  float: left;
  font-family: $font-family-header;
  color: #af1c1a;
}

.err404 .err404-wrap .err404-content .err404-text .err404-title {
  font-size: 53px;
  margin-bottom: -10px;
}

.err404 .err404-wrap .err404-content .err404-text .err404-description {
  font-size: 27px;
}

/* --------- gallery arrows ------------*/
.mfp-arrow {
  position: absolute;
  opacity: 0.65;
  margin: 0;
  top: 50%;
  margin-top: -55px;
  padding: 0;
  width: 90px;
  height: 110px;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.mfp-arrow:active {
  margin-top: -54px;
}

.mfp-arrow:hover,
.mfp-arrow:focus {
  opacity: 1;
}

.mfp-arrow:before,
.mfp-arrow:after,
.mfp-arrow .mfp-b,
.mfp-arrow .mfp-a {
  display: block;
  width: 30px !important;
  height: 30px !important;
  position: absolute;
  left: 0;
  top: 0;
  margin-top: 35px;
  margin-left: 35px;
}

.mfp-arrow:after,
.mfp-arrow .mfp-a {
  border-top-width: 0 !important;
  border-bottom-width: 0 !important;
  top: 8px;
}

.mfp-arrow:before,
.mfp-arrow .mfp-b {
  border-top-width: 0 !important;
  border-bottom-width: 0 !important;
}

.mfp-arrow-left {
  left: 0;
}

.mfp-arrow-left:after,
.mfp-arrow-left .mfp-a {
  border-right: 0 !important;
  margin-left: 60px !important;
  background: url(../Images/angle-left-white.svg);
  background-size: 50px 50px;
  height: 50px !important;
  width: 50px !important;
}

.mfp-arrow-left:before,
.mfp-arrow-left .mfp-b {
  margin-left: 25px;
  border-right: 0 !important;
}

.mfp-arrow-right {
  right: 0;
}

.mfp-arrow-right:after,
.mfp-arrow-right .mfp-a {
  border-left: 0 !important;
  margin-left: 0 !important;
  background: url(../Images/angle-right-white.svg);
  background-size: 50px 50px;
  height: 50px !important;
  width: 50px !important;
}

.mfp-arrow-right:before,
.mfp-arrow-right .mfp-b {
  border-left: 0 !important;
}

.white-color {
  color: #ffffff;
}

.simple-teaser {
  padding-left: 15px;
  padding-right: 15px;
}

.single-simple-teaser {
  margin-bottom: 30px;
  text-align: center;
}

.single-simple-teaser .single-image {
  position: relative;
  width: 100%;
  height: 100%;
}

.single-simple-teaser a {
  position: relative;
  height: 100%;
  display: inline-block;
}

.single-simple-teaser .title {
  position: absolute;
  top: 40%;
  width: 100%;
  font-size: 35px;
  line-height: 1.2;
  font-family: $font-family-header;
  color: #ffffff;
}

.single-simple-teaser .overlay {
  position: absolute;
  top: 0px;
  background: rgba(0, 0, 0, 0.75);
  opacity: 0;
  /* transition */
  -webkit-transition: opacity 0.25s;
  -moz-transition: opacity 0.25s;
  -o-transition: opacity 0.25s;
  transition: opacity 0.25s;
  height: 100%;
  width: 100%;
  font-size: 35px;
  line-height: 1.2;
  font-family: $font-family-header;
  color: #ffffff;
  text-align: center;
  padding-top: 15%;
}

.single-simple-teaser .overlay .overlay-linkname {
  font-family: $font-family-bold;
  font-size: 15px;
  text-align: center;
  font-weight: normal !important;
}

.single-simple-teaser .single-image:hover ~ .overlay,
.single-simple-teaser .overlay:hover {
  opacity: 1;
}

.single-simple-teaser a:hover .title {
  display: none;
}

.promo-badge {
  position: absolute;
  right: 30px;
  top: 20px;
}

@media (max-width: 991px) {
  .promo-badge {
    display: none;
  }
}

.promo-badge .inside-wrapper {
  border-radius: 50%;
  width: 260px;
  height: 260px;
  background-color: #b51f20;
  position: relative;
  z-index: 999;
  text-align: center;
  transform: rotate(9deg);
  -ms-transform: rotate(9deg);
  /* IE 9 */
  -webkit-transform: rotate(9deg);
  /* Chrome, Safari, Opera */
  padding: 35px 0 0 0;
  -webkit-box-shadow: 10px 10px 15px 0px rgba(0, 0, 0, 0.4);
  -moz-box-shadow: 10px 10px 15px 0px rgba(0, 0, 0, 0.4);
  box-shadow: 10px 10px 15px 0px rgba(0, 0, 0, 0.4);
}

.promo-badge .inside-wrapper .badge-text {
  color: #fff;
}

.promo-badge .inside-wrapper .badge-text .title {
  font-size: 24px;
  line-height: 1.3;
  font-family: $font-family-header-bold;
}

.promo-badge .inside-wrapper .badge-text .title.dash:before {
  content: '';
  display: block;
  height: 1px;
  width: 22px;
  background: #fff;
  position: relative;
  left: 50%;
  margin: 20px 0 15px -11px;
}

.promo-badge .inside-wrapper .badge-text .text {
  margin-top: 5px;
  font-size: 20px;
}

.promo-badge .inside-wrapper .badge-text .text.date {
  margin-top: 0;
}

.promo-bar {
  display: block;
  background-color: #b51f20;
  width: 100%;
  text-align: center;
  padding: 15px 0;
}

@media (min-width: 992px) {
  .promo-bar {
    display: none;
  }
}

.promo-bar .bar-text {
  color: #fff;
  font-family: $font-family-header-bold;
}

@media (max-width: 1500px) {
  #page_1 .slider_info {
    width: 600px;
  }
}

@media (max-width: 991px) {
  #page_1 .slider_info {
    width: 100%;
  }
}

/* Newsticker */
#newsticker {
  width: 100%;
  background-color: #b51f20;
  padding: 15px 0;
  overflow: hidden;
  color: #fff;
  font-family: $font-family-header-bold;
}

#newsticker a {
  color: #fff;
  font-family: $font-family-header-bold;
}
