@mixin anchor-style {
  --underline-offset: 4px;
  color: __get($colors, 'brand');
  text-decoration: underline;
  text-underline-offset: var(--underline-offset);

  &:hover {
    color: __get($colors, 'brand-darker');
  }
  &:focus {
    color: __get($colors, 'brand-darker');
    text-decoration-thickness: 2px;
  }
}

@mixin outline-style {
  outline: 1px solid __get($colors, 'brand') !important;
  outline-offset: 3px;
}

.ce-bodytext {
  a:not(.button):not(.btn) {
    @include anchor-style;
    --underline-offset: 2px;
    hyphens: auto;

    &.more-link i:before {
      content: '';
    }
  }

  a.button {
    @extend .btn, .btn-primary;
  }
}

a.rtelinksocialfb,
a.rtelinksocialkununu,
a.rtelinksocialxing,
a.rtelinksocialtwitter,
a.rtelinksociallinkedin,
a.rtelinksocialrss,
a.rtelinksocialyoutube {
  color: #000000;
  text-decoration: none;
  font-size: 0px;
  font-family: $font-family;
  margin-right: 7px;
  height: 24px;
  width: 24px;
  display: block;
}

a.rtelinksocialfb:hover,
a.rtelinksocialkununu:hover,
a.rtelinksocialxing:hover,
a.rtelinksocialtwitter:hover,
a.rtelinksociallinkedin:hover,
a.rtelinksocialrss:hover,
a.rtelinksocialyoutube:hover {
  opacity: 0.6;
}

a.rtelinksocialfb:focus,
a.rtelinksocialkununu:focus,
a.rtelinksocialxing:focus,
a.rtelinksocialtwitter:focus,
a.rtelinksociallinkedin:focus,
a.rtelinksocialrss:focus,
a.rtelinksocialyoutube:focus {
  outline: 1px solid __get($colors, success) !important;
  outline-offset: 3px;
}

.rtelinksocialfb:before {
  content: url('../Images/icon_fb.png');
  vertical-align: top;
  display: inline-block;
}

.rtelinksocialtwitter:before {
  content: url('../Images/icon_twitter.png');
  vertical-align: top;
  display: inline-block;
}

.rtelinksociallinkedin:before {
  content: url('../Images/icon_linkedin.png');
  vertical-align: top;
  display: inline-block;
}

.rtelinksocialkununu:before {
  content: url('../Images/icon_kununu.png');
  vertical-align: top;
  display: inline-block;
}

.rtelinksocialxing:before {
  content: url('../Images/icon_xing.png');
  vertical-align: top;
  display: inline-block;
}

.rtelinksocialrss:before {
  content: url('../Images/icon_rss.png');
  vertical-align: top;
  display: inline-block;
}

.rtelinksocialyoutube:before {
  content: url('../Images/icon_yt.png');
  vertical-align: top;
  display: inline-block;
}

@media (max-width: 991px) {
  .rtelinksocialfb,
  .rtelinksocialkununu,
  .rtelinksocialxing,
  .rtelinksocialtwitter,
  .rtelinksociallinkedin,
  .rtelinksocialrss,
  .rtelinksocialyoutube {
    margin-right: 4px;
  }

  .rtelinksocialrss {
    margin-left: 4px;
  }
}
